import Login from "../pages/Login";
import Profile from "../pages/Profile";
import Dashboard from "../pages/Dashboard";
import ResetPassword from "../pages/ResetPassword";
import OtpVerification from "../pages/OtpVerification";
import CreateNewPassword from "../pages/CreateNewPassword";
import CheckInForms from "../pages/CheckInForms";
import ManageUsers from "../pages/ManageUser";
import EditUsers from "../pages/EditUser";
import CreateForm from "../pages/CreateForm";
import EditForm from "../pages/EditForm";

//import OnBoardingForms from "../pages/OnBoardingForms";
import ResourcesLibrary from "../pages/ResourcesLibrary";
import Notifications from "../pages/Notifications";
import Notes from "../pages/Notes/index";
import ManagePoints from "../pages/ManagePoints";
import LeaderBoard from "../pages/LeaderBoard";
import Settings from "../pages/Settings";
import ManageEvents from "../pages/ManageEvents";
import UploadCall from "../pages/UploadResource/UploadCall";
import UploadHuddle from "../pages/UploadResource/UploadHuddle";
import UploadCourse from "../pages/UploadResource/UploadCourse";
import EditNote from "../pages/Notes/components/EditNote";
import CreateNote from "../pages/Notes/components/CreateNote";
import EditEvent from "../pages/ManageEvents/components/EditEvent";
import UploadApparel from "../pages/ResourcesLibrary/Winrate Resources/Apparel/UploadApparel";
import EditApparel from "../pages/ResourcesLibrary/Winrate Resources/Apparel/EditApparel";
import OnBoardingForms from "../pages/BoardingForms";
import CreateBoardingForm from "../pages/CreateBoardingForm";
import EditBoardingForm from "../pages/BoardingEditForm";
import ScheduleHuddleCall from "../pages/ResourcesLibrary/Client Resources/Schedule Huddle Call/CreateScheduleCall";
import ScheduleCallCalender from "../pages/ResourcesLibrary/Client Resources/Schedule Huddle Call/ScheduleCalender";
import EditScheduleCall from "../pages/ResourcesLibrary/Client Resources/Schedule Huddle Call/EditScheduleCall";
import UploadChangeImage from "../pages/ChangeImage/UploadChangeImage";
import EditChangeImage from "../pages/ChangeImage/EditChangeImage";
import ChangeImage from "../pages/ChangeImage/ChangeImage";
import ManageUserPoints from "../pages/ManagePoints/ManageUserPoints";
import GoogleAuth from "../pages/GoogleAuth";
import OutlookAuth from "../pages/OutlookAuth";
import CheckInHistory from "../pages/CheckInHistory";
import EditCourse from "../pages/UploadResource/EditCourse";

export const AllRoutes = [
  {
    path: "/login",
    page: <Login />,
    isPrivate: false,
  },
  {
    path: "/reset-password",
    page: <ResetPassword />,
    isPrivate: false,
  },
  {
    path: "/verification",
    page: <OtpVerification />,
    isPrivate: false,
  },
  {
    path: "/new-password/:otp",
    page: <CreateNewPassword />,
    isPrivate: false,
  },
  {
    path: "/usage-report",
    page: <Dashboard />,
    isPrivate: true,
  },
  {
    path: "/checkin-forms",
    page: <CheckInForms />,
    isPrivate: true,
  },
  {
    path: "/checkin-history/:id",
    page: <CheckInHistory />,
    isPrivate: true,
  },
  {
    path: "/manage-users",
    page: <ManageUsers />,
    isPrivate: true,
  },
  {
    path: "/edit-user/:id",
    page: <EditUsers />,
    isPrivate: true,
  },
  {
    path: "/create-form",
    page: <CreateForm />,
    isPrivate: true,
  },
  {
    path: "/edit-form/:id",
    page: <EditForm />,
    isPrivate: true,
  },
  {
    path: "/manage-points",
    page: <ManagePoints />,
    isPrivate: true,
  },
  {
    path: "/manage-points/:id",
    page: <ManageUserPoints />,
    isPrivate: true,
  },
  {
    path: "/resources-library",
    page: <ResourcesLibrary />,
    isPrivate: true,
  },
  {
    path: "/upload-call",
    page: <UploadCall />,
    isPrivate: true,
  },
  {
    path: "/upload-huddle",
    page: <UploadHuddle />,
    isPrivate: true,
  },
  {
    path: "/upload-course",
    page: <UploadCourse />,
    isPrivate: true,
  },
  {
    path: "/edit-course/:id",
    page: <EditCourse />,
    isPrivate: true,
  },
  {
    path: "/onboarding-forms",
    page: <OnBoardingForms />,
    isPrivate: true,
  },
  {
    path: "/profile",
    page: <Profile />,
    isPrivate: true,
  },
  {
    path: "*",
    page: <Login />,
    isPrivate: false,
  },
  {
    path: "/notifications",
    page: <Notifications />,
    isPrivate: true,
  },
  {
    path: "/client-notes",
    page: <Notes />,
    isPrivate: true,
  },
  {
    path: "/leaderboard",
    page: <LeaderBoard />,
    isPrivate: true,
  },
  {
    path: "/settings",
    page: <Settings />,
    isPrivate: true,
  },
  {
    path: "/manage-events",
    page: <ManageEvents />,
    isPrivate: true,
  },
  {
    path: "/create-note",
    page: <CreateNote />,
    isPrivate: true,
  },
  {
    path: "/edit-note/:id",
    page: <EditNote />,
    isPrivate: true,
  },
  {
    path: "/manage-event/:id",
    page: <EditEvent />,
    isPrivate: true,
  },
  {
    path: "/upload-apparel",
    page: <UploadApparel />,
    isPrivate: true,
  },
  {
    path: "/edit-apparel/:id",
    page: <EditApparel />,
    isPrivate: true,
  },
  {
    path: "/create-boarding-forms",
    page: <CreateBoardingForm />,
    isPrivate: true,
  },
  {
    path: "/edit-boarding-forms/:id",
    page: <EditBoardingForm />,
    isPrivate: true,
  },
  {
    path: "/schedule-huddle-call",
    page: <ScheduleHuddleCall />,
    isPrivate: true,
  },
  {
    path: "/schedule-call-calendar",
    page: <ScheduleCallCalender />,
    isPrivate: true,
  },
  {
    path: "/edit-schedule-call/:id",
    page: <EditScheduleCall />,
    isPrivate: true,
  },
  {
    path: "/upload-change-image",
    page: <UploadChangeImage />,
    isPrivate: true,
  },
  {
    path: "/edit-change-image/:contentType",
    page: <EditChangeImage />,
    isPrivate: true,
  },
  {
    path: "change-image",
    page: <ChangeImage />,
    isPrivate: true,
  },

  //Google Auth Redirection
  {
    path: "admin/google/redirect",
    page: <GoogleAuth />,
    isPrivate: true,
  },

  //outlook Auth Redirection
  {
    path: "admin/outlook/redirect",
    page: <OutlookAuth />,
    isPrivate: true,
  },
];
