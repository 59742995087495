import * as React from "react";
import Box from "@mui/material/Box";
import {
    AntTab,
    AntTabs,
    classes,
} from "../../../styles/ManageUserStyle";
import LeaderBoardClientTable from "./LeaderBoardClientTable";
import LeaderBoardCoachTable from "./LeaderBoardCoachTable";


interface ResourcesTabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function ManageUserTabsPanel(props: ResourcesTabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

const LeaderBoardTabs = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box>
            <Box sx={classes.antTabBoxLeaderBoard}>
                <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                    <AntTab label="Clients" />
                    <AntTab label="Coaches" />
                </AntTabs>
            </Box>
            <ManageUserTabsPanel value={value} index={0}>
                <LeaderBoardClientTable />
            </ManageUserTabsPanel>
            <ManageUserTabsPanel value={value} index={1}>
                <LeaderBoardCoachTable />
            </ManageUserTabsPanel>
        </Box>
    );
};
export default LeaderBoardTabs;