import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useChangePasswordMutation } from "../../../services/admin";
import classes from "../../../styles/LogonStyle";

interface IFormInputs {
  newPassword: string;
  currentPassword: string;
  confirmPassword: string
}

const schema = yup.object().shape({
  currentPassword: yup.string().min(6, "Password must be at least 6 characters").required("Required"),
  newPassword: yup.string().min(6, "Password must be at least 6 characters").required("Required"),
  confirmPassword: yup
    .string()
    .required("Password must be at least 6 characters")
    .min(6, "Password must be at least 6 characters")
    .oneOf([yup.ref("newPassword")], "Passwords do not match"),
});

const Password = ({setValue}:any) => {
  const [isPassword, setIsPassword] = useState(true);
  const [isNewPassword, setIsNewPassword] = useState(true);
  const [isConfirmPassword, setIsConfirmPassword] = useState(true);
  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
    const payload = {
      currentPassword: data?.currentPassword,
      newPassword: data?.newPassword
    }
    await changePassword(payload)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setValue(0)
      })
      .catch((error) => {
        toast.error(error?.data?.message);
      });
  };
  return (
    <>
      <Box sx={classes.editUserMain}>
        <Box className="mt-[1rem] mx-[4.6rem]">
          <Typography sx={{fontSize: "2.4rem", color: "#333333", fontWeight: 600, mb: 2}}>Change Password</Typography>
          <Grid
            container
            spacing="1.2rem"
            sx={{ maxWidth: "57.8rem" }}
          >
            <Grid item xs={12} sx={classes.fromWrapper}>
              <Box>
                <Box component="div" sx={classes.form_blk}>
                  <h6 className="text-[1.8rem] font-semibold mb-[1.3rem]">
                    Current Password
                  </h6>
                  <Controller
                    control={control}
                    name="currentPassword"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={classes.logonTextField}
                        variant="outlined"
                        fullWidth
                        placeholder="Enter Password"
                        type={isPassword ? "password" : "text"}
                        error={!!errors.currentPassword}
                        helperText={
                          errors.currentPassword
                            ? errors.currentPassword?.message
                            : null
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                size="medium"
                                onClick={() => setIsPassword(!isPassword)}
                              >
                                {isPassword ? (
                                  <VisibilityIcon
                                    sx={{
                                      fontSize: "2.4rem",
                                      color: "#312948",
                                    }}
                                  />
                                ) : (
                                  <VisibilityOffIcon
                                    sx={{
                                      fontSize: "2.4rem",
                                      color: "#312948",
                                    }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} component="div" sx={classes.fromWrapper}>
              <Box>
                <Box component="div" sx={classes.form_blk}>
                  <h6 className="text-[1.8rem] font-semibold mb-[1.3rem]">
                    New Password
                  </h6>
                  <Controller
                    control={control}
                    name="newPassword"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={classes.logonTextField}
                        variant="outlined"
                        type={isNewPassword ? "password" : "text"}
                        fullWidth
                        placeholder="Enter Password"
                        error={!!errors.newPassword}
                        helperText={
                          errors.newPassword
                            ? errors.newPassword?.message
                            : null
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                size="medium"
                                onClick={() => setIsNewPassword(!isNewPassword)}
                              >
                                {isNewPassword ? (
                                  <VisibilityIcon
                                    sx={{
                                      fontSize: "2.4rem",
                                      color: "#312948",
                                    }}
                                  />
                                ) : (
                                  <VisibilityOffIcon
                                    sx={{
                                      fontSize: "2.4rem",
                                      color: "#312948",
                                    }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} component="div" sx={classes.fromWrapper}>
              <Box>
                <Box component="div" sx={classes.form_blk}>
                  <h6 className="text-[1.8rem] font-semibold mb-[1.3rem]">
                    Confirm New Password
                  </h6>
                  <Controller
                    control={control}
                    name="confirmPassword"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={classes.logonTextField}
                        variant="outlined"
                        type={isConfirmPassword ? "password" : "text"}
                        fullWidth
                        placeholder="Enter Password"
                        error={!!errors.confirmPassword}
                        helperText={
                          errors.confirmPassword
                            ? errors.confirmPassword?.message
                            : null
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                size="medium"
                                onClick={() => setIsConfirmPassword(!isConfirmPassword)}
                              >
                                {isConfirmPassword ? (
                                  <VisibilityIcon
                                    sx={{
                                      fontSize: "2.4rem",
                                      color: "#312948",
                                    }}
                                  />
                                ) : (
                                  <VisibilityOffIcon
                                    sx={{
                                      fontSize: "2.4rem",
                                      color: "#312948",
                                    }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={isLoading}
                variant="contained"
                type="submit"
                sx={classes.createFormBtn}
                onClick={handleSubmit(onSubmit)}
              >
                update
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Password;
