import AddIcon from '@mui/icons-material/Add';
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetAllApparelsQuery } from "../../../services/admin";
import { selectWinRateResourceTabIndex } from "../../../store/selectors";
import { setWinRateResourceTabIndex } from "../../../store/slices/admin.slice";
import {
	classes,
	WinrateAntTab,
	WinrateAntTabs,
} from "../../../styles/ResourcesLibraryStyle";
import { StyledButton } from "../Client Resources/Index";
import ApparelCard from "./Apparel";
import Podcast from "./Podcast/Index";
import YouTube from "./Youtube Videos/Index";

interface WinrateTabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}
export interface WinrateStyledTabProps {
	label: string;
}
function WinrateTabPanel(props: WinrateTabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={classes.TabPanelBox}>
					<Box>{children}</Box>
				</Box>
			)}
		</div>
	);
}

const WinrateResourcesTabs = () => {
	const value = useSelector(selectWinRateResourceTabIndex);
	const navigate = useNavigate();
	const { data } = useGetAllApparelsQuery(1, { refetchOnMountOrArgChange: true });
	const dispatch = useDispatch()

	const handleChange = (_: React.SyntheticEvent, newValue: number) => {
		dispatch(setWinRateResourceTabIndex(newValue))
	};

	return (
		<Box>
			<Box sx={{ maxWidth: "100%" }}>
				<WinrateAntTabs
					value={value}
					onChange={handleChange}
					aria-label="ant example"
				>
					<WinrateAntTab label="Podcast" />
					<WinrateAntTab label="YouTube Videos" />
					<WinrateAntTab label="Apparel" />
				</WinrateAntTabs>
			</Box>
			<WinrateTabPanel value={value} index={0}>
				<Grid container spacing="2rem" marginTop="3rem">
					<Podcast />
				</Grid>
			</WinrateTabPanel>
			<WinrateTabPanel value={value} index={1}>
				<Grid container spacing="2rem" marginTop="3rem">
					<YouTube />
				</Grid>
			</WinrateTabPanel>
			<WinrateTabPanel value={value} index={2}>
				<Box sx={{ textAlign: "right", marginTop: "1rem" }}>
					<StyledButton onClick={() => navigate("/upload-apparel")}><AddIcon />Upload Apparel</StyledButton>
				</Box>
				{
					data?.data?.result.length === 0 ? (
						<h1 className="text-center text-[#2165A2] text-[2.4rem] font-bold mt-72">No Apparel Found</h1>
					) : (
						<Grid container spacing="2rem" marginTop="3rem">
							{data?.data?.result.map((item: any) => {
								return (
									<Grid item xs={12} sm={6} md={4} key={item?.id}>
										<ApparelCard item={item} />
									</Grid>
								);
							})}
						</Grid>
					)
				}

			</WinrateTabPanel>
		</Box>
	);
};
export default WinrateResourcesTabs;
