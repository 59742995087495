import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Stack
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import Layout from "../../components/Layout/Layout";
import apiEndpoint from "../../config/environment";
import {
  isFetchBaseQueryError,
  useGetBannerByTypeQuery,
  useUpdateBannerMutation,
} from "../../services/admin";
import UploadBox from "./Components/UploadBox";

interface IFormInputs {
  image: string;
}

const schema = yup.object().shape({
  image: yup.string().required("Required"),
});

const EditChangeImage = () => {
  const [imageType, setImageType] = useState("");
  const [id, setId] = useState("");
  const { contentType } = useParams();
  const [image, setImage] = useState({
    name: "",
    file: {},
    isUploading: false,
    url: "",
  });
  const [updateBanner, { isLoading }] = useUpdateBannerMutation();
  const { data, isFetching } = useGetBannerByTypeQuery(contentType,{
    refetchOnMountOrArgChange:true,
  });
  const navigate = useNavigate();
  const {handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if(data){
      setImageType(data?.data?.result?.imageUrl);
      setId(data?.data?.result?.id);
    } 
  }, [isFetching]);

  const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
    const payload = {
      imageUrl: data.image,
    };

    try {
      await updateBanner({ id, payload })
        .unwrap()
        .then((res) => {
          toast.success(res?.message)
          setImageType("")
        });
      navigate("/change-image");
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error.data?.message);
      }
    }
  };

  const handleUploadImage = async (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target?.files[0];
    setImage({
      ...image,
      name: file.name,
      file: file,
      isUploading: true,
    });

    const formData = new FormData();
    formData.append("image", file);

    const promise = axios
      .request({
        method: "POST",
        url: `${apiEndpoint}/upload-one`,
        data: formData,
      })
      .then((res) => {
        setImage({
          ...image,
          isUploading: false,
          url: res.data,
        });
        setValue("image", res.data);
        setImageType(res?.data);
        trigger("image");
      })
      .catch(() => {
        setImage({
          ...image,
          isUploading: false,
        });
      });
    await toast.promise(promise, {
      pending: "Uploading Image",
      success: "Uploaded Image",
      error: "Error Uploading Image",
    });
  };

  return (
    <Layout>
      <div className="pt-[6.636rem] pb-[4.633rem] px-[16rem]">
        <h4 className="text-[#333333] text-[3.2rem] font-bold mb-[1.9rem]">
          Update Image For
        </h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            className="w-[50rem] h-[7.51rem] border-[0.150201rem] border-[#2165A2] rounded-[2rem] p-[1rem] text-[1.8rem] my-[2rem] text-center"
            disabled
            value={
              data?.data?.result?.contentType.split("_")[0] +
              " " +
              data?.data?.result?.contentType.split("_")[1]
            }
          />
          <Box sx={{ display: "flex", gap: "6rem" }}>
            <Box>
              <UploadBox
                name="image"
                accept="image/*"
                uploadTitle="Upload Image"
                type="photo"
                fileName="Image12.png"
                progress={100}
                status="completed"
                isUploading={image.isUploading}
                showImages
                handleFileChange={handleUploadImage}
                register={register}
              />
              {errors?.image?.message ? (
                <p className="text-red-500 py-[10px]">
                  {" "}
                  {errors.image.message}{" "}
                </p>
              ) : null}
            </Box>
            {imageType && (
              <Stack direction="row" maxWidth={"100%"} maxHeight={"38.479rem"}>
                <div className="w-full h-full rounded-lg overflow-hidden relative">
                  <img
                    className="w-full h-full object-cover"
                    src={imageType}
                    alt="imageType"
                  />
                </div>
              </Stack>
            )}
          </Box>
          <div className="mt-[8rem]">
            <button
              disabled={isLoading || image.isUploading}
              type="submit"
              className={` ${
                isLoading || image.isUploading ? "bg-[#3886ca]" : "bg-[#2165A2]"
              } rounded-[0.481505rem] w-[18.402rem] h-[4.508rem] text-white text-[1.80564rem] font-bold`}
            >
              Upload Image
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default EditChangeImage;
