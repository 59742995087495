import theme from "../theme";

const classes = {
  Card: {
    p: 0,
    height: "100%",
    borderRadius: "2rem",
    boxShadow: "none",
    ".MuiCardActionArea-root:hover": {
      background: "#fff",
    },
  },
  CardActionAreaBox: {
    height: "20rem",
    position: "relative",
  },
  CardActionArea: {
    padding: "3rem",
    "&:hover": {
      background: "#ffffff !important",
    },
  },
  ManageEventsCardMedia: {
    borderRadius: "1rem",
    height: "20rem",
  },
  CardMediaBox: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "100%",
    padding: "1rem 1rem",
    background: "rgba(0, 0, 0, 0.5)",
    borderRadius: "0rem 0rem 1rem 1rem",
  },
  FmdGoodIcon: {
    marginRight: "0.5rem",
    fontSize: "2.4rem",
    color: "#fff",
  },
  ManageEventsCardActionAreaTypo: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1.4rem",
    lineHeight: "2.1rem",
    color: "#fff",
  },
  CardContent: {
    position: "relative",
    p: 0,
  },
  CardTitle: {
    textTransform: "capitalize",
    width: "100%",
    marginTop: "2rem",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "2.1rem",
    lineHeight: "150%",
    color: "#2165A2",
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  RecentHuddlecardDescription: {
    width: "100%",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1.8rem",
    lineHeight: "2.7rem",
    color: "#333333",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    height: "5rem",
  },
  TimeTypo: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1.66444rem",
    lineHeight: "2.7rem",
    color: "rgba(51, 51, 51, 0.6)",
  },
  ShareIcon: {
    marginTop: "-3rem",
    marginRight: "1rem",
    color: "#18203A",
    fontSize: "2.4rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "1rem",
    },
  },
  GroupAvatarsBox: {
    marginTop: "1rem",
  },
  GroupAvatar: {
    ".MuiAvatar-root": {
      width: "3rem",
      height: "3rem",
      color: "#fff",
      // background: "#2165A2",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "1.6rem",
      lineHeight: "2.4rem",
    },
  },
};
export default classes;
