import { Box } from "@mui/material";
import LogonSidebar from "../../components/LogonSidebar";
import Form from "./Form";
import { useState } from "react";
import Done from "./Done";
import classes from "../../styles/LogonStyle";

const CreateNewPassword = () => {
	const [done] = useState<any>(false);
	return (
		<Box component="section" sx={classes.Logon}>
			{done ? <Done /> : <Form />}
			<LogonSidebar />
		</Box>
	);
};

export default CreateNewPassword;
