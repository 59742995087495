import {
  Button,
  Grid,
} from "@mui/material";
import dayjs from "dayjs";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useState } from "react";
import { toast } from "react-toastify";
import {
  isFetchBaseQueryError,
  useCreateGHLEventMutation,
} from "../../../services/admin";

dayjs.extend(isSameOrBefore)

const GHLForm = ({ goBack }: any) => {
  const [formLink, setFormLink] = useState("");
  const [error, setError] = useState(false);

  const [createGHLEvent, { isLoading, isSuccess }] = useCreateGHLEventMutation();

  const submitHandler = async () => {
    if(!formLink){
      setError(true);
      return;
    }

    const payload = {
      formLink: formLink
    };

    try {
      await createGHLEvent(payload).unwrap();
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error.data.message);
      }
    }
  };

  if (isSuccess) {
    toast.success("Event Created Successfully");
    goBack();
  }

  const changeFormLinkHandler = (event:any) => {
    const value = event?.target?.value;
    if(value){
      setFormLink(value)
    }
  }

  return (
    <form >
      <div className="mb-8">
        <h6 className="text-3xl font-semibold">Add GHL Event Form Link</h6>
        <input
          type="text"
          className="bg-[#A2A3AC0A] px-10 text-[1.6rem] mt-5 border border-[#D0D6DE] rounded-3xl font-medium outline-none h-24 w-full"
          placeholder="e.g: https://api.leadconnectorhq.com/widget/form/4wr5XiNIt0sFcFAeC7VV"
          onChange={changeFormLinkHandler}
        />
        {error && (
          <p style={{ fontSize: "10px", color: "red" }}>
            GHL Form Link is Required
          </p>
        )}
      </div>

      <Grid container spacing="2rem">
        <Grid item xs={7}>
          <Button
            onClick={submitHandler}
            variant="contained"
            disableElevation
            disableTouchRipple
            fullWidth
            disabled={isLoading}
            className="!text-3xl !capitalize h-24 !rounded-2xl !mt-8"
          >
            Create Event
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default GHLForm;
