import { yupResolver } from "@hookform/resolvers/yup";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Box, IconButton, Stack, TextField, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from "yup";
import Layout from '../../../components/Layout/Layout';
import { useGetAllClientsQuery, useGetNoteQuery, useUpdateNoteMutation } from '../../../services/admin';
import classes from "../../../styles/CreateFormStyle";
import classesNote from '../../../styles/QuestionFormStyles';
import { calendarStyle, dateInputStyle } from "../../../styles/calenderStyles";


interface IFormInputs {
    client: string;
    note: string;
    date: string;
}
const schema = yup.object().shape({
    note: yup.string().required(" Required"),
    date: yup.string().required(" Required"),
    client: yup.string().required(" Required"),
});
const EditNote = () => {
    const [setDateValue] = React.useState<any>(null);
    const { data: notes } = useGetAllClientsQuery(1, { refetchOnMountOrArgChange: true });
    const { id } = useParams();
    const { data, isFetching } = useGetNoteQuery(id, { refetchOnMountOrArgChange: true });
    const [updateNote, { isSuccess }] = useUpdateNoteMutation();
    const record: any = notes?.data?.clients.find((item: any) => item?.id === data?.data?.note?.receiverId);
    const navigate = useNavigate();

    useEffect(() => {
        setValue("client", record?.full_name)
        setValue("note", data?.data?.note?.note)
        setValue("date", data?.data?.note?.date)
    }, [isFetching])

    const {
        handleSubmit,
        control,
        setValue,
        register,
        formState: { errors },
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema), defaultValues: {
            note: data?.data?.note?.note,
            date: data?.data?.note?.date,
            client: record?.full_name,

        }
    });

    const goBack = () => {
        navigate("/client-notes")
    }

    if (isSuccess) {
        toast.success("Note Updated")
        goBack()
    }

    const onSubmit: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
        const note = data.note
        const payload = { note };
        updateNote({ id, payload });
    };


    return (
        <>
            <Layout>
                <Stack direction="row" gap="2rem">
                    <IconButton
                        sx={classes.backArrow}
                        disableRipple
                        onClick={goBack}
                    >
                        <ArrowRightAltIcon />
                    </IconButton>
                    <Typography variant="h2" sx={{ fontSize: "3.5rem", lineHeight: "5.2rem", fontWeight: "600", color: "#333333" }}>Edit Note</Typography>
                </Stack>
                <Typography sx={{ mt: "4.3rem", fontSize: "2.4rem", lineHeight: "3.7rem", fontWeight: "600", color: "#333333" }}>Description</Typography>
                <form >
                    <Box sx={{ marginTop: "6.6rem" }}  >

                        <Controller
                            name="note"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    multiline
                                    rows={7}
                                    maxRows={7}
                                    sx={classesNote.questionTextField}
                                    id="outlined-basic"
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    placeholder="Write a Note?"
                                    error={!!errors.note}
                                    helperText={
                                        errors.note
                                            ? errors.note?.message
                                            : null
                                    }
                                />
                            )}
                        />
                    </Box>

                    <Box sx={{ marginTop: "6rem", display: "flex", gap: "2rem" }}>
                        <Box sx={{ width: "100%" }}>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: "1.8rem",
                                        fontWeight: 600,
                                        color: "#333333",
                                        mb: "1.6rem",
                                    }}
                                >
                                    User
                                    <span style={{ color: "#FF5B5B" }}>*</span>
                                </Typography>
                            </Box>

                            <Box>
                                <TextField
                                    {...register("client")}
                                    sx={classesNote.textField}
                                    id="outlined-basic"
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    // defaultValue={record?.full_name}
                                    disabled
                                />

                            </Box>


                        </Box>

                        <Box sx={{ width: "100%" }}>
                            <Typography sx={{
                                fontSize: "1.8rem",
                                fontWeight: 600,
                                color: "#333333",
                                mb: "1.6rem",
                            }}>
                                Date
                            </Typography>

                            <Box sx={{ mt: "1.2rem" }}>
                                <Controller
                                    control={control}
                                    name="date"
                                    render={({ field: { onChange, ...restField } }) => (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label=""
                                                disabled
                                                disablePast
                                                onChange={(event: any) => {
                                                    onChange(event);
                                                    setDateValue(event);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        defaultValue={data?.data?.note?.date}
                                                        variant="outlined"
                                                        sx={dateInputStyle}
                                                        {...params}
                                                    />
                                                )
                                                }
                                                PaperProps={{
                                                    sx: calendarStyle
                                                }}
                                                {...restField}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                                {errors?.date && (
                                    <p style={{
                                        fontSize: "1.75rem",
                                        color: "#d32f2f",

                                    }}>
                                        {errors?.date?.message}
                                    </p>
                                )}
                            </Box>
                        </Box>


                    </Box>
                    <Box sx={{ width: "100%", mt: "3.9rem", textAlign: "center" }}>
                        <Button
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                            sx={classesNote.noteButton}>
                            update
                        </Button>
                    </Box>
                </form>
            </Layout>
        </>
    )
}

export default EditNote


