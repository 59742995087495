import {
  Box,
  Button,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { verifyOtp } from "../../apis/auth-apis";
import MobileLogo from "../../components/MobileLogo";
import Spinner from "../../components/Spinner";
import classes from "../../styles/LogonStyle";


const Form = () => {
  //const { setError } = useForm();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [phone, setPhone] = useState({
    n1: "",
    n2: "",
    n3: "",
    n4: "",
    n5: "",
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const { n1, n2, n3, n4, n5 } = phone;
    const data = n1 + n2 + n3 + n4 + n5;
    if (data.length < 5) {
      setIsError(true);
      setError("Enter Valid OTP Code");
      return;
    }

    if (data.length === 5) {
      verifyOtp(data, setLoading, navigate);
      // navigate("/new-password");
    }
  };

  useEffect(() => {
    const { n1, n2, n3, n4, n5 } = phone;
    const data = n1 + n2 + n3 + n4 + n5;
    setError(" ");
    if (data && data.length < 5) {
      setError("Please enter complete OTP");
    } else if (data.length === 5) {
      setError(" ");
    }
  }, [phone]);

  function handleOtp(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void {
    const enteredValue = e.target.value.replace(/\D/g, "");
    setPhone({ ...phone, [e.target.name]: enteredValue });
  }

  return (
    <Box component="div" sx={classes.LogBlk}>
      <Box component="div" sx={classes.fromWrapper}>
        <MobileLogo />

        <Typography variant="h2" sx={classes.formTitle}>
          Enter your OTP verification code
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box component="div" sx={classes.form_blk}>
            <Typography variant="h6" sx={classes.formlabelVerification}>
              Enter the verification code sent to your email address.
            </Typography>

            <Stack direction="row" spacing="2.5rem">
              <TextField
                name="n1"
                value={phone.n1}
                onChange={handleOtp}
                inputProps={{
                  maxLength: 1,
                }}
                sx={classes.otpTextField}
                id="outlined-basic"
                variant="outlined"
              />

              <TextField
                name="n2"
                value={phone.n2}
                onChange={handleOtp}
                inputProps={{
                  maxLength: 1,
                }}
                sx={classes.otpTextField}
                id="outlined-basic"
                variant="outlined"
              />

              <TextField
                name="n3"
                value={phone.n3}
                onChange={handleOtp}
                inputProps={{
                  maxLength: 1,
                }}
                sx={classes.otpTextField}
                id="outlined-basic"
                variant="outlined"
              />

              <TextField
                name="n4"
                value={phone.n4}
                onChange={handleOtp}
                inputProps={{
                  maxLength: 1,
                }}
                sx={classes.otpTextField}
                id="outlined-basic"
                variant="outlined"
              />

              <TextField
                name="n5"
                value={phone.n5}
                onChange={handleOtp}
                inputProps={{
                  maxLength: 1,
                }}
                sx={classes.otpTextField}
                id="outlined-basic"
                variant="outlined"
              />
            </Stack>
          </Box>
          {isError && (
            <Typography sx={{ fontSize: "1.7rem", color: "#d32f2f" }}>
              {error}
            </Typography>
          )}
          <Button
            disabled={loading}
            variant="contained"
            type="submit"
            sx={loading ? classes.disabled : classes.logonBtn}
          >
            {loading ? <Spinner /> : "Submit"}
          </Button>
        </form>

        <Typography sx={classes.wrongNumberLabel}>
          Didn&apos;t Get OTP Code?
        </Typography>
        <Typography sx={classes.resendCodeLabel}>
          <Link to={"/reset-password"}>Resend</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Form;
