import { Menu, MenuProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: "23rem",
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiBox-root': {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiTypography-root": {
          fontSize: "1.8rem",
          fontWeight: 400,
          color: "#293A48",
          lineHeight: 1,
        },
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.primary.main,
        },
      },
      
    },
  },
}));