import Layout from "../../components/Layout/Layout";
import { Typography, Stack, IconButton, Button } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import classes from "../../styles/CreateFormStyle";
import Form from "../CreateForm/Form";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  useUpdateCheckinFormMutation,
  isFetchBaseQueryError,
  useGetCheckinFormQuery,
} from "../../services/admin";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
interface questionI {
  id: string;
  question: string;
  label: string;
  type: string;
  length: string;
  questionNum: number
}
const CreateForm = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetCheckinFormQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const [questionsData, setQuestionsData]: any = useState<any[]>([]);
  const [title, setTitle] = useState("");
  const [isRequired, setisRequired] = useState("");
  
  const [updateCheckinForm] = useUpdateCheckinFormMutation();
  const navigate = useNavigate();
  const payload = {
    title: title,
    questions: questionsData,
  };

  useEffect(() => {
    const questionData = data?.data;

    if (questionData && id) {
      setTitle(questionData?.title);
      const filteredQuestion = questionData?.checkInFormQuestions.map(
        (question: questionI) => {
          return {
            id: question.id,
            question: question.question,
            label: question.label,
            type: question.type,
            length: question.length,
            questionNum: question.questionNum
          };
        }
      );
      setQuestionsData(filteredQuestion);
    }
  }, [id, isLoading]);

  const publishForm = async () => {
    if (title == "") {
      setisRequired("Required");
      return;
    }
    const hasEmptyQuestion = questionsData.some((obj:any) => !obj.label && !obj.question && !obj.type);

    if( hasEmptyQuestion ){ // Check to see question is filled before adding new question
      toast.info("Fill and save the question details before publishing");
      return;
    }
    
    try {
      await updateCheckinForm({ id: id, payload: payload })
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          navigate("/checkin-forms");
        });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error?.data?.message);
      }
    }
  };

  return (
    <Layout>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap="2rem">
          <Link to="/checkin-forms">
            <IconButton sx={classes.backArrow} disableRipple>
              <ArrowRightAltIcon />
            </IconButton>
          </Link>
          <Typography variant="h2">Edit Check-In Form</Typography>
        </Stack>
        <Button
          variant="contained"
          sx={classes.pubBtn}
          disableElevation
          disableRipple
          disabled={questionsData.length == 0}
          onClick={publishForm}
        >
          Publish
        </Button>
      </Stack>
      <h6 className="after:!text-[red] text-3xl font-semibold mt-10">Title</h6>
      <div className="flex items-center mt-5 max-w-[90%]">
        <input
          type="text"
          className="bg-[#fff] px-10 text-[1.6rem] border border-[#D0D6DE] rounded-3xl font-medium outline-none h-24"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <span style={{ color: "red" }}>{isRequired}</span>
      <Form
        setQuestionsData={setQuestionsData}
        questionsData={questionsData}
      />
    </Layout>
  );
};

export default CreateForm;
