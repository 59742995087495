import { createTheme } from "@mui/material/styles";
import { calendarOrClockPickerClasses } from "@mui/x-date-pickers/internals";

const customTimeTheme = createTheme({
  components: {
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          [`&.custom-clock .${calendarOrClockPickerClasses.root} > div`]: {
            width: 250
          }
        }
      }
    },
    MuiClockPicker: {
      styleOverrides: {
        arrowSwitcher: {
          right: 8,
          top: 8
        },
        root: {
          width: 250
        }
      }
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        root: {
          "& .MuiButtonBase-root": {
            color: "#2165A2",
            "& .MuiSvgIcon-root": {
              width: "2.4rem",
              height: "2.4rem"
            }
          }
        },
        spacer: {
          width: 12
        }
      }
    },
    MuiClock: {
      styleOverrides: {
        root: {
          margin: 1,
          "& .MuiButtonBase-root": {
            borderRadius: "5px",
            padding: "6px",
            "& .MuiTypography-root": {
              fontSize: "1.4rem"
            }
          }
        },
        clock: {
          transform: `scale(0.8)`,
          fontSize: "125%"
        }
      }
    }
  }
});

export default customTimeTheme;