import AddIcon from "@mui/icons-material/Add";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { useGetOnBoardingFormsQuery } from "../../services/admin";
import classes from "../../styles/CheckinFormStyle";
import CheckinFormTable from "./Table";

const BoardingForms = () => {
  const { data, refetch, isLoading } = useGetOnBoardingFormsQuery(1, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  return (
    <Layout>
      <Box className="flex justify-between items-center">
        <Typography variant="h2" component="h2">
          Onboarding Forms
        </Typography>
        <Link to="/create-boarding-forms">
          <Button variant="contained" sx={classes.createFormBtn}>
            <AddIcon fontSize="large" sx={{ marginRight: "1rem" }} /> Create New
            Form
          </Button>
        </Link>
      </Box>
      {isLoading ? (
        <h1 className="text-[3rem] mt-[5rem] text-center">Loading...</h1>
      ) : (
        <CheckinFormTable data={data && data?.data} refetch={refetch} />
      )}
    </Layout>
  );
};

export default BoardingForms;
