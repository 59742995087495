import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Layout from "../../components/Layout/Layout";
import classes from "../../styles/CheckinFormStyle";
import LeaderBoardTabs from "./components/LeaderBoardTab";


const LeaderBoard = () => {
    return (
        <Layout>
            <Box sx={classes.headWithBtn}>
                <Typography variant="h2" component="h2">
                    Leaderboard
                </Typography>
            </Box>
            <LeaderBoardTabs />
        </Layout>
    );
};

export default LeaderBoard;