import iconUpload from "../../../assets/images/iconUpload.svg";
import { Stack } from "@mui/material";
import axios from "axios";
import apiEndpoint from "../../../config/environment";
import { toast } from "react-toastify";

const UploadImage = ({ images, setImages }: any) => {

	const imageUploadHandler = async (event: any) => {
		if (!event.target.files) {
			return;
		}

		const files = event.target.files;

		const formData = new FormData();
		for (let i = 0; i < files.length; i++) {
			formData.append("images", files[i]);
		}

		const promise = axios.request({
			method: "POST",
			url: `${apiEndpoint}/upload-many`,
			data: formData
		}).then((res: any) => {
			setImages([...images, ...res.data]);
		}).catch(() => {
			toast.error("Images uploading failed")
		});

		await toast.promise(promise, {
			pending: "Uploading Images",
			success: "Images Uploaded",
			error: "Error Uploading Images",
		});
	}

	const removeImageHandler = (image: string) => {
		const filteredImages = images.filter((item: string) => item !== image);
		setImages([...filteredImages]);
	};

	return (
		<Stack direction="row" mt={3} spacing={3}>
			<div>
				<div className="w-[20rem] h-[18rem] bg-[#A2A3AC0A] border border-[#D0D6DE] rounded-3xl flex flex-col gap-2  justify-center items-center relative">
					<input
						type="file"
						multiple
						accept="image/*"
						className="w-full h-full absolute top-0 left-0 opacity-0 !cursor-pointer"
						onChange={(event: any) => imageUploadHandler(event)}
					/>
					<div className="">
						<img className="w-28" src={iconUpload} alt="" />
					</div>
					<p className="px-10 text-center font-medium">Click to upload images </p>
				</div>
			</div>

			<Stack direction="row" flexWrap="wrap" gap={2}>
				{images && (
					images.map((image: string, index: number) => (
						<div className="w-[20rem] h-[18rem] rounded-3xl overflow-hidden relative" key={index}>
							<p onClick={() => removeImageHandler(image)}
								className="absolute w-full text-center bottom-0 bg-black bg-opacity-40 text-white font-bold py-3 cursor-pointer">
								Remove
							</p>
							<img
								alt="eventsimages"
								className="w-full h-full object-cover"
								src={image} />
						</div>
					))
				)}
			</Stack>
		</Stack>



	);
};

export default UploadImage;
