import { Grid } from "@mui/material";
import { useGetHuddleCallsQuery, useDeleteHuddleCallMutation } from "../../../../services/admin";
import HuddleCard from "./HuddleCard";
import { toast } from "react-toastify";
import Thumb from "../../../../assets/images/DummyThumb.png";

const HuddleCall = () => {
    const { data, isLoading, refetch } = useGetHuddleCallsQuery({ type: "huddle" }, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
    const [deleteHuddleCall] = useDeleteHuddleCallMutation();

    const deleteHandler = (id: string, type: string) => {
        const payload = {
            id: id,
            callType: "huddle",
            recordType: type
        };

        deleteHuddleCall(payload).unwrap().then(() => {
            refetch();
            toast.success("Deleted Successfully")
        }).catch(() => {
            toast.error("Something went wrong ")
        });
    };

    const huddles = data?.data?.data || [];
    const zoomCalls = huddles?.zoom || [];
    const uploadCalls = huddles?.upload || [];

    const sortedZoomCalls = [...zoomCalls]?.sort((a: any, b: any) => {
        const aDate = new Date(a?.start_time)?.getTime();
        const bDate = new Date(b?.start_time)?.getTime();
        return bDate - aDate
      })

    
      const sortedUploads = [...uploadCalls]?.sort((a: any, b: any) => {
        const aDate = new Date(a?.createdAt)?.getTime();
        const bDate = new Date(b?.createdAt)?.getTime();
        return bDate - aDate
      })

    return (
        <>
            {isLoading && (
                <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                    <p className="text-4xl sm:text-5xl text-primary_blue">Loading...</p>
                </div>
            )}

            {!isLoading && <h2 className="text-[#2165A2] text-5xl mt-20 font-medium">Huddle Recordings</h2>}
            {(!isLoading && sortedZoomCalls?.length === 0) && (
                <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                    <p className="text-4xl sm:text-5xl text-primary_blue">No Recordings Found</p>
                </div>
            )}

            {(!isLoading && sortedZoomCalls?.length !== 0) && (
                <Grid container spacing="2rem" marginTop="0rem">
                    {sortedZoomCalls?.map((item: any, index: number) => {
                        const video = item?.recording_files?.find((vid: any) => vid.file_type === "MP4");
                        const url = `${video?.play_url}?pwd=${item?.recording_play_passcode}`

                        return (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <HuddleCard
                                    title={item?.topic}
                                    detail={item?.detail || null}
                                    thumbnail={Thumb}
                                    id={item?.id}
                                    item={item}
                                    date={item?.start_time}
                                    type="zoom"
                                    onDelete={deleteHandler}
                                    url={url}
                                    status={video?.status}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            )}

            {!isLoading && <h2 className="text-[#2165A2] text-5xl mt-20 font-medium">Huddle Uploads</h2>}
            {(!isLoading && sortedUploads?.length === 0) && (
                <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                    <p className="text-4xl sm:text-5xl text-primary_blue">No Recordings Found</p>
                </div>
            )}

            {(!isLoading && sortedUploads?.length !== 0) && (
                <Grid container spacing="2rem" marginTop="0rem">
                    {sortedUploads?.map((item: any, index: number) => {
                        return (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <HuddleCard
                                    title={item.title}
                                    detail={item.detail}
                                    thumbnail={item?.thumbnail || Thumb}
                                    id={item.id}
                                    item={item}
                                    date={item.createdAt}
                                    onDelete={deleteHandler}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </>

    );
};

export default HuddleCall;