import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  user: "",
  notiCount: 0,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      // state.loggedIn = (action.payload && action.payload.length > 0) || false;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setNotiCount: (state, action) => {
      state.notiCount = action.payload;
    },
  },
});

export const setToken = authSlice.actions.setToken;
export const setUser = authSlice.actions.setUser;
export const setNotiCount = authSlice.actions.setNotiCount;

const AuthSlice = authSlice.reducer;
export default AuthSlice;
