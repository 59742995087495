import { Modal, Typography, Box, Stack, Button } from "@mui/material";
import { useDeleteClientMutation } from "../../../services/general";
import { useGetAllClientsQuery } from "../../../services/admin";
import { toast } from "react-toastify";

const DeleteClientModal = ({ open, setOpen, userId }: any) => {
  const { refetch } = useGetAllClientsQuery(1);
  const [deleteClient, { isLoading }] = useDeleteClientMutation();

  const handleClose = () => setOpen(false);

  const deleteHandler = async() => {
    await deleteClient(userId).unwrap().then(() => {
      toast.success("User Deleted Successfully");
      refetch();
      handleClose();
    })
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h3" textAlign="center">
          Are you sure?
        </Typography>
        <Typography id="modal-modal-description" variant="h5" sx={{ mt: 2 }}>
          All the data will be lost when deleting this Client!
        </Typography>
        <Stack spacing={1} direction="row" justifyContent="center" mt={2}>
          <Button onClick={handleClose} disabled={isLoading} variant="outlined" size="large" sx={{ fontSize: "1.4rem", fontWeight: 600 }}>No, Cancel</Button>
          <Button onClick={deleteHandler} disabled={isLoading} variant="contained" size="large" color="error" sx={{ fontSize: "1.4rem", fontWeight: 600 }}>Yes, Delete</Button>
        </Stack>
      </Box>
    </Modal>
  )
};

export default DeleteClientModal;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};