interface Column {
    id: string;
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
}

export const columns: readonly Column[] = [
    { id: "name", label: "", minWidth: 300 },
    { id: "Questions", label: "Questions", minWidth: 150 },
    // {
    //     id: "Responses",
    //     label: "Responses",
    //     minWidth: 150,
    //     format: (value: number) => value.toLocaleString("en-US"),
    // },
    // {
    //     id: "Completion",
    //     label: "Completion",
    //     minWidth: 150,
    //     format: (value: number) => value.toLocaleString("en-US"),
    // },
    {
        id: "Activated",
        label: "Activated",
        minWidth: 150,
        // format: (value: number) => value.toFixed(2),
    },
    {
        id: "Actions",
        label: "Actions",
        minWidth: 50,
        // align: "right",
        format: (value: number) => value.toFixed(2),
    },
];