import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, IconButton, MenuItem } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDeleteApparelMutation } from "../../../../services/admin";
import { classes } from "../../../../styles/ResourcesLibraryStyle";
import { StyledMenu } from "../../../../styles/StyledMenu";
import numberWithCommas from "../../../../utilities/commafunction";
import ViewApparelModal from "./ViewApparelModal";

const ApparelCard = ({ item }: any) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openView, setOpenView] = React.useState(false);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const [deleteApparel, { isSuccess, isError }] = useDeleteApparelMutation();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    if (isSuccess) {
        toast.success("Deleted Successfully")

    }
    if (isError) {
        toast.error("Something went wrong")
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickOpen = () => {
        setOpenView(true);
    };
    function handleDelete(_: any, id: any) {
        deleteApparel(id)
    }
    function handleEdit(_: any, id: any) {
        navigate(`/edit-apparel/${id}`)
    }

    function renderMenu() {
        return (
            <>

                <StyledMenu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClickOpen}>
                        <Box>
                            <Typography>View</Typography>
                            <VisibilityIcon />
                        </Box>
                    </MenuItem>
                    <MenuItem onClick={(e) => handleEdit(e, item?.id)}>
                        <Box>
                            <Typography>Edit</Typography>
                            <EditIcon />
                        </Box>
                    </MenuItem>
                    <MenuItem onClick={(e) => handleDelete(e, item?.id)}>
                        <Box>
                            <Typography>Delete</Typography>
                            <DeleteIcon />
                        </Box>
                    </MenuItem>
                </StyledMenu>

            </>
        )
    }
    return (
        <Card sx={classes.Card}>
            <Box sx={classes.CardActionArea}>
                <Box sx={classes.CardActionAreaBox}>
                    <CardMedia
                        sx={classes.RecentHuddleCardMedia}
                        component="img"
                        image={item?.image ? item?.image : item?.title}
                        alt="ApparelImage"
                    />
                    <Box sx={classes.MenuBtn}>
                        <IconButton aria-label="delete" sx={{ p: 0 }} onClick={handleClick}>
                            <MoreVertIcon sx={{ color: "#2165A2", fontSize: "3.4rem" }} />
                        </IconButton>
                    </Box>
                </Box>
                <CardContent sx={classes.CardContent}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography gutterBottom sx={classes.CardTitle}>
                            {item?.title}
                        </Typography>
                        <Typography sx={classes.priceTag}>
                            ${numberWithCommas(item?.price)}
                        </Typography>
                    </Box>
                    <Typography sx={classes.RecentHuddlecardDescription}>
                        {item?.description}
                    </Typography>
                </CardContent>
            </Box>

            <ViewApparelModal openView={openView} setOpenView={setOpenView} item={item} />
            {renderMenu()}
        </Card>
    );
};

export default ApparelCard;
