import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea, Stack } from "@mui/material";
import classes from "../../../styles/ManageEventsStyle";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import GroupAvatars from "./GroupAvatars";
import dayjs from "dayjs";
import EventDetailsModal from "./EventDetailsModal";

const ManageEventsCard = ({ id, title, description, time, date, location, photos, members, status, ghlForm }: any) => {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	return (
		<>
			<Card sx={classes.Card} onClick={handleClickOpen}>
				<CardActionArea sx={{
					...classes.CardActionArea, "&": {
						height: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "stretch",
					}
				}}>
					<Box sx={classes.CardActionAreaBox}>
						<CardMedia
							sx={classes.ManageEventsCardMedia}
							component="img"
							src={photos[0]?.url}
							alt="ManageEventsCardImg"
						/>
						<Box sx={classes.CardMediaBox}>
							<FmdGoodIcon sx={classes.FmdGoodIcon} />
							<Typography sx={classes.ManageEventsCardActionAreaTypo}>
								{location}
							</Typography>
						</Box>
						{(status === "cancel") && (
							<Box sx={{ position: "absolute", top: 10, right: 10 }}>
								<Typography sx={{
									color: "#fff",
									backgroundColor: "#ff0000d4",
									fontSize: "1.8rem",
									fontWeight: 600,
									textTransform: "capitalize",
									px: 1,
									borderRadius: "4px",
								}}>Cancelled</Typography>
							</Box>
						)}
					</Box>
					<CardContent sx={classes.CardContent}>
						<Stack alignItems="center" direction="row" spacing="3rem">
							<Typography gutterBottom sx={classes.CardTitle}>
								{title}
							</Typography>
						</Stack>
						<Typography sx={classes.RecentHuddlecardDescription}>
							{description}
						</Typography>
						<Stack
							marginTop="0.2rem"
							direction="row"
							justifyContent="space-between"
						>
							<Typography sx={classes.TimeTypo}>
								{time}
							</Typography>
							<Typography sx={classes.TimeTypo}>
								{dayjs(date).format("MMM DD, YYYY")}
							</Typography>
						</Stack>
						<Box sx={{ display: "flex" }}>
							<GroupAvatars users={members} />
						</Box>
					</CardContent>
				</CardActionArea>
			</Card>

			<EventDetailsModal
				open={open}
				setOpen={setOpen}
				id={id}
				title={title}
				description={description}
				date={date}
				time={time}
				location={location}
				photos={photos}
				members={members}
				status={status}
				ghlForm={ghlForm}
			/>
		</>
	);
};

export default ManageEventsCard;
