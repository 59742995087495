import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import Layout from "../../components/Layout/Layout";
import apiEndpoint from "../../config/environment";
import {
  isFetchBaseQueryError,
  useCreateBannerMutation,
} from "../../services/admin";
import UploadBox from "./Components/UploadBox";

const options = [
  {
    title: "Admin Login",
    value: "ADMIN_LOGIN",
  },
  {
    title: "Coach/Client Login",
    value: "COACH_CLIENT_LOGIN",
  },
  {
    title: "Client Header",
    value: "CLIENT_HEADER",
  },
  {
    title: "Coach Header",
    value: "COACH_HEADER",
  },
];

interface Type {
  title: string;
  value: string;
}

interface IFormInputs {
  type: Type;
  image: string;
}

const schema = yup.object().shape({
  type: yup.string().required("Required"),
  image: yup.string().required("Required"),
});

const UploadChangeImage = () => {
  const [setImageType] = useState<any>("");

  const [image, setImage] = useState({
    name: "",
    file: {},
    isUploading: false,
    url: "",
  });

  const [createBanner, { isLoading }] = useCreateBannerMutation();
  const navigate = useNavigate();
  
  const {
    handleSubmit,
    register,
    setValue,
    setError,
    control,
    trigger,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const handleChange = (event: any) => {
    const targetValue = event.target.value;
    const value = targetValue.value;
    setImageType(targetValue);
    setValue("type", value);
    setError("type", { type: "focus" }, { shouldFocus: true });
  };
  const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
    const payload = {
      contentType: data.type,
      imageUrl: data.image,
    };
    try {
      await createBanner(payload)
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
        });
      navigate("/change-image");
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error.data?.message);
      }
    }
  };

  const handleUploadImage = async (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target?.files[0];
    setImage({
      ...image,
      name: file.name,
      file: file,
      isUploading: true,
    });

    const formData = new FormData();
    formData.append("image", file);

    const promise = axios
      .request({
        method: "POST",
        url: `${apiEndpoint}/upload-one`,
        data: formData,
      })
      .then((res) => {
        setImage({
          ...image,
          isUploading: false,
          url: res.data,
        });
        setValue("image", res.data);
        trigger("image");
      })
      .catch(() => {
        setImage({
          ...image,
          isUploading: false,
        });
      });

    await toast.promise(promise, {
      pending: "Uploading Image",
      success: "Uploaded Image",
      error: "Error Uploading Image",
    });
  };

  return (
    <Layout>
      <div className="pt-[6.636rem] pb-[4.633rem] px-[16rem]">
        <h4 className="text-[#333333] text-[3.2rem] font-bold mb-[1.9rem]">
          Upload Image For
        </h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            sx={{
              marginTop: "2rem",
              marginBottom: "2rem",
              width: "50rem",
              "& .MuiInputBase-root": {
                height: "5.25rem",
                boxShadow: "0px 1px 4px rgb(0 0 0 / 10%)",
                borderRadius: "0.76rem",
              },
            }}
          >
            <Typography
              sx={{ fontSize: "2rem", fontWeight: 400, mb: "1.2rem" }}
            >
              Select
            </Typography>
            <Box sx={{ mt: 0 }}>
              <Controller
                control={control}
                name="type"
                render={({ field: { onChange, ...restField } }) => (
                  <Select
                    sx={{
                      maxWidth: "50rem",
                      width: "100%",
                      fontSize: "2rem",
                      fontWeight: 400,
                      borderColor: "#2165A2",
                      borderRadius: "2rem",
                      py: "0rem",
                      px: "1rem",
                      "& .MuiSelect-icon": {
                        width: "3rem",
                        height: "3rem",
                        top: "calc(50% - 1.5rem)",
                      },
                      "& .MuiMenuItem-root": {
                        fontSize: "1.8rem",
                      },
                      "& .MuiSelect-select": {
                        py: "1.8rem",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          "& .MuiMenuItem-root": {
                            fontSize: "1.8rem",
                            maxWidth: "50rem !important",
                          },
                        },
                      },
                    }}
                    fullWidth
                    {...restField}
                    placeholder="Select"
                    onChange={(event: any) => {
                      onChange(event);
                      handleChange(event);
                    }}
                    renderValue={(selected: any) => {
                      if (selected?.length === 0) {
                        return <span>Select One</span>;
                      }
                      return <span>{selected?.title}</span>;
                    }}
                  >
                    {options?.map((item: any, index: any) => (
                      <MenuItem key={index} value={item}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors?.type && (
                <p
                  style={{
                    fontSize: "10px",
                    color: "red",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {errors?.type?.message}
                </p>
              )}
            </Box>
          </FormControl>
          <Box sx={{ display: "flex", gap: "6rem" }}>
            <Box>
              <UploadBox
                name="image"
                accept="image/*"
                uploadTitle="Upload Image"
                type="photo"
                fileName="Image12.png"
                progress={100}
                status="completed"
                isUploading={image.isUploading}
                showImages
                handleFileChange={handleUploadImage}
                register={register}
              />
              {errors?.image?.message ? (
                <p className="text-red-500 py-[10px]">
                  {" "}
                  {errors.image.message}{" "}
                </p>
              ) : null}
            </Box>
            {image?.url && (
              <Stack direction="row" maxWidth={"100%"} maxHeight={"38.479rem"}>
                <div className="w-full h-full rounded-lg overflow-hidden relative">
                  <img
                    className="w-full h-full object-cover"
                    src={image?.url}
                    alt="Apparelimage"
                  />
                </div>
              </Stack>
            )}
          </Box>
          <div className="mt-[8rem]">
            <button
              disabled={isLoading || image?.isUploading}
              type="submit"
              className="bg-[#2165A2] rounded-[0.481505rem] w-[18.402rem] h-[4.508rem] text-white text-[1.80564rem] font-bold"
            >
              {(isLoading || image?.isUploading) ? "Loading..." : "Upload Image"}
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default UploadChangeImage;
