import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/Layout/Layout";
import {
  isFetchBaseQueryError,
  useCreateCheckinFormMutation,
} from "../../services/admin";
import classes from "../../styles/CreateFormStyle";
import Form from "./Form";

const CreateForm = () => {
  const [questionsData, setQuestionsData] = useState<any[]>([{
    question: "",
    label: "",
    type: "",
    length: "",
    questionNum: 1
  }]);
  const [title, setTitle] = useState("");
  const [isRequired, setisRequired] = useState("");

  const [createCheckinForm] =
    useCreateCheckinFormMutation();
  const navigate = useNavigate();
  const payload = {
    title: title,
    questions: questionsData,
  };
  const publishForm = async () => {
    if (title == "") {
      setisRequired("Required");
      return;
    }
    const hasEmptyQuestion = questionsData.some((obj:any) => !obj.label && !obj.question && !obj.type);

    if( hasEmptyQuestion ){ // Check to see question is filled before adding new question
      toast.info("Fill and save the question details before publishing");
      return;
    }
    try {
      await createCheckinForm(payload)
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          navigate("/checkin-forms");
        });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error?.data?.message);
      }
    }
  };
  return (
    <Layout>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap="2rem" alignItems="center">
          <Link to="/checkin-forms">
            <IconButton sx={classes.backArrow} disableRipple>
              <ArrowRightAltIcon />
            </IconButton>
          </Link>
          <Typography variant="h2" sx={classes.mainHeading}>
            Create New Check-In Form
          </Typography>
        </Stack>
        <Button
          variant="contained"
          sx={classes.pubBtn}
          disableElevation
          disableRipple
          disabled={questionsData.length == 0}
          onClick={publishForm}
        >
          Publish
        </Button>
      </Stack>
      <h6 className="after:!text-[red] text-[3.5rem] font-semibold mt-[6.2rem]">
        Title
      </h6>
      <div className="flex items-center mt-5 max-w-[90%]">
        <input
          type="text"
          className="bg-[#fff] px-10 text-[1.6rem] border border-[#D0D6DE] rounded-3xl font-medium outline-none h-24"
          placeholder="Title"
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <span style={{ color: "red" }}>{isRequired}</span>
      <Form setQuestionsData={setQuestionsData} questionsData={questionsData} />
    </Layout>
  );
};

export default CreateForm;
