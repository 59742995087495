import { Typography } from "@mui/material";
import Layout from "../../components/Layout/Layout";
import SettingsTabs from "./components/SettingsTabs";

const Settings = () => {
	return (
		<Layout>
			<Typography variant="h2" className="!mb-6">
				Settings
			</Typography>
			<SettingsTabs />
		</Layout>
	);
};

export default Settings;
