import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Logo from "../../assets/images/logo-color.png";
import iconLogout from "../../assets/images/iconLogout.svg";
import classes from "../../styles/SidebarStyle";
import { NavLink } from "react-router-dom";
import listItem from "../../utilities/Sidebar";
import { logoutAsync } from "../../store/reducers/auth.reducer";
import { useDispatch } from "react-redux";

const Sidebar = () => {
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logoutAsync());
  };
  return (
    <Box component="aside" sx={classes.sidebar}>
      <Box sx={{ webkitScrollbar: "0.5rem", webkitScrollbarTrack: "" }}></Box>
      <Box component="div" sx={classes.inside}>
        <Box component="div" sx={classes.sidebarLogo}>
          <img src={Logo} alt="" />
        </Box>
        <List id="side_link">
          {listItem.map((item) => (
            <NavLink
              to={item.path}
              key={item.id}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <ListItem disablePadding>
                <ListItemButton sx={classes.listItem}>
                  <ListItemIcon sx={classes.ico}>
                    <img src={item.icon} alt="" />
                  </ListItemIcon>
                  <ListItemText sx={classes.text}>{item.text}</ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>
          ))}

          <ListItem disablePadding onClick={logoutHandler}>
            <ListItemButton sx={classes.listItem}>
              <ListItemIcon sx={classes.ico}>
                <img src={iconLogout} alt="iconLogout" />
              </ListItemIcon>
              <ListItemText sx={classes.text}>Log Out</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default Sidebar;
