import { Box, Avatar, AvatarGroup } from "@mui/material";
import classes from "../../../styles/ManageEventsStyle";

export default function GroupAvatars({ users }: any) {
	function stringToColor(string: string) {
		let hash = 0;
		let i;

		/* eslint-disable no-bitwise */
		for (i = 0; i < string?.length; i += 1) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}

		let color = '#';

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			color += `00${value?.toString(16)}`?.slice(-2);
		}
		/* eslint-enable no-bitwise */

		return color;
	}


	function stringAvatar(name: string) {
		const hasSpace = name?.indexOf(' ') >= 0
		return {
			sx: {
				bgcolor: stringToColor(name),
			},
			children: hasSpace ? `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}` : name?.slice(0, 1)
		};
	}

	return (
		<Box sx={classes.GroupAvatarsBox}>

			<AvatarGroup max={4} sx={classes.GroupAvatar}>
				{users?.map((item: any, index: number) => (
					<Avatar key={index} {...stringAvatar(item?.full_name)}></Avatar>
				))}
			</AvatarGroup>
		</Box>
	);
}
