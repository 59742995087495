import { Paper, Box, Typography } from "@mui/material";
import UploadVidIcon from "../../../assets/images/upload-vid-icon.png";
import UploadImgIcon from "../../../assets/images/upload-img-icon.svg";
import UploadFile from "./UploadFile";

interface UploadBoxProps {
  type?: "video" | "image" | "photo";
  accept?: "image/*" | "video/*";
  fileName?: string;
  progress?: number;
  status?: string;
  showImages?: boolean;
  handleFileChange?: any;
  name?: string;
  register?: any;
  uploadTitle?: string
  isUploading?: boolean
}

const UploadBox = ({
  type,
  accept,
  handleFileChange,
  name,
  register,
  uploadTitle,
  isUploading
}: UploadBoxProps) => {
  return (
    <Box sx={{ maxWidth: "32rem" }}>
      <Paper
        sx={{
          height: "23rem",
          width: "32rem",
          p: "2.5rem",
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Box
          sx={{
            border: "1px dashed #939393",
            height: "100%",
            width: "100%",
            borderRadius: "0.685rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "0.6rem",
              height: "100%",
            }}
          >
            <Box
              component="img"
              src={type === "video" ? UploadVidIcon : UploadImgIcon}
              sx={{ width: "5.2rem" }}
            />
            <Box sx={{ display: "flex", alignItems: "center", textAlign: "center", height: "4.5rem", px: "1.4rem" }}>
              <>
                <Typography
                  sx={{
                    color: "#2165A2",
                    fontSize: "1.67rem",
                    fontWeight: 500,
                  }}
                >
                  {uploadTitle}
                </Typography>
              </>
            </Box>

            <UploadFile
              onChange={handleFileChange}
              accept={accept}
              name={name}
              register={register}
              isUploading={isUploading}
            />


          </Box>
        </Box>
      </Paper>


    </Box>
  );
};

export default UploadBox;
