import { useRef, forwardRef } from "react";
import { Box, Button } from "@mui/material";
const UploadFile = ({
  onChange: onUploadFile,
  accept,
  register,
  isUploading,
}: any) => {

  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const InputField = forwardRef(
    (
      { onChange, name, accept, style, hidden }: any,
      ref: any
    ): any => (
      <input
        name={name}
        type="file"
        ref={ref}
        onChange={(e: any) => onChange(e)}
        accept={accept}
        style={style}
        hidden={hidden}
        // accept={accept}
      />
    )
  );
  return (
    <Box>
      {/* Our custom button to select and upload a file */}
      <Button
        onClick={handleUploadClick}
        disabled={isUploading}
        sx={{
          fontSize: "1.67297rem",
          background: "#2165A2",
          minWidth: "15.312rem",
          height: "4.192rem",
          borderRadius: "0.446124rem",
          color: "#fff",
          textTransform: "capitalize",
          "&:hover": {
            background: "#2165A2",
          },
          "&.Mui-disabled": {
            opacity: 0.7,
            color: "#fff",
          },
        }}
        component="span"
      >
        Browse File
      </Button>

      <InputField
        name={"image"}
        type="file"
        ref={inputRef}
        onChange={onUploadFile}
        accept={accept ? accept : "*"}
        // hidden={true}
        style={{ display: "none" }}
        register={register}
      />
    </Box>
  );
};

export default UploadFile;
