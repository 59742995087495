import { Menu, MenuItem } from "@mui/material";
import React from "react";
import client from "../../../assets/images/profile.png";
import { useDeleteNoteMutation } from "../../../services/admin";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { JSX } from "react";

function NoteCard({ item, image, name }: any): JSX.Element {
  const [deleteNote, { isSuccess }] = useDeleteNoteMutation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const splitData = item?.note?.split("\n");
  const isMenuOpen = Boolean(anchorEl);
  const navigate = useNavigate();

  if (isSuccess) {
    toast.success("Note Deleted Successfully");
  }
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  // Handle Delete
  function handleDelete(_: any, id: any) {
    deleteNote(id);
  }

  function handleEdit(_: any, id: any) {
    navigate(`/edit-note/${id}`);
  }

  const menuId = "primary-search-account-menu";

  function renderMenu() {
    return (
      <>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          id={menuId}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={(e) => handleEdit(e, item?.id)}
            className="!text-3xl"
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={(e) => handleDelete(e, item?.id)}
            className="!text-3xl"
          >
            Delete
          </MenuItem>
        </Menu>
      </>
    );
  }
  return (
    <>
      <div
        className="px-14 pt-12 pb-8 rounded-[2rem] mb-8"
        style={{ backgroundColor: "#2C854133" }}
      >
        <div className="flex items-center gap-5 justify-start mb-8">
          <div className="w-[4.143rem] max-w-[4.143rem] h-[4.143rem] rounded-full overflow-hidden">
            <img
              src={image ? image : client}
              alt="clientImage"
              className="w-full h-full object-cover"
            />
          </div>
          <h6 className="font-semibold w-[50rem] truncate text-[1.9335rem]  text-[#1B2E42]">
            {name}
          </h6>
        </div>
        <div className="flex items-start justify-between pr-4">
          <div id="notes">
            <ul style={{ wordBreak: "break-word" }} className="pl-[3.2rem] font-bold text-4xl sm:text-5xl text-primary_blue break-normal">
              {splitData?.map((item: any, index: any) => {
                if (item) {
                  return <li key={index} className="flex items-start text-[2rem] leading-10 font-normal mb-2">{item}</li>
                }
              })}
            </ul>
          </div>
        </div>
        <p className="text-[2rem] mt-6 flex justify-end">
          {dayjs(item?.createdAt).format("MM-DD-YYYY")}
        </p>
        {renderMenu()}
      </div>
    </>
  );
}

export default NoteCard;
