import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/images/logonLogo.svg";
import classes from "../../styles/SidebarStyle";
import listItem from "../../utilities/Sidebar";

type Anchor = "left";

export default function SideDrawer() {
	const [state, setState] = React.useState({
		left: false,
	});

	const toggleDrawer = (anchor: Anchor, open: boolean) => (
		event: React.KeyboardEvent | React.MouseEvent
	) => {
		if (
			event.type === "keydown" &&
			((event as React.KeyboardEvent).key === "Tab" ||
				(event as React.KeyboardEvent).key === "Shift")
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	const list = (anchor: Anchor) => (
		<Box
			// component="aside"
			sx={classes.drawer}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<Box sx={classes.inside}>
				<Box component="div" sx={classes.sidebarLogo}>
					<img src={Logo} alt="" />
				</Box>
				<List id="side_link">
					{listItem.map((item) => (
						<NavLink
							to={item.path}
							key={item.id}
							className={({ isActive }) =>
								isActive ? "active" : ""
							}
						>
							<ListItem disablePadding>
								<ListItemButton sx={classes.listItem}>
									<ListItemIcon sx={classes.ico}>
										<img src={item.icon} alt="" />
									</ListItemIcon>
									<ListItemText sx={classes.text}>
										{item.text}
									</ListItemText>
								</ListItemButton>
							</ListItem>
						</NavLink>
					))}
				</List>
			</Box>
		</Box>
	);

	return (
		<div className="md:hidden">
			{(["left"] as const).map((anchor) => (
				<React.Fragment key={anchor}>
					<IconButton
						onClick={toggleDrawer(anchor, true)}
						className="w-16 h-16 !p-2"
					>
						<MenuIcon className="!w-full !h-full" />
					</IconButton>
					<Drawer
						anchor={anchor}
						open={state[anchor]}
						onClose={toggleDrawer(anchor, false)}
					>
						{list(anchor)}
					</Drawer>
				</React.Fragment>
			))}
		</div>
	);
}
