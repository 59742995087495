import React from "react";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Layout from "../../components/Layout/Layout";
import styles from "../../styles/CheckinFormStyle";
import ManageUserTabs from "./components/ManageUserTab";
import AddIcon from "@mui/icons-material/Add";
import { AntTab, AntTabs, classes } from "../../styles/ManageUserStyle";
import ClientInviteModal from "./components/clientInviteModal";
import CoachInviteModal from "./components/coachInviteModal";
import { useSelector, useDispatch } from "react-redux";
import { selectManageUserTabIndex } from "../../store/selectors";
import { setManageUserTabIndex } from "../../store/slices/admin.slice";

const ManageUsers = () => {
  // const [value, setValue] = React.useState<number>(0);
  const [clientOpen, setClientOpen] = React.useState(false);
  const [coachOpen, setCoachOpen] = React.useState(false);
  const dispatch = useDispatch();
  const value = useSelector(selectManageUserTabIndex);
  
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    // setValue(newValue);
    dispatch(setManageUserTabIndex(newValue))
  };

  const handleOpen = () => {
    if (value == 0) {
      setClientOpen(true);
    } else if (value == 1) {
      setCoachOpen(true);
    }
  };

  return (
    <Layout>
      <Box sx={styles.headWithBtn}>
        <Typography variant="h2" component="h2">
          {value === 0 ? " Manage Clients" : " Manage Coaches"}
        </Typography>
        <Button
          variant="contained"
          sx={styles.createFormBtn}
          onClick={handleOpen}
        >
          <AddIcon fontSize="large" sx={{ marginRight: "1rem" }} />
          {value === 0 ? "Add Client" : "Add Coach"}
        </Button>
        <Box sx={classes.antTabBox}>
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="ant example"
          >
            <AntTab label="Clients" />
            <AntTab label="Coaches" />
            {/* <AntTab label="Public" /> */}
          </AntTabs>
        </Box>
      </Box>
      <ManageUserTabs value={value} />
      <ClientInviteModal open={clientOpen} setOpen={setClientOpen} />
      <CoachInviteModal open={coachOpen} setOpen={setCoachOpen} />
      {/* <DateModal open={coachOpen} setOpen={setCoachOpen} /> */}
    </Layout>
  );
};

export default ManageUsers;
