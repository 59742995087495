
const classes = {
  backArrow: {
    width: "5rem",
    minWidth: "5rem",
    height: "5rem",
    background: "#fff",
    padding: "1rem",

    svg: {
      width: "100%",
      height: "100%",
      transform: " rotateY(180deg)",
      color: "#363848",
    },
  },
  pubBtn: {
    minWidth: "20.4rem",
    fontWeight: 600,
    fontSize: "1.8rem",
    lineHeight: "2.7rem",
    textTransform: "capitalize",
    borderRadius: "1rem",
  },
  sideItem: {
    background: "#FFFFFF",
    borderRadius: "2rem",
    padding: "2rem",
    height: "50rem",
    // height: "58.2rem",
    overflowY: "scroll",
  },
  item: {
    background: "#FFFFFF",
    borderRadius: "2rem",
    padding: "2rem",
    // height: "100%",
    height: "50rem",
    overflowY: "scroll",
  },
  plus: {
    width: "3.5rem",
    minWidth: "3.5rem",
    height: "3.5rem",
    background: "#2165A233",
    padding: "0.6rem",
    borderRadius: "0.5rem",
    svg: {
      width: "100%",
      height: "100%",
      color: "#18203A",
    },
  },
  list: {
    margin: "0 -2rem",
  },
  itemText: {
    display: "flex",
    justifyContent: "space-between",
  },
  listIconBtn: {
    width: "7.5rem",
    minWidth: "8rem",
    height: "3rem",
    borderRadius: "0.5rem",
    fontWeight: 600,
    fontSize: "1.6rem",
    lineHeight: "2.4rem",

    svg: {
      width: "2.4rem",
      minWidth: "2.4rem",
      height: "2.4rem",
    },
  },
  questions: {
    fontWeight: 500,
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    color: "#333333",
  },
  menu: {
    ".MuiList-root ": {
      boxShadow: "0 0 1.5rem rgba(51, 51, 51, 0.1)",
      borderRadius: "0.6rem",
      minWidth: "20.3rem",
      li: {
        fontSize: "1.8rem",
        fontWeight: 500,
        lineHeight: "2.8rem",
        color: "#299AFF",
        borderBottom: "0.2rem solid #F5F5F5",
      },
    },
  },
  menuBtn: {
    width: "3rem",
    minWidth: "3rem",
    height: "3rem",
    borderRadius: "1rem",
    padding: "0",
    svg: {
      width: "100%",
      height: "100%",
    },
  },
  select: {
    width: "100%",
    border: "none",
    ".MuiFormControl-root": {
      width: "100%",
      ".MuiInputBase-root ": {
        borderRadius: "1.6rem",
        borderColor: "#D0D6DE",
        overflow: "hidden",
        width: "100%",
        height: "6rem",
        "> svg": {
          fontSize: "4rem",
        },
      },
    },

    ".MuiSelect-select": {
      width: "100%",
      fontSize: "1.6rem",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#333333",
      background: "rgba(162, 163, 172, 0.04)",
    },
  },
  selectItem: {
    fontSize: "1.6rem",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#333333",
  },
  select1: {
    fontSize: "1.2rem",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#333333",
  },
  setTypo: {
    fontWeight: 500,
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    color: "rgba(51, 51, 51, 0.6)",
  },
  input: {
    "& .MuiInputBase-input": {
      fontWeight: 500,
      fontSize: "1.6rem",
      lineHeight: "2.4rem",
      color: "#33333399",
    },
  },
  mainHeading: {
    fontSize: "3.5rem",
  },
};

export default classes;
