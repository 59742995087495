import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box, Grid, Stack } from "@mui/material";
import theme from "../../../theme";
import { Avatar } from "@mui/material";
import {
  useCancelEventMutation,
  useDeleteEventMutation,
  useUpdateGHLEventMutation,
  isFetchBaseQueryError,
} from "../../../services/admin";
import { toast } from "react-toastify";
import UserDetailModal from "./UserDetailModal";
import Popover from '@mui/material/Popover';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        textAlign: "center",
        backgroundColor: "#2165A2",
        padding: "1rem 4rem",
        color: "white",
        fontSize: "18px",
        fontWeight: "600px",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon className="!text-5xl text-white" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function EventDetailsModal({
  id,
  title,
  description,
  time,
  date,
  location,
  photos,
  members,
  open,
  setOpen,
  status,
}: any) {
  const [deleteEvent] = useDeleteEventMutation();
  const [cancelEvent] = useCancelEventMutation();
  const [updateGHLEvent, { isLoading }] = useUpdateGHLEventMutation();
  const [openModal, setOpenModal] = React.useState(false);
  const [data, setData] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);

  const handleClose = () => {
    setOpen(false);
  };

  const cancelEventHandler = async () => {
    try {
      await cancelEvent({ id: id })
        .unwrap()
        .then(() => {
          toast.success("Event Cancelled");
          handleClose();
        });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error.data.message);
      }
    }
  };

  function handleModal(id: any) {
    const filterRecord = members?.find((item: any) => item?.id === id);
    setData(filterRecord);
    setOpenModal(true);
  }

  const handleDeleteOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      await deleteEvent({ id: id })
        .unwrap()
        .then(() => {
          toast.success("Event Deleted");
          handleClose();
          handleDeleteClose();
        });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error.data.message);
      }
    }
  };

  const updateEventHandler = async () => {
    const payload = {
      id: id
    };

    try {
      await updateGHLEvent(payload)
        .unwrap()
        .then(() => {
          toast.success("Event Updated");
          handleClose();
          handleDeleteClose();
        });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error.data.message);
      }
    }
  };



  return (
    <Box>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          ".MuiPaper-root": {
            minWidth: "94rem",
            [theme.breakpoints.down("md")]: {
              minWidth: "unset",
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Event Details
        </BootstrapDialogTitle>
        <DialogContent className="!px-12 !pt-14 !pb-16 modal-inner-hide">
          <div className="bg-[#D9D9D9] rounded-2xl overflow-hidden">
            <img
              className="w-full h-[22rem] object-cover"
              src={photos[0].url}
              alt=""
            />
          </div>
          <div className="relative">
            <div className="mt-7 flex items-center gap-8">
              <p className="text-3xl text-[#33333399]">
                {time} | {location}
              </p>
            </div>
            <Typography variant="h2" color="primary" className="!mt-5">
              {title}
            </Typography>
            {/* <ShareIcon className="!text-5xl absolute right-0 top-6" /> */}
          </div>
          <p className="text-[2.2rem] pt-5 pb-5">{description}</p>
          {(members?.length) ? (
            <div className="bg-[#2165A233] rounded-[1rem] overflow-hidden p-8 !pr-4 mt-6">
              <Typography variant="h4" color="primary">
                Participants
              </Typography>
              <div className="overflow-y-auto mt-8 list-inner-hide pr-4">
                <Grid container spacing="2rem">
                  {members?.map((member: any, index: any) => {
                    return (
                      <Grid item xs={6} key={index}>
                        <div className="bg-white rounded-[1rem] p-6 ">
                          <div className="bg-white rounded-[1rem] p-6 pb-0 flex items-center gap-5">
                            <div className="min-w-20 h-20 overflow-hidden rounded-full">
                              <Avatar
                                sx={{
                                  bgcolor: "#2165A2",
                                  fontSize: "2.4rem",
                                  overflow: "hidden",
                                }}
                                alt="Remy Sharp"
                                src={member?.user?.profile_photo}
                              >
                                {member?.full_name?.substring(0, 1)}
                              </Avatar>
                              {/* <img
													 className="w-20 h-20 object-cover"
													 src={member?.user?.profile_photo} alt="" /> */}
                            </div>
                            <div className="">
                              <h6 className="font-bold text-3xl max-w-[26rem] truncate">
                                {member?.full_name}
                              </h6>
                              <p className="text-[#ADADAD] text-2xl">
                                {member?.email}
                              </p>
                            </div>
                          </div>
                          <div
                            className="cursor-pointer text-right"
                            onClick={() => handleModal(member?.id)}
                          >
                            <h6 className="font-normal leading-[2.4rem]  text-[#2165A2] text-[1.6rem]">
                              Details
                            </h6>
                          </div>
                        </div>
                        <UserDetailModal
                          index={index}
                          openModal={openModal}
                          setOpenModal={setOpenModal}
                          member={data}
                          title={title}
                          description={description}
                          time={time}
                          date={date}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </div>
          ) : ""}
          <div className="flex justify-center mt-12 gap-10">
            <Button
              // onClick={() => navigate(`/manage-event/${id}`)}
              onClick={updateEventHandler}
              variant="outlined"
              fullWidth
              disabled={isLoading}
              className="!border-2 !border-[#2165A2] max-w-[50rem] !mx-auto h-24 text-[#2165A2] !text-3xl !capitalize"
            >
              {isLoading ? "Updating Event" : "Update Event"}
            </Button>

            {status !== "cancel" && (
              <Button
                onClick={() => cancelEventHandler()}
                variant="outlined"
                fullWidth
                color="error"
                className="!border-2  max-w-[50rem] !mx-auto h-24 !text-3xl !capitalize"
              >
                Cancel Event
              </Button>
            )}

            <Button
              onClick={handleDeleteOpen}
              aria-describedby="delete-pop"
              variant="outlined"
              fullWidth
              color="error"
              className="!border-2  max-w-[50rem] !mx-auto h-24 !text-3xl !capitalize"
            >
              Delete Event
            </Button>

            <Popover
              id="delete-pop"
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleDeleteClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <Box sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ pb: 1 }}>Are you sure, Delete this event?</Typography>
                <Stack flexDirection="row" gap="10px" justifyContent="center">
                  <Button
                    variant="contained"
                    disableElevation
                    size="small"
                    color="error"
                    sx={{ fontSize: "12px", maxWidth: "80px" }}
                    onClick={handleDelete}
                  >Yes</Button>
                  <Button
                    onClick={handleDeleteClose}
                    variant="contained"
                    disableElevation
                    size="small"
                    color="primary"
                    sx={{ fontSize: "12px", maxWidth: "80px" }}
                  >No</Button>
                </Stack>
              </Box>
            </Popover>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
}
