import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Box, Button, Checkbox, FormControl, IconButton, LinearProgress, MenuItem, Modal, Paper, Select, Typography, useTheme } from "@mui/material";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { forwardRef, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CompIcon from "../../assets/images/import-device.svg";
import VideoIcon from "../../assets/images/vid-icon.svg";
import apiEndpoint from "../../config/environment";
import { isFetchBaseQueryError, useCreateBulkCallsMutation, useGetAllClientsQuery, useGetAllCoachesQuery } from "../../services/admin";

const StyledFormControl = styled(FormControlLabel)<FormControlLabelProps>(
  ({ theme }) => ({
    "& .MuiTypography-root": {
      fontSize: "3.5rem",
      color: theme.palette.secondary.main,
      fontWeight: 400,
    },
    "& .MuiSvgIcon-root": {
      width: "2.5rem",
      height: "2.5rem",
    },
  })
);

const selectItemStyle = {
  fontSize: "1.6rem",
  fontWeight: 400,
  lineHeight: "24px",
  color: "#333333",
  "&.Mui-selected": {
    backgroundColor: "rgb(33 101 162 / 44%)"
  }
};

const UploadBulkCalls = (props: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isTrue, setIsTrue] = useState(true);
  const { data: clientData } = useGetAllClientsQuery({ refetchOnMountOrArgChange: true });
  const { data: coachData } = useGetAllCoachesQuery({ refetchOnMountOrArgChange: true });
  const dropData = isTrue ? coachData?.data?.users : clientData?.data?.clients;
  const [selected, setSelected] = useState("")
  const [userId, setUserId] = useState("");
  const [error, setError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [createBulkCalls] = useCreateBulkCallsMutation();

  //NEW STATES
  const [files, setFiles] = useState({
    selected: false,
    count: 0,
    selectedFiles: [] as any[]
  });

  const onSubmit = async () => {

    const formData = new FormData();

    for (let i = 0; i < files?.selectedFiles?.length; i++) {
      formData.append("images", files?.selectedFiles[i]);
    }

    setIsUploading(true);
    axios.request({
      method: "POST",
      url: `${apiEndpoint}/upload-bulk-videos`,
      data: formData
    }).then((res) => {
      createData(res.data);

    }).catch(() => {
      setIsUploading(false);
      toast.error("Error Uploading Videos");
      // handleClose();
    });

  };

  const createData = async (res: any) => {
    // PAYLOAD TO ADD VIDEO URLS IN DATA BASE
    const payload = {
      callType: props.callType,
      files: res,
      receiverId: userId
      // title: data.title,
      // thumbnail: data.image,
      // detail: data.detail,
    };

    try {
      await createBulkCalls(payload).unwrap().then((res: any) => {
        toast.success(res?.message)
        navigate("/resources-library")
        setIsUploading(false);
        // handleClose()
      });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error?.data?.message);
      }
    }
  }

  const handleUploadVideo = async (e: any) => {
    if (props.callType !== "huddle" && !userId) {
      setError(true);
      return;
    }
    if (!e.target.files) {
      return;
    }
    const temp = e.target?.files;
    const selectedFiles = Object.values(temp) as any[];

    setFiles({
      ...files,
      selected: true,
      selectedFiles: selectedFiles
    });
  };

  function handleChange() {
    setIsTrue(!isTrue);
    setUserId("");
    setSelected("");
  }

  const handleSelectChange = (event: any) => {
    setSelected(event.target.value)
    if (event.target.value?.id) {
      setUserId(event.target.value?.id);
      setError(false);
    }
  };

  const handleClose = () => {
    setFiles({
      ...files,
      selected: false,
      selectedFiles: []
    })
  };

  return (
    <>
      <Box sx={{ py: "2.2rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "3.8rem",
            mb: "4.8rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "3.5rem",
              color: theme.palette.secondary.main,
              fontWeight: 600,
              textTransform: "capitalize"
            }}
          >
            {props.callType === "huddle" ? "Upload Huddles" : "Upload Calls for"}
          </Typography>
          {props.callType !== "huddle" ? (
            <Box>
              <StyledFormControl
                name="check"
                value="coach"
                checked={isTrue}
                control={<Checkbox />}
                label="Coach"
                onChange={handleChange}
              />
              <StyledFormControl
                name="check"
                value="client"
                control={<Checkbox />}
                label="Client"
                checked={!isTrue}
                onChange={handleChange}
              />
            </Box>
          ) : ""}
        </Box>

        {props?.callType !== "huddle" ? (
          <Box sx={{
            display: "flex", justifyContent: "space-between",
            flexWrap: "wrap", mb: "2.7rem",
            [theme.breakpoints.down("md")]: { gap: "3rem", },
            maxWidth: "75rem"
          }}>

            <Box>
              <FormControl sx={{ width: "32rem", "& .MuiInputBase-root": { height: "5.7rem" } }}>
                <Typography sx={{ fontSize: "2rem", fontWeight: 400, mb: "1.2rem" }}>
                  Select {isTrue ? "Coach" : "Client"}:
                </Typography>

                <Select
                  fullWidth
                  value={selected}
                  sx={{
                    fontSize: '2rem',
                    fontWeight: 400,
                    boxShadow: "0px 1px 4px rgb(0 0 0 / 10%)",
                    borderColor: '#2165A2',
                    backgroundColor: '#fcfcfb',
                    borderRadius: "7px",
                    py: "0rem",
                    px: "1.2rem",
                    "& .MuiSelect-icon": {
                      width: "3rem",
                      height: "3rem",
                      top: "calc(50% - 1.5rem)"
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: '1.8rem',
                    },
                    "& .MuiSelect-select": {
                      py: "1.8rem"
                    }
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        "& .MuiMenuItem-root": {
                          fontSize: "1.8rem"
                        }
                      },
                    },
                  }}
                  placeholder="Select"
                  onChange={(event: any) => {
                    handleSelectChange(event)
                  }}
                  renderValue={(value: any) => {
                    if (value === "") {
                      return <span>Select Coach</span>;
                    }
                    return <span>{value?.full_name}</span>;
                  }}
                >
                  {dropData?.map((user: any, index: number) => {
                    return (
                      <MenuItem
                        value={user}
                        sx={selectItemStyle}
                        key={index}
                      >
                        <span className="truncate max-w-[28rem]">{user?.full_name}</span>
                      </MenuItem>
                    )
                  })}
                </Select>
                {(error) && (
                  <p style={{ fontSize: "10px", color: "red" }}>
                    Select User to Upload Videos
                  </p>
                )}
              </FormControl>

            </Box>
          </Box>
        ) : ""}

        <Box>
          <Typography sx={{ color: "#333333", fontSize: "2.8rem", fontWeight: 600, mb: 2 }}>Upload Videos</Typography>
          <Paper sx={{ p: 3 }}>
            <Box sx={{
              border: "1px dashed #939393",
              height: "100%",
              width: "100%",
              borderRadius: "0.685rem",
              px: 2,
              py: "6rem"
            }}>
              <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                <UploadDeviceBox handleUploadVideo={handleUploadVideo} />

                {/* <BulkUploadDrive /> */}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>

      <FilesSelectedModal files={files} setFiles={setFiles} submit={onSubmit} isUploading={isUploading} handleClose={handleClose} />
    </>
  );
};

export default UploadBulkCalls;

const UploadDeviceBox = ({ handleUploadVideo }: any) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const InputField = forwardRef(({ onChange, name, accept, style, hidden }: any, ref: any): any => (
    <input
      name={name}
      type="file"
      ref={ref}
      onChange={(e: any) => onChange(e)}
      accept={accept}
      style={style}
      hidden={hidden}
      multiple
    // accept={accept}
    />
  ));

  return (
    <>
      <Box sx={boxStyle} onClick={handleUploadClick}>
        <Box component="img" src={CompIcon} sx={{ height: "6rem", mb: "1rem" }}></Box>
        <Typography sx={{ color: "#333333", fontSize: "2.2rem", fontWeight: 500 }}>Import From Device</Typography>
      </Box>
      <InputField
        name={"video"}
        type="file"
        ref={inputRef}
        onChange={handleUploadVideo}
        accept={"video/mp4"}
        // hidden={true}
        style={{ display: "none" }}
      />
    </>
  )
}

const FilesSelectedModal = ({ files, setFiles, submit, isUploading, handleClose }: any) => {
  const removeFile = (name: string) => {
    const temp = files?.selectedFiles?.filter((file: any) => file.name !== name);
    setFiles({
      ...files,
      selectedFiles: temp
    });
    if (temp?.length === 0) {
      handleClose()
    }
  }
  const totalBytes = files?.selectedFiles.reduce((acc: number, obj: any) => { return acc + obj.size; }, 0);
  const totalSize = (totalBytes / 1048576).toFixed(2);

  return (
    <Modal
      open={files.selected}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Box sx={{ maxHeight: "60vh", overflow: "auto", p: 3 }}>

          {files?.selectedFiles?.map((file: any, idx: any) => {
            return (
              <VideoCard
                key={idx}
                name={file?.name}
                date={file?.lastModifedDate}
                size={file?.size}
                removeFile={removeFile}
                isUploading={isUploading}
              />
            )
          })}
          {isUploading && (
            <Box sx={{ width: '100%', mt: "4rem" }}>
              <Typography sx={{ fontSize: "2rem", fontWeight: 400, opacity: 0.7, mb: "6px" }}>
                Uploading {files?.selectedFiles?.length} Videos ({totalSize}MB)
              </Typography>
              <LinearProgress sx={{ height: "1.4rem", borderRadius: "8px" }} />
            </Box>
          )}
          {!isUploading && (
            <>
              <Typography sx={{ fontSize: "2rem", fontWeight: 400, opacity: 0.7, mb: "6px", mt: "4rem" }}>
                Uploading {files?.selectedFiles?.length} Videos ({totalSize}MB)
              </Typography>
              <Box sx={{ mt: "1rem", display: "flex", gap: 2 }}>
                <Button
                  onClick={submit}
                  // disabled={image.isUploading || video.isUploading || isLoading}
                  sx={{
                    background: "#2165A2",
                    borderRadius: "0.485rem",
                    height: "4.5rem",
                    width: "18rem",
                    color: "#fff",
                    fontSize: "1.8rem",
                    textTransform: "capitalize",
                    "&:hover": {
                      background: "#2165A2",
                    },
                    "&.Mui-disabled": {
                      opacity: 0.7,
                      color: "#fff"
                    }
                  }}
                >
                  Upload Calls
                </Button>
                <Button
                  onClick={handleClose}
                  // disabled={image.isUploading || video.isUploading || isLoading}
                  sx={{
                    background: "transparent",
                    borderRadius: "0.485rem",
                    border: "1px solid #2165A2",
                    height: "4.5rem",
                    width: "18rem",
                    color: "#2165A2",
                    fontSize: "1.8rem",
                    textTransform: "capitalize",
                    "&:hover": {
                      background: "transparent",
                      border: "1px solid #2165A2"
                    },
                    "&.Mui-disabled": {
                      opacity: 0.7,
                      color: "#fff"
                    }
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  )
}

const VideoCard = ({ name, size, removeFile, isUploading }: any) => {
  return (
    <Paper sx={{ mb: "2.4rem" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem", p: 2 }}>
          <Box component="img" src={VideoIcon} sx={{ width: "4.2rem" }}></Box>
          <Typography sx={{ color: "#333333", fontWeight: 600, fontSize: "2rem", maxWidth: "34rem", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{name}</Typography>
          <Typography sx={{ fontSize: "1.8rem", fontWeight: 400, opacity: 0.8 }}>({(size / 1048576).toFixed(2)} MB)</Typography>
        </Box>
        {!isUploading && (
          <Box>
            <IconButton aria-label="remove" sx={{ mr: "1.8rem" }} onClick={() => removeFile(name)}>
              <CancelOutlinedIcon sx={{ fontSize: "3rem" }} />
            </IconButton>
          </Box>
        )}
      </Box>
    </Paper>
  )
}

const boxStyle = {
  display: "flex", alignItems: "center", flexDirection: "column", minWidth: "32rem", borderRadius: "4px", py: "2.6rem", boxShadow: "0px 0.47393px 2.4px rgba(0, 0, 0, 0.15)", cursor: "pointer"
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: "8px",
  boxShadow: 20,
  overflow: "hidden",
  '&:focus-visible': {
    outline: "none"
  }
};

