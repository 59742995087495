import Grid from "@mui/material/Grid";
import classes from "../../styles/CreateFormStyle";
import { Box, Typography, Stack, IconButton, Button } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import TocIcon from "@mui/icons-material/Toc";
import { useState } from "react";
// import BasicSelect from "../../components/Select";
import Question1 from "./components/Question1";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

interface questionI {
  id: string;
  question: string;
  label: string;
  type: string;
  length: string;
  questionNum: number
}

const Form = ({ setQuestionsData, questionsData }: any) => {
  const [activeData, setActiveData]: any = useState(1);

  const [type, _] = useState("textfield");
  const [length, __] = useState("");

  const AddHandler = () => {
    const hasEmptyQuestion = questionsData.some((obj:any) => !obj.label && !obj.question && !obj.type);

    if( hasEmptyQuestion ){ // Check to see question is filled before adding new question
      toast.info("Fill the question details before creating new question");
      return;
    }

    const questionNums = questionsData?.map((ques:any) => ques.questionNum );

    // Initially set question number 0 for first question
    // Else get the max question number and increase by 1 to question number
    const lastQuesNumber = questionNums?.length ? Math.max(...questionNums) : 0;

    const question = {
      question: "",
      label: "",
      type: "",
      length: "",
      questionNum: lastQuesNumber + 1
    };

    setActiveData(question.questionNum);
    setQuestionsData([...questionsData, question]);
  };
  
  function handleDeleteQuestion(id: number) {
    const filteredQuestions = questionsData.filter(
      (question: questionI) => question.questionNum !== id
    );
    for (let i = 0; i < filteredQuestions.length; i++) {
      filteredQuestions[i].questionNum = i + 1;
    }
    setQuestionsData(filteredQuestions);
  }

  return (
    <Grid
      container
      spacing="2rem"
      marginTop="3rem"
      height="calc(100vh - 25.9rem)"
      minHeight="60rem"
    >
      <Grid item xs={2.6}>
        <Box sx={classes.item}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Content</Typography>
            <IconButton onClick={AddHandler} sx={classes.plus} disableRipple>
              <AddIcon />
            </IconButton>
          </Stack>
          <List sx={classes.list}>
            {questionsData &&
              questionsData.map((question: questionI, index: number) => (
                <ListItem
                  key={question.questionNum}
                  // onClick={() => handleActive(question.questionNum)}
                  disablePadding
                  sx={{
                    backgroundColor:
                      activeData === question.questionNum ? "#c3c3c3" : "white",
                  }}
                >
                  <ListItemButton disableTouchRipple>
                    <ListItemText>
                      <>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Stack
                            direction="row"
                            gap="1.4rem"
                            alignItems="center"
                          >
                            <Button
                              disableElevation
                              disableRipple
                              startIcon={<TocIcon />}
                              variant="contained"
                              sx={classes.listIconBtn}
                            >
                              {index + 1}
                            </Button>
                            <Typography variant="body1" sx={classes.questions}>
                              Question {question?.questionNum}
                            </Typography>
                          </Stack>
                          <DeleteIcon
                            onClick={() => handleDeleteQuestion(question?.questionNum)}
                            sx={{ width: "24px", height: "24px" }}
                          />
                        </Stack>
                      </>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Box>
      </Grid>
      <Grid item xs={6.8}>
        <Box sx={classes.item}>
          {questionsData &&
            questionsData.map((question: questionI, index: number) => (
              <div key={question.questionNum}>
                <Question1
                  setQuestionsData={setQuestionsData}
                  questionsData={questionsData}
                  length={length}
                  type={type}
                  id={question.id}
                  activeData={activeData}
                  index={index}
                  questionNum={question?.questionNum}
                />
              </div>
            ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Form;
