import {
  Box,
  Button,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ClientProfile from "../../../assets/images/profile.png";
import { isFetchBaseQueryError, useUpdateUserWinRatingMutation } from '../../../services/admin';
import classes from "../../../styles/LogonStyle";

const EditManagePointsUser = ({ data }: any) => {
  const [winRate, setWinRate] = useState(data?.user?.winRating || 0);
  const scoreChangeHandler = (event: any) => {
    const value = event.target.value;
    if (event.charCode >= 48 && event.charCode <= 57 || value < 0) {
      return
    } else {
      setWinRate(value)
    }
  }
  const [updateUserWinRating, { isLoading }] = useUpdateUserWinRatingMutation();
  const navigate = useNavigate();
  const submitHandler = async () => {
    if (!winRate) {
      return toast.error("Win Rating is Required");
    }
    if(Number(winRate) > 1000){
      toast.error("Invalid Score Value, Max Value 1000");
      return;
    }

    const payload = {
      userId: data?.user?.id,
      data: {
        winRating: winRate ? winRate : 0
      }
    }

    try {
      await updateUserWinRating({ payload })
        .unwrap()
        .then(() => {
          toast.success("User Win Rating Updated Successfully");
          navigate("/manage-points")
        });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error.data.message);
      }
    }
  }
  return (
    <Box sx={{ ...classes.editUserMain }}>
      <Box maxWidth="70rem">
        <Typography gutterBottom sx={{ ...classes.edit_typo, mx: "6rem" }}>
          <img src={data?.user?.profile_photo ? data?.user?.profile_photo : ClientProfile} />
        </Typography>
        <Grid container columnSpacing={"3.2rem"} sx={{ maxWidth: "66rem", margin: "0 auto" }}>
          <Grid item xs={6} sx={classes.fromWrapper}>
            <Box>
              <Box component="div" sx={classes.form_blk}>
                <Typography
                  variant="h6"
                  sx={classes.formlabel}
                  className="require"
                >
                  Client
                </Typography>
                <TextField
                  sx={classes.logonTextField}
                  id="outlined-basic"
                  variant="outlined"
                  type="email"
                  fullWidth
                  placeholder="Select Clients"
                  disabled
                  value={data?.user?.full_name}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} component="div" sx={classes.fromWrapper}>
            <Box>
              <Box component="div" sx={classes.form_blk}>
                <Typography
                  variant="h6"
                  sx={classes.formlabel}
                  className="require"
                >
                  Coach Name
                </Typography>
                <TextField
                  sx={classes.logonTextField}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  fullWidth
                  placeholder="Placehodler"
                  disabled
                  value={data?.user?.coach?.full_name}
                />
              </Box>
              
            </Box>
          </Grid>
          <Grid item xs={12} pt={0} component="div" sx={classes.fromWrapper}>
            <Box>
              <Box component="div">
                <Typography
                  variant="h6"
                  sx={classes.formlabel}
                  className="require"
                >
                  Points
                </Typography>
                <TextField
                  sx={classes.logonTextField}
                  id="outlined-basic"
                  variant="outlined"
                  type="number"
                  fullWidth
                  placeholder="Enter Points"
                  value={winRate}
                  onChange={scoreChangeHandler}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} mt={5}>
            <Button variant="contained" sx={classes.createFormBtn} disabled={isLoading} onClick={submitHandler}>
              Save
            </Button>
          </Grid>

        </Grid>
      </Box>
    </Box>
  );
};

export default EditManagePointsUser;