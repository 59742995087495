import { Typography, Stack, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import Layout from "../../components/Layout/Layout";
import classes from "../../styles/CheckinFormStyle";
import EditUser from "./components/EditUser";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";


const EditUsers = () => {
    const navigate = useNavigate()

    return (
        <Layout>
            <Box sx={classes.headWithBtn}>
                <Stack direction="row" gap="2rem">
                    <IconButton
                        sx={classes.backArrow}
                        disableRipple
                        onClick={() => {
                            navigate(-1)
                        }}
                    >
                        <ArrowRightAltIcon />
                    </IconButton>

                    <Typography variant="h2" component="h2">
                        Edit User Detail
                    </Typography>
                </Stack>

            </Box>
            <EditUser navigate={navigate} />
        </Layout>
    );
};

export default EditUsers;