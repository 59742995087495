import {
  Box,
  Button,
  Grid,
  TextField,

} from "@mui/material";
import classes from "../../../styles/LogonStyle";
import ClientProfile from "../../../assets/images/profile.png";
import cameraIcon from "../../../assets/images/camera.svg";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import apiEndpoint from "../../../config/environment";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../../store/slices/auth.slice";
import { updatedProfile } from "../../../apis/auth-apis";

interface IFormInputs {
  image: string;
  full_name: string;
  email: string;
  phoneNo: string;
}

const schema = yup.object().shape({
  image: yup.string().required("Required").nullable(),
  email: yup.string().email().required("Required"),
  full_name: yup.string().required("Required").nullable(),
  phoneNo: yup.string().min(9,"Phone number must be at least 9 characters").max(12,"Phone number max will be 12 characters").required("Required").nullable(),
});



const ViewForm = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const [image, setImage] = useState({
    name: "",
    file: {},
    isUploading: false,
    url: user?.profile_photo,
  });
  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),

  });

  // Setting values of Fields by using RHF
  useEffect(() => {
    setValue("email", user?.email)
    setValue("image", user?.profile_photo)
    setValue("phoneNo", user?.phoneNo)
    setValue("full_name", user?.full_name)
  }, [])


  const handleUploadImage = async (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target?.files[0];
    setImage({
      ...image,
      name: file.name,
      file: file,
      isUploading: true
    });

    const formData = new FormData();
    formData.append("image", file);

    setLoading(true)
    const promise = axios.request({
      method: "POST",
      url: `${apiEndpoint}/upload-profile`,
      data: formData
    }).then((res) => {
      setImage({
        ...image,
        isUploading: false,
        url: res.data
      });
      setLoading(false)
      setValue("image", res.data);
      trigger("image");
    }).catch(() => {
      setImage({
        ...image,
        isUploading: false
      });
    });

    await toast.promise(promise, {
      pending: "Uploading Image",
      success: "Image Uploaded",
      error: "Error Uploading Thumbnail",
    });
  };
  const allowOnlyNumber = (e: any) => {
    const enteredValue = e.target.value.replace(/\D/g, "");
    return enteredValue;
  }
  const onSubmit = (data: IFormInputs) => {
    setLoading(true)
    const payload = {
      email: data.email,
      fullName: data.full_name,
      phone: data.phoneNo,
      profilePhoto: data.image,
    }
    updatedProfile(payload, setLoading)
      .then((result) => {
        setLoading(false);
        dispatch(setUser(result?.data?.user));
      })
      .catch((error: any) => {
        setLoading(false);
        toast.error(error?.message);
      });
  };
  return (
    <>

      <Box sx={classes.editUserMain} className="!mt-16">
        <form >
          <Box maxWidth="65rem" className="mx-auto">
            <Box className="flex justify-center items-end">
              <Box sx={classes.edit_typo}>
                <img className="w-full h-full object-cover" src={ image?.url ?  image?.url : ClientProfile} alt="profileImage" />
              </Box>
              <button
                type="button"
                className="relative -ml-[4.8rem] w-[4.8rem] p-[0.6rem] h-[4.8rem] min-w-[4.8rem] rounded-full bg-[#2165A2] cursor-pointer overflow-hidden border-2 border-white"
              >
                <img src={cameraIcon} alt="" className="cursor-pointer" />
                <input
                  type="file"
                  className="opacity-0 absolute top-0 left-0 z-10 "
                  accept="image/*"
                  onChange={handleUploadImage}
                />
              </button>
              {errors?.image?.message ? (
                <p className="text-red-500"> {errors?.image?.message} </p>
              ) : null}
            </Box>
            <Grid
              container
              gap="3.2rem"
              sx={{ margin: "4rem auto 0 auto", flexWrap: "nowrap" }}
            >

              <Grid item xs={12}>
                <Box component="div" sx={classes.form_blk}>
                  <h6 className="text-[1.8rem] mb-[1.3rem] font-semibold">
                    Full Name
                  </h6>
                  <Controller
                    control={control}
                    name="full_name"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={classes.logonTextField}
                        variant="outlined"
                        type="text"
                        fullWidth
                        placeholder="Enter Name"
                        error={!!errors.full_name}
                        helperText={errors.full_name ? errors.full_name?.message : null}
                      />
                    )}

                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box component="div" sx={{ width: "100%" }}>
                  <h6 className=" text-[1.8rem] mb-[1.3rem] font-semibold">
                    Phone
                  </h6>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="phoneNo"
                    render={({ field: { onChange, value, name } }) => (
                      <TextField
                        name={name}
                        onChange={(text: any) => onChange(allowOnlyNumber(text))}
                        value={value}
                        sx={classes.logonTextField}
                        variant="outlined"
                        type="text"
                        fullWidth
                        placeholder="+1231488787"
                        error={!!errors.phoneNo}
                        helperText={errors.phoneNo ? errors.phoneNo?.message : null}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={6} component="div">
              <Box>
                <Box component="div" >
                  <h6 className=" text-[1.8rem] mb-[1.3rem] font-semibold">
                    Email
                  </h6>
                  <Controller
                    control={control}
                    name="email"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={classes.logonTextField}
                        variant="outlined"
                        type="email"
                        fullWidth
                        inputProps={
                          { readOnly: true, }
                        }
                        placeholder="example@gmail.com"
                        error={!!errors.email}
                        helperText={errors.email ? errors.email?.message : null}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Grid>

            <Button type="submit" disabled={loading} onClick={handleSubmit(onSubmit)} sx={classes.UpdateBtn}>Update</Button>
          </Box>
        </form>
      </Box>

    </>
  );
};

export default ViewForm;
