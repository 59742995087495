import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { IconButton, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout/Layout";
import classes from "../../../styles/CreateFormStyle";
import EditForm from "./EditForm";

const EditEvent = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/manage-events");
  };

  return (
    <Layout>
      <div>
        <Stack direction="row" gap="2rem">
          <IconButton sx={classes.backArrow} disableRipple onClick={goBack}>
            <ArrowRightAltIcon />
          </IconButton>
          <Typography variant="h2">Edit Event Details</Typography>
        </Stack>
        <div className="bg-white rounded-[2rem] px-14 pt-14 pb-20 mt-6">
          <div className="max-w-[96.4rem]">
            <EditForm goBack={goBack} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditEvent;
