import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../../styles/LogonStyle";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { createPassword } from "../../apis/auth-apis";
import MobileLogo from "../../components/MobileLogo";
import Spinner from "../../components/Spinner";

interface IFormInputs {
  password: string;
  confirm_password: string;
}

const schema = yup.object().shape({
  password: yup.string().min(6).required(" required"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords do not match")
    .required("required"),
});

const Form = () => {
  const navigate = useNavigate();
  const [isNewPassword, setIsNewPassword] = useState(true);
  const [isConfirmPassword, setIsConfirmPassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const { otp } = useParams();
  const value = otp && otp;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
      confirm_password: "",
    },
  });

  const onSubmit: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    createPassword(
      value,
      data.password,
      data.confirm_password,
      setLoading,
      navigate
    );
  };
  return (
    <Box component="div" sx={classes.LogBlk}>
      <Box component="div" sx={classes.fromWrapper}>
        <MobileLogo />

        <Typography variant="h2" sx={classes.formTitle}>
          Create new password
        </Typography>
        <Typography
          component="p"
          sx={classes.subTitleConfirmPassword}
          className="text-2.4rem"
        >
          Please create password to complete this process
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box component="div" sx={classes.form_blk}>
            <Typography variant="h6" sx={classes.formlabelConfirmPassword}>
              New Password
            </Typography>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={classes.logonTextField}
                  variant="outlined"
                  type={isNewPassword ? "password" : "text"}
                  fullWidth
                  placeholder="Create new password"
                  error={!!errors.password}
                  helperText={errors.password ? errors.password?.message : null}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          size="large"
                          onClick={() => setIsNewPassword(!isNewPassword)}
                        >
                          {isNewPassword ? (
                            <VisibilityIcon
                              sx={{
                                fontSize: "2.4rem",
                                color: "#312948",
                              }}
                            />
                          ) : (
                            <VisibilityOffIcon
                              sx={{
                                fontSize: "2.4rem",
                                color: "#312948",
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
          <Box component="div">
            <Typography variant="h6" sx={classes.formlabelConfirmPassword}>
              Confirm new password
            </Typography>

            <Controller
              name="confirm_password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={classes.logonTextField}
                  variant="outlined"
                  type={isConfirmPassword ? "password" : "text"}
                  fullWidth
                  placeholder="Confirm new password"
                  error={!!errors.confirm_password}
                  helperText={
                    errors.confirm_password
                      ? errors.confirm_password?.message
                      : null
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          size="large"
                          onClick={() =>
                            setIsConfirmPassword(!isConfirmPassword)
                          }
                        >
                          {isConfirmPassword ? (
                            <VisibilityIcon
                              sx={{
                                fontSize: "2.4rem",
                                color: "#312948",
                              }}
                            />
                          ) : (
                            <VisibilityOffIcon
                              sx={{
                                fontSize: "2.4rem",
                                color: "#312948",
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
          <Button
            disabled={loading}
            variant="contained"
            type="submit"
            // onClick={() => {
            //   setDone(true);
            // }}
            sx={loading ? classes.disabled : classes.logonBtn}
          >
            {loading ? <Spinner /> : "Done"}
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default Form;
