import * as React from "react";
import Box from "@mui/material/Box";
import ClientResourcesTabs from "./Client Resources/Index";
import WinrateResourcesTabs from "./Winrate Resources";
import {
  AntTab,
  AntTabs,
  classes,
} from "../../styles/ResourcesLibraryStyle";
import { selectResourceIndex } from "../../store/selectors";
import { useSelector, useDispatch } from "react-redux";
import { setResourceIndex } from "../../store/slices/admin.slice";

interface ResourcesTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface ResourcesTabStyledTabProps {
  label: string;
}
function ResourcesTabsTabPanel(props: ResourcesTabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

const ResourcesTabs = () => {
  const dispatch = useDispatch();
  const value = useSelector(selectResourceIndex)

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    dispatch(setResourceIndex(newValue))
  };

  return (
    <Box>
      <Box sx={classes.antTabBox}>
        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <AntTab label="Client Resources" />
          <AntTab label="WinRate Resources" />
        </AntTabs>
      </Box>
      <ResourcesTabsTabPanel value={value} index={0}>
        <ClientResourcesTabs />
      </ResourcesTabsTabPanel>
      <ResourcesTabsTabPanel value={value} index={1}>
        <WinrateResourcesTabs />
      </ResourcesTabsTabPanel>
    </Box>
  );
};
export default ResourcesTabs;
