const TabContent = ({ content }: any) => {
    return (
      <>
        {content?.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className="py-[2.1rem] px-[3rem] border-b-2 border-[#C1C1C1] rounded-[0.4rem] w-full"
            >
              <>
                <h5 className="text-primary_blue capitalize font-semibold text-4xl mb-2 ">
                  {item?.checkInFormQuestions?.question}
                </h5>
                <h2 className="text-[#333333] text-4xl">
                  {item?.answer}
                </h2>
              </>
            </div>
          );
        })}
      </>
    );
  };
  
  export default TabContent;
  