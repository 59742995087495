import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";
import theme from "../../../../theme";
import numberWithCommas from "../../../../utilities/commafunction";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        textAlign: "center",
        backgroundColor: "#2165A2",
        padding: "1rem 4rem",
        color: "white",
        fontSize: "18px",
        fontWeight: "600px",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon className="!text-5xl text-white" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ViewApparelModal({ openView, setOpenView, item }: any) {
  const handleClose = () => {
    setOpenView(false);
  };

  return (
    <Box>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openView}
        sx={{
          ".MuiPaper-root": {
            minWidth: "94rem",
            [theme.breakpoints.down("md")]: {
              minWidth: "unset",
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Apparel Details
        </BootstrapDialogTitle>
        <DialogContent className="!px-12 !pt-14 !pb-16 modal-inner-hide">
          <div className="bg-[#D9D9D9] rounded-2xl overflow-hidden">
            <img
              className="w-full h-[50rem] object-contain"
              src={item?.image}
              alt="image"
            />
          </div>
          <div className="relative flex justify-between items-center">
            <Typography variant="h2" color="primary" className="!mt-5">
              {item?.title}
            </Typography>
            <p className="w-[10rem] text-right text-[2.2rem] text-[#2165A2] leading-[2.7rem] text-[600] pt-5 pb-5">
              ${numberWithCommas(item?.price)}
            </p>
          </div>
          <p className="text-[2.2rem] pt-5 pb-5">{item?.description}</p>
          <div className="bg-white rounded-[1rem] overflow-hidden p-8 !pr-4 mt-6">
            <div className="overflow-y-auto mt-8 list-inner-hide pr-4"></div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
}
