import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { inviteUser } from "../../../apis/auth-apis";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { calendarStyle, dateInputStyle } from "../../../styles/InviteClientCalederStyle";
import dayjs from "dayjs";
import { Autocomplete, FormHelperText } from "@mui/material";
import { getRequest } from "../../../config/axiosConfig";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "95rem",
  bgcolor: "background.paper",
  boxShadow: 24,
  px: "7.4rem",
  py: "3.4rem",
  borderRadius: "1rem",
  outline: "none",
};

interface InviteUserModalProps {
  open: boolean;
  setOpen: (state: boolean) => void;
}

interface IFormInputs {
  email: string;
  coach: string;
  contractEndDate?: string;
}

const schema = yup.object().shape({
  email: yup.string().email().required("Email is Required"),
  coach: yup.string().required(),
  contractEndDate: yup.string().required("Date is required").nullable(),
});


const InviteUserModal = ({ open, setOpen }: InviteUserModalProps) => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const [data, setData] = useState<any>({});

  const {
    handleSubmit,
    control,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    reset({
      email: "",
      contractEndDate: "",
    })
    setSelected("")
    setOpen(false);
  };

  const handleSelectChange = (value: any) => {
    if (value !== null || undefined) {
      setSelected(value.id)
      const id = value.id
      setValue("coach", id)
    } else {
      setValue("coach", "")
    }
    setError("coach", { type: "focus" }, { shouldFocus: true });
  };

  const onSubmit: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    if (data.coach === null) {
      setError("coach", { type: 'custom', message: 'custom message' });
      return
    }

    const isValid = dayjs(data.contractEndDate).isValid();
    if(!isValid){
      setError("contractEndDate", { type: 'custom', message: 'Invalid Date' })
      return;
    }
    const coach = data.coach;
    const payload = {
      email: data.email,
      type: "client",
      coachId: coach,
      contractEndDate: dayjs(data.contractEndDate).format("MM/DD/YYYY")
    }

    inviteUser(payload, setLoading).then(() => {
      reset({
        email: "",
        contractEndDate:""
      })
      setSelected("")
      setOpen(false);
    });
  };

  useEffect(() => {
    if(open){
      getRequest(`admin/all-coaches`).then((res) => {
        setData(res);
      }).catch((error: any) => {
        console.log("Error--->", error);
      })
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={handleClose}
        >
          <Typography
            sx={{ fontSize: "2.8rem", fontWeight: 600, color: "#333333" }}
          >
            Invite Client
          </Typography>
          <IconButton>
            <CloseIcon sx={{ fontSize: "2.6rem" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            minHeight: "53rem",
            width: "100%",
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                position: "absolute",
                top: "40%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Typography
                sx={{
                  fontSize: "2.8rem",
                  fontWeight: 400,
                  color: "#333333",
                  mb: "2.4rem",
                  mt: "5rem"
                }}
              >
                Enter email to send link
              </Typography>
              <Controller
                control={control}
                defaultValue=""
                name="email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputProps={{
                      endAdornment: (
                        <Button 
                          type="submit" 
                          disabled={loading || !selected} 
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.stopPropagation();
                              e.preventDefault();
                              return;
                            }
                          }}
                        >
                          {loading == true ? "Loading..." : "Send Link"}
                        </Button>
                      ),
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        width: "58rem",
                        height: "6.5rem",
                        borderRadius: "1rem",
                        overflow: "hidden",
                        pr: 0,
                        borderColor: "#2165A2",
                        "& .MuiInputBase-input": {
                          fontSize: "2rem",
                          fontWeight: 400,
                          px: "2.8rem",
                          borderColor: "#2165A2",
                        },
                      },
                      "& .MuiButtonBase-root": {
                        backgroundColor: "#2165A2",
                        color: "#fff",
                        height: "100%",
                        width: "26rem",
                        borderRadius: 0,
                        fontSize: "2.2rem",
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: "#2165A2",
                        },
                        "&:disabled": {
                          color: "#fff"
                        }
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#2165A2",
                      },
                      "& .MuiFormHelperText-root": {
                        fontSize: "1.4rem",
                      },
                    }}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email?.message : null}
                  />
                )}
              />

              <Box sx={{ mt: 4 }}>
                <Typography
                  sx={{
                    fontSize: "2.6rem",
                    fontWeight: 400,
                    color: "#333333",
                    mb: "1.6rem",
                  }}
                  >
                  Assign Coach
                </Typography>
                <Controller
                  control={control}
                  name="coach"

                  render={({ field: { onChange, value } }) => {
                    return (
                      <Autocomplete
                        freeSolo
                        options={data?.data?.users || []}
                        placeholder="select coach"
                        onChange={(_, values) => handleSelectChange(values)}
                        value={value}
                        getOptionLabel={(option: any) => {
                          return option.full_name
                        }}
  
                        renderInput={(params: any) => (
                          <TextField
                            sx={dateInputStyle}
                            {...params}
                            variant="outlined"
                            onChange={onChange}                      
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.stopPropagation();
                                e.preventDefault();
                                return;
                              }
                            }}
                          />
                        )}
                      />
                    )
                  }}
                />
                {errors?.coach && (
                  <FormHelperText
                    sx={{
                      fontSize: "1.4rem",
                      color: "#d32f2f",
                      ml: "1.4rem",
                    }}
                  >
                    {errors?.coach?.message ? "You can't invite/send link to a client without assigning a coach" : null}
                  </FormHelperText>
                )}
              </Box>

              <Box sx={{ mt: 4 }}>
                <Typography
                  sx={{
                    fontSize: "2.6rem",
                    fontWeight: 400,
                    color: "#333333",
                    mb: "2.6rem",
                  }}
                >
                  Contract end date
                </Typography>
                <Box sx={{ width: "18.7rem", borderColor: "#2165A2" }} >
                  <Controller
                    control={control}
                    name="contractEndDate"
                    defaultValue=""
                    render={({ field: { onChange, ...restField } }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label=""
                          // value={dateValue}
                          disablePast
                          onChange={(event: any) => {
                            onChange(event);
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              sx={dateInputStyle}
                              {...params}
                              error={false}
                            />
                          )
                          }
                          PaperProps={{
                            sx: calendarStyle
                          }}
                          {...restField}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  {errors?.contractEndDate && (
                    <p style={{ fontSize: "10px", color: "red" }}>
                      {errors?.contractEndDate?.message}
                    </p>
                  )}
                </Box>

              </Box>

            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default InviteUserModal;