import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";
import ProfilePhoto from "../../../assets/images/profile.png";
import classes from "../../../styles/CheckinFormStyle";
import theme from "../../../theme";
import Form from "./Form";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle
			sx={{
				m: 0,
				textAlign: "center",
				backgroundColor: "#2165A2",
				padding: "1rem 4rem",
				color: "white",
				fontSize: "18px",
				fontWeight: "600px",
			}}
			{...other}
		>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 6,
						color: "#fff",
						"& .MuiSvgIcon-root": {
							width: "1.8rem",
							height: "1.8rem"
						}
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
}

export default function CustomizedDialogs({ setAnchorEl, user, type }: any) {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		// setAnchorEl(null);
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Typography sx={classes.menuItems} onClick={handleClickOpen}>
				View
			</Typography>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
				sx={{
					".MuiPaper-root": {
						minWidth: "94rem",
						[theme.breakpoints.down("sm")]: {
							minWidth: "unset",
						},
					},
				}}
			>
				<BootstrapDialogTitle
					id="customized-dialog-title"
					onClose={handleClose}
				>
					User Details
				</BootstrapDialogTitle>
				<DialogContent dividers>
					
					<Typography
						gutterBottom
						sx={{
							width: "18rem",
							height: "18rem",
							textAlign: "center",
							margin: "0 auto",
							mb: "2rem",
						}}
					>
						<Box
							component="img"
							sx={{ width: "18rem", height: "18rem", objectFit: "cover", borderRadius: "50%" }}
							src={user?.profile_photo ? user?.profile_photo : ProfilePhoto}>
						</Box>
					</Typography>
					<Box>
						<Form user={user} type={type} />
					</Box>
				</DialogContent>
				
			</BootstrapDialog>
		</Box>
	);
}
