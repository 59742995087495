import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import { Typography } from "@mui/material";

export const StyledTextFiled = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #fff",
    borderRadius: "0.76rem",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    boxShadow: "0px 1px 4px rgb(0 0 0 / 10%)",
    "&:focus": {
      boxShadow: "none",
      border: "1px solid #2165A2",
    },
    "&::placeholder": {
      color: "#BFBFBF",
      opacity: 1,
    },
  },
}));

const InputField = ({ name, register, title, placeholder, fullWidth }: any) => {
  
  return (
    <FormControl variant="standard" sx={{ maxWidth: fullWidth ? "100%" : "32rem", width: "100%" }}>
      <Typography
        sx={{
          fontSize: "2.40752rem",
          fontWeight: 600,
          color: "#333333",
          mb: "1rem",
        }}
      >
        {title}
      </Typography>
      <StyledTextFiled
        fullWidth={fullWidth}
        name={name}
        {...register(name)}
        placeholder={placeholder}
      />
    </FormControl>
  );
};

export default InputField;
