import { yupResolver } from "@hookform/resolvers/yup";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Box, FormHelperText, IconButton, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import Layout from "../../../components/Layout/Layout";
import {
  useCreateNoteMutation,
  useGetAllClientsQuery,
} from "../../../services/admin";
import classes from "../../../styles/CreateFormStyle";
import classes1 from "../../../styles/QuestionFormStyles";
import { calendarStyle, dateInputStyle } from "../../../styles/calenderStyles";

interface IClientInputs {
  id: string;
  full_name: string;
}

interface IFormInputs {
  note: string;
  client: IClientInputs;
  date: string;
}
const schema = yup.object().shape({
  note: yup.string().required(" Required"),
  client: yup.object().shape({
    id: yup.string().required(),
  }),
  date: yup.string().required(" Required"),
});
const CreateNote = () => {
  const [setDateValue] = React.useState<any>(null);
  const [date] = useState("");
  const { data } = useGetAllClientsQuery(1, {
    refetchOnMountOrArgChange: true,
  });
  const [createNote, { isError, isSuccess }] = useCreateNoteMutation();

  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      note: "",
      date: "",
    },
  });

  const onSubmit: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    const receiverId = data?.client?.id;
    const note = data?.note;
    const date = data?.date;
    const payload = { note, receiverId, date };
    createNote(payload);
  };

  const goBack = () => {
    navigate("/client-notes");
  };
  if (isError) {
    toast.error("Something thing went wrong");
  }

  if (isSuccess) {
    toast.success("Note Created");
    goBack();
  }
  return (
    <Layout>
      <Stack direction="row" gap="2rem">
        <IconButton sx={classes.backArrow} disableRipple onClick={goBack}>
          <ArrowRightAltIcon />
        </IconButton>
        <Typography
          variant="h2"
          sx={{
            fontSize: "3.5rem",
            lineHeight: "5.2rem",
            fontWeight: "600",
            color: "#333333",
          }}
        >
          New Note
        </Typography>
      </Stack>
      <Typography
        sx={{
          mt: "4.3rem",
          fontSize: "2.4rem",
          lineHeight: "3.7rem",
          fontWeight: "600",
          color: "#333333",
        }}
      >
        Description
      </Typography>
      <form>
        <Box sx={{ marginTop: "6.6rem" }}>
          <Controller
            name="note"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                rows={7}
                maxRows={7}
                sx={classes1.questionTextField}
                id="outlined-basic"
                variant="outlined"
                type="text"
                fullWidth
                placeholder="Write a Note?"
                error={!!errors.note}
                helperText={errors.note ? errors.note?.message : null}
              />
            )}
          />
        </Box>

        <Box sx={{ marginTop: "6rem", display: "flex", gap: "2rem" }}>
          <Box sx={{ width: "100%" }}>
            <Box>
              <Typography
                sx={{
                  fontSize: "1.8rem",
                  fontWeight: 600,
                  color: "#333333",
                  mb: "1.6rem",
                }}
              >
                User
                <span style={{ color: "#FF5B5B" }}>*</span>
              </Typography>
            </Box>

            <Box>
              <Select
                {...register("client")}
                defaultValue=""
                sx={{
                  width: "100%",
                  fontSize: "2rem",
                  fontWeight: 400,
                  borderColor: "black",
                  borderRadius: "1rem",
                  background: "#FFFFFF",
                  // py: "1.8rem",
                  border: "#D0D6DE",
                  px: "1rem",
                  "& .MuiSelect-icon": {
                    width: "3rem",
                    height: "3rem",
                    top: "calc(50% - 1.5rem)",
                  },
                  "& .MuiMenuItem-root": {
                    fontSize: "1.8rem",
                  },
                  "& .MuiSelect-select": {
                    py: "1.8rem",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenuItem-root": {
                        fontSize: "1.8rem",
                      },
                    },
                  },
                }}
                renderValue={(selected: any) => {
                  if (selected?.length === 0) {
                    return <span>Select Client</span>;
                  }
                  return <span>{selected?.full_name}</span>;
                }}
              >
                {data?.data?.clients?.map((client: any, index: any) => (
                  <MenuItem key={index} value={client}>
                    {client?.full_name}
                  </MenuItem>
                ))}
              </Select>
              {errors?.client && (
                <FormHelperText
                  sx={{
                    fontSize: "1.4rem",
                    color: "#d32f2f",
                    ml: "1.4rem",
                  }}
                >
                  {errors?.client?.message ? "Client is required" : null}
                </FormHelperText>
              )}
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography
              sx={{
                fontSize: "1.8rem",
                fontWeight: 600,
                color: "#333333",
                mb: "1.6rem",
              }}
            >
              Date
            </Typography>

            <Box sx={{ mt: "1.2rem" }}>
              <Controller
                control={control}
                name="date"
                render={({ field: { onChange, ...restField } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label=""
                      disablePast
                      onChange={(event: any) => {
                        onChange(event);
                        setDateValue(event);
                      }}
                      renderInput={(params) => (
                        <TextField
                          value={date}
                          variant="outlined"
                          sx={dateInputStyle}
                          {...params}
                        />
                      )}
                      PaperProps={{
                        sx: calendarStyle,
                      }}
                      {...restField}
                    />
                  </LocalizationProvider>
                )}
              />
              {errors?.date && (
                <p style={{ fontSize: "10px", color: "red" }}>
                  {errors?.date?.message}
                </p>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "100%", mt: "3.9rem", textAlign: "center" }}>
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            sx={classes1.noteButton}
          >
            save
          </Button>
        </Box>
      </form>
    </Layout>
  );
};

export default CreateNote;
