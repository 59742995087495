import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { IconButton, Stack, Typography } from "@mui/material";
import classes from "../../../styles/CreateFormStyle";
import GHLForm from "./GHLForm";

const CreateEvent = ({ toggleComponent }: any) => {
	const goBack = () => {
		toggleComponent();
	};
	
	return (
		<div>
			<Stack direction="row" gap="2rem">
				<IconButton
					sx={classes.backArrow}
					disableRipple
					onClick={goBack}
				>
					<ArrowRightAltIcon />
				</IconButton>
				<Typography variant="h2">Create New Event</Typography>
			</Stack>
			<div className="bg-white rounded-[2rem] px-14 pt-14 pb-20 mt-6">
				<div className="max-w-[96.4rem]">
					<GHLForm goBack={goBack}/>
				</div>
			</div>
		</div>
	);
};

export default CreateEvent;
