import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Layout/Layout";
import NotifyBlk from "./components/NotifyBlk";
import { socket } from "../../config/socket";
import {
  useGetNotificationsQuery,
  useMarkAllNotificationsMutation,
  useMarkNotificationsMutation,
} from "../../services/general";
import { setNotiCount } from "../../store/slices/auth.slice";
import classes from "../../styles/CheckinFormStyle";

const Notifications = () => {
  const [socketArray, setSocketArray] = useState<any>([]);
  const [notifications, setNotifications] = useState<any>([]);
  const [trigger, setTrigger] = useState<boolean>(false);
  const userId = useSelector((state: any) => state?.auth?.user?.id);
  const user_img = useSelector(
    (state: any) => state?.auth?.user?.profile_photo
  );
  const dispatch = useDispatch();
  //get Notifications Api
  const { data: getNotifications, isLoading } = useGetNotificationsQuery(
    userId,
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );

  //mark Notifications Api
  const [markNotifications, { data: markedNotifications }] =
    useMarkNotificationsMutation();
  //mark All Notifications Api
  const [markAllNotifications] = useMarkAllNotificationsMutation();

  const handleMarkAllNotification = () => {
    const payload = {
      userId: userId
    }
    markAllNotifications(payload);
    setTrigger(true);
  }
  useEffect(() => {
    const array = getNotifications?.data?.Notifications || [];
    setNotifications(array);
  }, [markedNotifications?.data?.Notifications, isLoading, getNotifications?.data?.Notifications]);

  useEffect(() => {
    const handleNotification = (response: any) => {
      setSocketArray((prevSocketArray: any) => [...prevSocketArray, response]);
      dispatch(setNotiCount(response?.count));
    };
    socket.on(
      `clientOnboardingAnswerNotification${userId}`,
      handleNotification
    );
    socket.on(`newUserRegisteredNotification${userId}`, handleNotification);
    socket.on(`videoUploadedNotification${userId}`, handleNotification);
    socket.on(`userCalendarDisconnectedNotification${userId}`, handleNotification);

    // Clean up the event listener when the component unmounts
    return () => {
      socket.off(
        `clientOnboardingAnswerNotification${userId}`,
        handleNotification
      );
      socket.off(`newUserRegisteredNotification${userId}`, handleNotification);
      socket.off(`videoUploadedNotification${userId}`, handleNotification);
      socket.off(`userCalendarDisconnectedNotification${userId}`, handleNotification);
    };
  }, []);

  return (
    <Layout>
      <Typography variant="h2" component="h2">
        Notifications
      </Typography>
      <div className="bg-white p-16 rounded-[2rem] mt-7 notify_cont">
        {notifications?.length !== 0 && <div className="flex justify-end">
          <Button disabled={notifications?.length > 0 && (!(notifications?.some((notification: any) => !notification?.mark_as_read)))} onClick={handleMarkAllNotification} variant="contained" sx={classes.createFormBtn}>
            Mark all as read
          </Button>
        </div>}
        {socketArray?.length > 0 && (
          <h1 className="text-[2.4rem] text-[#000] mb-[2rem]">
            New Notifications
          </h1>
        )}
        <div className="mb-[5rem]">
          {socketArray?.map((item: any, index: any) => {
            return (
              <div key={index} className="mb-[1.827rem]">
                <NotifyBlk
                  item={item}
                  user_img={user_img}
                  socketArray={socketArray}
                  trigger={trigger}
                  setTrigger={setTrigger}
                />
              </div>
            );
          })}
        </div>
        {isLoading && (
          <h1 className="text-[3rem] font-medium text-center text-[#000]">
            Loading...
          </h1>
        )}
        {!isLoading && notifications?.length === 0 ? (
          <h1 className="text-[3rem] font-medium text-center text-[#000]">
            No Notification
          </h1>
        ) : (
          notifications?.map((item: any, index: any) => {
            return (
              <div key={index} className="mb-[1.827rem]">
                <NotifyBlk
                  item={item}
                  user_img={user_img}
                  markNotifications={markNotifications}
                  trigger={trigger}
                  setTrigger={setTrigger}
                />
              </div>
            );
          })
        )}
      </div>
    </Layout>
  );
};

export default Notifications;
