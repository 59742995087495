import AddIcon from "@mui/icons-material/Add";
import TocIcon from "@mui/icons-material/Toc";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import classes from "../../styles/CreateFormStyle";
// import BasicSelect from "../../components/Select";
import DeleteIcon from '@mui/icons-material/Delete';
import Question1 from "./components/Question1";

interface questionI {
  id: string;
  question: string;
  label: string;
  type: string;
  length: string;
}

const Form = ({
  setQuestionsData,
  questionsData,
  questions,
  setQuestions,
  AddHandler,
  activeData,
  setActiveData,
}: any) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [type] = useState("textfield");
  const [length] = useState("");

  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";

  function handleDeleteQuestion(id: string) {
    if (questions.length !== 1) {
      const filteredQuestions = questions.filter(
        (question: questionI) => question.id !== id
      );
      setQuestions(filteredQuestions);
      setQuestionsData(
        questionsData.map((item: any) => {
          return item.id === id
            ? {
                ...item,
                delete: true,
              }
            : item;
        })
      );
    }
    handleMenuClose();
  }
  function handleActive(id: string) {
    const filteredQuestion = questions.find(
      (question: questionI) => question.id == id
    );

    setActiveData(filteredQuestion);
    // setActive(id);
  }

  return (
    <Grid
      container
      spacing="2rem"
      marginTop="3rem"
      height="calc(100vh - 25.9rem)"
      minHeight="60rem"
    >
      <Grid item xs={2.6}>
        <Box sx={classes.item}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Content</Typography>
            <IconButton onClick={AddHandler} sx={classes.plus} disableRipple>
              <AddIcon />
            </IconButton>
          </Stack>
          <List sx={classes.list}>
            {questions &&
              questions.map((question: questionI, index: number) => (
                <ListItem
                  key={question.id}
                  onClick={() => handleActive(question.id)}
                  disablePadding
                  sx={{
                    backgroundColor:
                      activeData.id == question.id ? "#c3c3c3" : "white",
                  }}
                >
                  <ListItemButton disableTouchRipple>
                    <ListItemText>
                      <>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Stack
                            direction="row"
                            gap="1.4rem"
                            alignItems="center"
                          >
                            <Button
                              disableElevation
                              disableRipple
                              startIcon={<TocIcon />}
                              variant="contained"
                              sx={classes.listIconBtn}
                            >
                              {index + 1}
                            </Button>
                            <Typography variant="body1" sx={classes.questions}>
                              Question {index + 1}
                            </Typography>
                          </Stack>
                          <DeleteIcon onClick={() => handleDeleteQuestion(question?.id)} sx={{ width: "24px", height: "24px" }} />
                        </Stack>
                        <Menu
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          id={menuId}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={isMenuOpen}
                          onClose={handleMenuClose}
                          sx={classes.menu}
                        >
                          <MenuItem onClick={handleMenuClose}>
                            Duplicate
                          </MenuItem>
                          <MenuItem onClick={handleMenuClose}>Edit</MenuItem>
                          <MenuItem
                            onClick={() => handleDeleteQuestion(question?.id)}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Box>
      </Grid>
      <Grid item xs={6.8}>
        <Box sx={classes.item}>
          {questions &&
            questions.map((question: questionI, index: number) => (
              <div key={question.id}>
                <Question1
                  setQuestionsData={setQuestionsData}
                  questionsData={questionsData}
                  length={length}
                  type={type}
                  questionItem={question}
                  activeData={activeData}
                  index={index}
                />
              </div>
            ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Form;
