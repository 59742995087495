import { useState } from "react";
import Layout from "../../../../components/Layout/Layout";
import {
  Box,
  TextField,
  Typography,
  Button,
  InputAdornment,
} from "@mui/material";
import { useTheme } from "@mui/material";
import InputField from "../../../UploadResource/Components/InputField";
import UploadBox from "../../../UploadResource/Components/UploadBox";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import apiEndpoint from "../../../../config/environment";
import { toast } from "react-toastify";
import { Stack } from "@mui/material";
import {
  isFetchBaseQueryError,
  useCreateApparelMutation,
} from "../../../../services/admin/index";
import { useNavigate } from "react-router-dom";

interface IFormInputs {
  title: string;
  image: string;
  description: string;
  price: string;
  link: string;
}

const schema = yup.object().shape({
  title: yup.string().required("Required"),
  image: yup.string().required("Required"),
  description: yup.string().required("Required"),
  price: yup.string().required("Required"),
  link: yup.string().required("Required"),
});

const UploadApparel = () => {
  const theme = useTheme();
  const [priceVal, setPriceVal] = useState("");
  const [image, setImage] = useState({
    name: "",
    file: {},
    isUploading: false,
    url: "",
  });

  const [createApparel, { isSuccess }] = useCreateApparelMutation();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    control,
    setValue,
    setError,
    trigger,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      image: "",
      title: "",
      description: "",
      price: "",
    },
  });
  const handlePrice = (e: any) => {
    const { name } = e.target;
    const enteredValue = e.target.value.replace(/\D/g, "");
    setPriceVal(enteredValue);
    setValue(name, enteredValue);
  };

  
  function isValidUrl(string: any): any {
    const pattern = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/)
    return pattern?.test(string);
  }

  const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
    if (data?.link) {
      if (!isValidUrl(data?.link)) {
        setError("link", {
          type: "custom",
          message: "Please enter a valid URL.",
        });
        return;
      }
    }
    const payload = {
      title: data.title,
      image: data.image,
      description: data.description,
      price: data.price,
      link: data.link,
    };
    try {
      await createApparel(payload).unwrap();
      navigate("/resources-library");
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error.data?.message);
      }
    }
  };

  if (isSuccess) {
    toast.success(" Apparel Created  Successfully");
  }

  const handleUploadImage = async (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target?.files[0];
    setImage({
      ...image,
      name: file.name,
      file: file,
      isUploading: true,
    });

    const formData = new FormData();
    formData.append("image", file);

    const promise = axios
      .request({
        method: "POST",
        url: `${apiEndpoint}/upload-one`,
        data: formData,
      })
      .then((res) => {
        setImage({
          ...image,
          isUploading: false,
          url: res.data,
        });
        setValue("image", res.data);
        trigger("image");
      })
      .catch(() => {
        setImage({
          ...image,
          isUploading: false,
        });
      });

    await toast.promise(promise, {
      pending: "Uploading Thumbnail",
      success: "Thumbnail Uploaded",
      error: "Error Uploading Thumbnail",
    });
  };

  return (
    <Layout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ px: "2.2rem", py: "1.8rem" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "3.8rem",
              mb: "4rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "3.5rem",
                color: theme.palette.secondary.main,
                fontWeight: 600,
              }}
            >
              Upload Apparel
            </Typography>
          </Box>

          <Box sx={{ mb: "2.7rem" }}>
            <InputField
              name="title"
              register={register}
              title="Title"
              placeholder="Enter Title"
            />
            {errors?.title?.message ? (
              <p className="text-red-500"> {errors?.title?.message} </p>
            ) : null}
          </Box>
          <Box sx={{ mb: "2.7rem" }}>
            <InputField
              name="link"
              register={register}
              title="Link"
              placeholder="Enter Link"
            />
            {errors?.link?.message ? (
              <p className="text-red-500 "> {errors?.link?.message} </p>
            ) : null}
          </Box>
          <Box sx={{ maxWidth: "75rem", mb: "4rem" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            ></Box>
          </Box>

          <Box sx={{ display: "flex", gap: "6rem" }}>
            <Box>
              <UploadBox
                name="image"
                accept="image/*"
                uploadTitle="Upload Thumbnail"
                type="photo"
                fileName="Image12.png"
                progress={100}
                status="completed"
                isUploading={image.isUploading}
                showImages
                handleFileChange={handleUploadImage}
                register={register}
              />
              {errors?.image?.message ? (
                <p className="text-red-500"> {errors.image.message} </p>
              ) : null}
            </Box>
            {image?.url && (
              <Stack direction="row" maxWidth={"32rem"}>
                <div className="w-[28rem] h-[23rem] rounded-3xl overflow-hidden relative">
                  <img
                    className="w-full h-full "
                    src={image?.url}
                    alt="Apparelimage"
                  />
                </div>
              </Stack>
            )}
          </Box>
          <Box sx={{ mt: "2.7rem", maxWidth: "32rem" }}>
            <Typography
              sx={{
                fontSize: "2.4rem",
                fontWeight: 400,
                color: "#333333",
                mt: "2rem",
              }}
            >
              Price
            </Typography>
            <Controller
              name="price"
              control={control}
              render={({ field: { name } }) => (
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {
                      position: "relative",
                      backgroundColor:
                        theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
                      border: "1px solid #fff",
                      borderRadius: "0.76rem",
                      fontSize: 16,
                      maxWidth: "32rem",
                      padding: "10px 12px",
                      boxShadow: "0px 1px 4px rgb(0 0 0 / 10%)",

                      "&:focus": {
                        boxShadow: "none",
                        border: "none",
                      },
                      "&::placeholder": {
                        color: "#BFBFBF",
                        opacity: 1,
                      },
                    },
                    "& .MuiFormHelperText-root.Mui-error": {
                      color: "#EF4444",
                      fontSize: "1.3rem",
                      margin: "0",
                    },
                  }}
                  name={name}
                  value={priceVal}
                  onChange={handlePrice}
                  type="text"
                  placeholder="00"
                  inputProps={{ maxLength: 10 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">$</InputAdornment>
                    ),
                  }}
                  error={!!errors.price}
                  helperText={errors.price ? errors?.price?.message : null}
                />
              )}
            />
          </Box>

          <Box sx={{ maxWidth: "40rem", mb: "2rem", mt: "10rem" }}>
            <Typography
              sx={{ fontSize: "2.4rem", fontWeight: 400, color: "#333333" }}
            >
              Details
            </Typography>
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  minRows={5}
                  variant="outlined"
                  sx={{
                    width: "100%",
                    "& > .MuiOutlinedInput-root": {
                      borderRadius: "1rem",
                      fontSize: "2rem",
                      backgroundColor: "#fcfcfb",
                      border: "none",
                      boxShadow: "0px 1px 4px rgb(0 0 0 / 10%)",
                    },
                    "& .MuiFormHelperText-root": {
                      color: "red",
                      fontSize: "1.4rem",
                      ml: 0,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#fff",
                    },
                  }}
                  helperText={
                    errors.description ? errors.description?.message : null
                  }
                />
              )}
            />
          </Box>

          <Box sx={{ mb: "7rem" }}>
            <Button
              type="submit"
              disabled={image.isUploading}
              sx={{
                background: "#2165A2",
                borderRadius: "0.485rem",
                height: "4.5rem",
                width: "18rem",
                color: "#fff",
                fontSize: "1.8rem",
                textTransform: "capitalize",
                "&:hover": {
                  background: "#2165A2",
                },
                "&.Mui-disabled": {
                  opacity: 0.7,
                  color: "#fff",
                },
              }}
            >
              Upload Apparel
            </Button>
          </Box>
        </Box>
      </form>
    </Layout>
  );
};

export default UploadApparel;
