import theme from "../theme";

const classes = {
	sidebar: {
		position: "fixed",
		bottom: "0",
		left: "0",
		zIndex: "20",
		background: "#333333",
		width: "30.4rem",
		minWidth: "30.4rem",
		height: "100%",
		[theme.breakpoints.down("md")]: {
			display: "none",
		},
	},
	inside: {
		display: "flex",
		flexFlow: "column",
		overflow: "hidden",
		overflowY: "auto",
		padding: "4.4rem 1.8rem 1rem",
		height: "100%",
		"&::-webkit-scrollbar": {
			width: "0.5rem",
		},
		"&::-webkit-scrollbar-track": {
			background: "transparent",
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "rgba(0,0,0,0)",
		},
	},
	sidebarLogo: {
		width: "22rem",
		height: "5.4rem",
		margin: "0 auto",
		marginBottom: "4rem",
	},
	ico: {
		width: "2.8rem",
		minWidth: "2.8rem",
		height: "2.8rem",
	},
	listItem: {
		padding: "1rem 1rem 1rem 3.2rem",
		borderRadius: "1rem",
		marginBottom: "1rem",
		transition: "all ease 0.5s",
	},
	activeItem: {
		padding: "1rem 1rem 1rem 3.2rem",
		borderRadius: "1rem",
		marginBottom: "1rem",
		transition: "all ease 0.5s",
		background: "#2165A2",
		"& .MuiListItemIcon-root > img": {
			filter:
				"brightness(0) saturate(100%) invert(100%) sepia(22%) saturate(2%) hue-rotate(73deg) brightness(105%) contrast(100%)",
		},
		"&:hover": {
			background: "#2165A2",
		},
	},

	text: {
		"& > .MuiTypography-root": {
			fontWeight: "500",
			fontSize: "1.8rem",
			lineHeight: "2.7rem",
			color: "#FFFFFF",
			marginLeft: "1.7rem",
			textDecoration: "none",
		},
	},

	drawer: {
		minWidth: "30.4rem",
	},
};

export default classes;
