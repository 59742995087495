import {
  Stack,
  Typography,
  IconButton,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  FormControl,
  Select,
  Radio,
  Button,
  RadioGroup,
} from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../../../../components/Layout/Layout";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import classes from "../../../../styles/CreateFormStyle";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import {
  isFetchBaseQueryError,
  useCreateScheduleCallMutation,
} from "../../../../services/admin";
import { toast } from "react-toastify";
import CustomDatepicker from "../../../../components/CustomDatepicker";
import SelectWeeklyDays from "./SelectWeeklyDays";
import SelectMonthlyDays from "./SelectMonthlyDays";

const selectItemStyle = {
  fontSize: "1.6rem",
  fontWeight: 400,
  lineHeight: "24px",
  color: "#333333",
  "&.Mui-selected": {
    backgroundColor: "rgb(33 101 162 / 44%)",
  },
};

interface IFormInputs {
  date: string;
  endDate?: Date;
  recurrence: string;
  repeatEvery: string;
  occurrences?: string;
  recurrencecall?: boolean;
  by?: string;
  title: string;
  duration: number;
  occursOn?: string;
  dayOfMonth?: string;
  weekIndex?: string;
  WeekDay?: string;
  dayOfWeek?: string;
  occursOnChecked: string;
  monthMode?: any;
  monthly_day?: any;
  monthly_week?: any;
  monthly_week_day?: any;
}

const schema = yup.object().shape({
  endDate: yup.date().notRequired().nullable(),
  date: yup.string().required(" Required"),
  repeatEvery: yup.string().required("Required"),
  recurrence: yup.string().required("Required"),
  occurrences: yup.string(),
  recurrencecall: yup.boolean(),
  title: yup.string().required("Required"),
  duration: yup.number().required("Required"),
});

const options = ["Daily", "Weekly", "Monthly"];

const days = ["1", "2", "3", "4", "5"];
const durations = [15, 30, 45, 60, 90];

const ScheduleHuddleCall = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setEndDate] = React.useState<Dayjs | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [autoReccuranceEndDate] = useState<Date>(
    new Date(Date.now() + 86400000)
  );
  const [checked, setChecked] = useState("by");
  const [SelectedDay, setSelectedDay] = useState("");
  const [createScheduleCall, { isLoading }] = useCreateScheduleCallMutation();
  const [error, setError] = useState("");
  const [errorOccurrences, setErrorOccurrences] = useState("");
  const [isRecurring, setIsRecurring] = useState(false);
  // const [recurrence, setRecurrence] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);
  maxDate.setDate(maxDate.getDate() - 1);
  // New States
  const [selectedDays, setSelectedDays] = useState<any>([]);
  const [monthState, setMonthState] = useState({
    monthlyDay: 1,
    weekOption: { title: "First", value: 1 },
    weekDayOption: { title: "Sunday", value: 1 },
    mode: "WEEK_DAY",
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [startDateError] = useState("");
  const [date, setDate] = useState(
    dayjs(new Date()).add(5, 'minute').format("YYYY-MM-DDTHH:mm")
  );

  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    register,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      date: dayjs().add(5, 'minute').format("YYYY-MM-DDTHH:mm"),
      repeatEvery: "1",
      recurrence: "Daily",
      endDate: dayjs().add(7, "day").toDate(),
      duration: 60,
      occursOn: "",
      dayOfMonth: "1",
      weekIndex: "First",
      dayOfWeek: "Monday",
      WeekDay: "Monday",
      occursOnChecked: "monthDay",
    },
  });

  // Callback version of watch.  It's your responsibility to unsubscribe when done.
  React.useEffect(() => {
    const subscription = watch((value) => {
      const { occurrences, date, endDate, repeatEvery, recurrence, WeekDay } =
        value;
      if (date && endDate) {
        const startEventDate: any = new Date(date);
        const endEventDate: any = new Date(endDate);
        const numberOfDays: number =
          (endEventDate - startEventDate) / (1000 * 60 * 60 * 24);
        getMeetingOccurrences(
          date,
          endDate,
          recurrence?.toString(),
          repeatEvery,
          numberOfDays,
          checked === "by" ? true : false,
          occurrences,
          WeekDay
        );
      }
      if (recurrence === "Daily") {
        setSelectedDay("");
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, checked]);

  useEffect(() => {
    const { occurrences, date, endDate, repeatEvery, recurrence, WeekDay } =
      getValues();
    if (date && endDate) {
      const startEventDate: any = new Date(date);
      const endEventDate: any = new Date(endDate);
      const numberOfDays: number =
        (endEventDate - startEventDate) / (1000 * 60 * 60 * 24);
      getMeetingOccurrences(
        date,
        endDate,
        recurrence?.toString(),
        repeatEvery,
        numberOfDays,
        checked === "by" ? true : false,
        occurrences,
        WeekDay
      );
    }
  }, [checked, getValues]);

  //NEW EFFECTS
  useEffect(() => {
    const day = String(dayjs().get("day") + 1);
    setSelectedDays([...selectedDays, day]);
  }, []);

  const onSubmit = async (data: IFormInputs) => {
    const end = dayjs(data?.endDate);
    const start = dayjs(data?.date);
    const dateCheck = end?.isBefore(start, "date");
    if (!dayjs(end).isValid()) {
      toast.warning("Invalid End Date");
      return;
    }

    if (isRecurring) {
      if (
        (data?.endDate?.toString() === "" && checked === "by") ||
        (data?.endDate === undefined && checked === "by")
      ) {
        setError("required");
        setErrorOccurrences("");
        return;
      }
      if (
        (data?.occurrences === "" && checked === "after") ||
        (data?.occurrences === undefined && checked === "after")
      ) {
        setErrorOccurrences("required");
        setError("");
        return;
      }
      if (dateCheck && checked === "by") {
        toast.error("End date should be greater than start date");
        return;
      }

      // Weekly Validation
      if(data.recurrence === "Weekly"){
        const currentDay = dayjs().day() + 1;
        const isCurrentDay = selectedDays?.includes(String(currentDay));
        if(isCurrentDay){
          const isBefore = dayjs(data?.date).isBefore(new Date());
          if (isBefore) {
            // setStartDateError(
            //   "Select time later from current time"
            // );
            toast.error("Please schedule call in future day & time");
            return;
          }
        }
      }
    }

    let payload: any;
    setIsSubmitting(true);
    if (isRecurring) {
      const weekDays = selectedDays?.join(",");
      payload = {
        title: data.title,
        date: SelectedDay ? SelectedDay : dayjs(data.date).format(),
        end_date: checked === "by" ? dayjs(data.endDate) : dayjs(data.date),
        duration: data.duration,
        endDate:
          checked === "by"
            ? dayjs(data.endDate).format("YYYY-MM-DD")
            : dayjs(data.date).format("YYYY-MM-DD"),
        recurrence: data.recurrence.toLowerCase(),
        repeatEvery: data.repeatEvery,
        occurrences: checked === "after" ? data.occurrences : null,
        callType: "huddle",
        isEndDate: checked === "after" ? false : true,
        isRecurring: isRecurring,
        weekly_days: weekDays,
      };
      if (data?.recurrence === "Weekly") {
        payload.weekly_days = weekDays;
      }
      if (data?.recurrence === "Monthly") {
        payload.monthMode = monthState.mode;
        if (monthState.mode === "WEEK_DAY") {
          payload.monthly_day = monthState.monthlyDay;
        }
        if (monthState.mode === "MONTH_DAY") {
          payload.monthly_week = monthState.weekOption.value;
          payload.monthly_week_day = monthState.weekDayOption.value;
        }
      }
    } else {
      payload = {
        title: data.title,
        date: dayjs(data.date).format(),
        end_date: dayjs(data.date).format(),
        duration: data.duration,
        endDate: dayjs(data.date).format("YYYY-MM-DD"),
        recurrence: data.recurrence.toLowerCase(),
        repeatEvery: data.repeatEvery,
        occurrences: checked === "after" ? data.occurrences : null,
        callType: "huddle",
        isEndDate: checked === "after" ? false : true,
        isRecurring: isRecurring,
      };
    }
    try {
      await createScheduleCall(payload)
        .unwrap()
        .then((res: any) => {
          setTimeout(() => {
            navigate("/schedule-call-calendar");
            toast.success(res?.message);
            setIsSubmitting(false);
          }, 5000);
        });
    } catch (error) {
      setIsSubmitting(false);
      if (isFetchBaseQueryError(error)) {
        // toast.error(error?.data?.message);
      }
    }
  };

  function handleRadio(e: any) {
    setChecked(e.target.value);
  }

  const getMeetingOccurrences = async (
    startEventDate: any,
    endEventDate: any,
    recurrence: any,
    repeatEvery: any,
    _: any,
    isEndDateOption: any,
    afterOccurrences: any,
    occursOn: any
  ) => {
    let occurrenceCount = 0;
    let formattedDate;
    let recurrenceWord;
    let finalMessage;
    const occurrenceDates = [];
    // Calculate the number of occurrences based on the repeat frequency
    switch (recurrence) {
      case "Daily":
        recurrenceWord = "day";
        occurrenceCount = 0;
        const date1 = dayjs(dayjs(startEventDate).format("YYYY-MM-DD"));
        const date2 = dayjs(dayjs(endEventDate).format("YYYY-MM-DD"));
        const diff = date2.diff(date1, "day") + 1;
        occurrenceCount = Math.ceil(diff / repeatEvery);
        if (!isEndDateOption) {
          occurrenceCount = afterOccurrences;
        }
        for (let i = 0; i < occurrenceCount; i++) {
          const occurrenceDate = new Date(startEventDate);
          occurrenceDate.setDate(occurrenceDate.getDate() + i * repeatEvery);
          occurrenceDates.push(occurrenceDate.toISOString().slice(0, 10));
        }
        break;
      case "Weekly":
        recurrenceWord = "week";
        occurrenceCount = 0;
        const dateStartWeek = dayjs(dayjs(startEventDate).format("YYYY-MM-DD"));
        const dateEndWeek = dayjs(dayjs(endEventDate).format("YYYY-MM-DD"));
        const diffWeek = dateEndWeek.diff(dateStartWeek, "week") + 1;
        occurrenceCount = Math.ceil(diffWeek / repeatEvery);
        if (!isEndDateOption) {
          occurrenceCount = afterOccurrences;
        }
        for (let i = 0; i < occurrenceCount; i++) {
          const occurrenceDate = new Date(startEventDate);
          occurrenceDate.setDate(
            occurrenceDate.getDate() + i * repeatEvery * 7
          );
          occurrenceDates.push(occurrenceDate.toISOString().slice(0, 10));
        }
        break;
      case "Monthly":
        recurrenceWord = "month";
        occurrenceCount = 0;
        const dateStartMonth = dayjs(
          dayjs(startEventDate).format("YYYY-MM-DD")
        );
        const dateEndMonth = dayjs(dayjs(endEventDate).format("YYYY-MM-DD"));
        const diffMonth = dateEndMonth.diff(dateStartMonth, "month") + 1;
        occurrenceCount = Math.ceil(diffMonth / repeatEvery);
        if (!isEndDateOption) {
          occurrenceCount = afterOccurrences;
        }
        for (let i = 0; i < occurrenceCount; i++) {
          const occurrenceDate = new Date(startEventDate);
          occurrenceDate.setMonth(occurrenceDate.getMonth() + i * repeatEvery);
          occurrenceDates.push(occurrenceDate.toISOString().slice(0, 10));
        }
        break;
      default:
        break;
    }

    // Calculate the end date based on the number of occurrences
    if (isEndDateOption) {
      // User have selected the End Date Option
      if (occurrenceCount > 0) {
        formattedDate = dayjs(endEventDate).format("MMM D, YYYY");
      }
      const newOccurrenceCount = (
        occurrenceCount / Number(repeatEvery)
      ).toFixed(0);
      finalMessage = `Every ${repeatEvery} ${recurrenceWord} on ${occursOn} , until ${formattedDate}, ${
        newOccurrenceCount ? newOccurrenceCount : 0
      }  occurrences`;
    } else {
      // User have selected the After Occurrences Option
      finalMessage = `Every ${repeatEvery} ${recurrenceWord}, ${afterOccurrences} occurrences`;
    }
    console.log(finalMessage);
  };

  const eventStartDate = watch("date");
  const eventEndDate = watch("endDate");
  const repeatEvery = watch("repeatEvery");
  
  const getRecurringText = () => {
    const { recurrence, repeatEvery, occurrences } = getValues();
    const isByDate = checked === "by";
    const start = dayjs(dayjs(eventStartDate).format("YYYY-MM-DD"));
    const end = dayjs(dayjs(eventEndDate).format("YYYY-MM-DD"));
    const formattedEnd = dayjs(eventEndDate).format("MMM D, YYYY");
    let text = "";

    if (recurrence === "Daily") {
      let occurrenceCount = 0;
      if (!isByDate) {
        return `Every ${repeatEvery ? repeatEvery : ""} ${Number(repeatEvery) > 1 ? "days": "day"}, ${occurrences} occurrence(s)`;
      } else {
        const diff = end.diff(start, "day") + 1;
        occurrenceCount = Math.ceil(diff / Number(repeatEvery));
        text = `Every ${repeatEvery ? repeatEvery : ""} ${Number(repeatEvery) > 1 ? "days": "day"}, until ${formattedEnd}, ${occurrenceCount} occurrence(s)`;
      }
    }
    if (recurrence === "Weekly") {
      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const result = [];
      for (const day of selectedDays) {
        const index = parseInt(day) - 1; // Adjust index to match array indices (starting from 0)
        result.push(daysOfWeek[index]);
      }
      const daysString = result?.join(", ");
      if (!isByDate) {
        return `Every ${repeatEvery ? repeatEvery : ""} ${Number(repeatEvery) > 1 ? "weeks": "week"} on ${daysString}, ${occurrences} occurrence(s)`;
      } else {
        let count = 0;
        let currentDate = start;
        while (currentDate.isSameOrBefore(end)) {
          const weekdayValue = String(currentDate.day() + 1);
          if (selectedDays.includes(weekdayValue)) {
            count++;
          }
          currentDate = currentDate.add(1, "day");
        }
        const newOccur = (count / Number(repeatEvery)).toFixed(0);
        text = `Every ${repeatEvery ? repeatEvery : ""} ${Number(repeatEvery) > 1 ? "weeks": "week"} on ${daysString} until ${formattedEnd}, ${newOccur} occurrence(s)`;
        // text = `Every week on ${daysString} until ${formattedEnd}`;
      }
    }
    if (recurrence === "Monthly") {
      const monthValue =
        monthState.mode === "WEEK_DAY"
          ? `${monthState.monthlyDay} of the month`
          : `${monthState.weekOption.title} ${monthState.weekDayOption.title}`;
      if (!isByDate) {
        return `Every ${repeatEvery ? repeatEvery : ""} ${Number(repeatEvery) > 1 ? "months": "month"} on the ${monthValue}, ${occurrences} occurrence(s)`;
      } else {
        let count = 0;
        let currentDate = start;
        const endDate = end;
        while (currentDate.isSameOrBefore(end)) {
          if (monthState.mode === "WEEK_DAY") {
            if (
              (currentDate != start && currentDate.month() != endDate.month()) ||
              (currentDate == start && currentDate.date() <= monthState.monthlyDay) ||
              (currentDate.month() == endDate.month() && endDate.date() >= monthState.monthlyDay)
            ) {
              count++;
            }
          } else {
            const newDate = getDateOfMonth(
              currentDate,
              monthState.weekOption.title,
              monthState.weekDayOption.title
            );
            if (
              (currentDate != start &&
                currentDate.month() != endDate.month()) ||
              (currentDate == start && currentDate.date() <= newDate.date()) ||
              (currentDate.month() == endDate.month() &&
                endDate.date() >= newDate.date())
            ) {
              count++;
            }
          }
          currentDate = currentDate.add(1, "month");
        }
        const lastDay = dayjs(end).date() ;
        if((dayjs(start).month() !== dayjs(end).month())){
          if( lastDay >= monthState.monthlyDay){
            count = count ++;
          }
        }
        const newOccur = (count / Number(repeatEvery)).toFixed(0);
        text = `Every  ${repeatEvery ? repeatEvery : ""} ${Number(repeatEvery) > 1 ? "months": "month"} on the ${monthValue} until, ${formattedEnd}, ${newOccur} occurrence(s)`;
      }
    }
    return text;
  };

  function getDateOfMonth(
    currentDate: any,
    selectWeekCount: any,
    selectedDay: any
  ) {
    const __weekDays: any = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    };

    const _weeksCount: any = { First: 0, Second: 1, Third: 2, Fourth: 3 };

    if (selectWeekCount == "Last") {
      const lastDayOfMonth = currentDate.endOf("month");
      const dayCount = lastDayOfMonth.day();
      let targetDate;
      if (__weekDays[selectedDay] <= dayCount) {
        targetDate = lastDayOfMonth.subtract(
          dayCount - __weekDays[selectedDay],
          "day"
        );
      } else {
        targetDate = lastDayOfMonth.subtract(
          7 - (__weekDays[selectedDay] - dayCount),
          "day"
        );
      }
      return targetDate;
    } else {
      const firstDayOfMonth = currentDate.startOf("month");
      const dayOfWeek = firstDayOfMonth.day();

      const daysToTargetDay = (7 + __weekDays[selectedDay] - dayOfWeek) % 7;

      const targetDate = firstDayOfMonth.add(
        daysToTargetDay + _weeksCount[selectWeekCount] * 7,
        "day"
      );

      return targetDate;
    }
  }


  const setAutoEndDate = () => {
    const { recurrence, date } = getValues();
    const eee = recurrence;
    switch (eee) {
      case "Daily":
        //Add 1 day
        setValue("endDate", dayjs(date).endOf("day").add(1, "day").toDate());
        //setValue("endDate", new Date(date + 86400000));
        break;
      case "Weekly":
        //Add 1 week
        setValue("endDate", dayjs(date).endOf("day").add(1, "week").toDate());
        break;
      case "Monthly":
        //Add 1 month
        setValue("endDate", dayjs(date).add(1, "month").endOf("day").toDate());
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    if (autoReccuranceEndDate) {
      setValue("endDate", new Date(autoReccuranceEndDate));
    }
  }, [autoReccuranceEndDate, setValue]);

  function handleStarDate(date: any) {
    let formateddDate = dayjs(new Date()).format();
    if (date && dayjs(date).isValid()) {
      formateddDate = dayjs(date).format();
    }

    setDate(formateddDate);
    setValue("date", formateddDate);
    setAutoEndDate();
  }
  const { recurrence } = getValues();

  return (
    <Layout>
      <Stack direction="row" gap="2rem" sx={{ mb: "6.8rem" }}>
        <Link to="/resources-library">
          <IconButton sx={classes.backArrow} disableRipple>
            <ArrowRightAltIcon />
          </IconButton>
        </Link>
        <Typography variant="h2">Schedule Huddle Call</Typography>
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ marginLeft: "7.1rem" }}>
          <Box sx={{ display: "flex", gap: "18.6rem" }}>
            <Box sx={{ mt: "1rem" }}>
              <CustomTypography title="Title" />
            </Box>

            <Box sx={{ width: "35rem", height: "6rem", mb: "3rem" }}>
              <TextField
                {...register("title")}
                fullWidth
                type="text"
                placeholder="Enter Title"
                sx={{
                  "& .MuiInputBase-input": {
                    position: "relative",
                    backgroundColor: "#FFFFFF",
                    // border: "1px solid #fff",
                    borderRadius: "0.76rem",
                    fontSize: 16,

                    padding: "10px 12px",
                    // boxShadow: "0px 1px 4px rgb(0 0 0 / 10%)",
                    // "&:focus": {
                    //    boxShadow: "none",
                    //   border: "1px solid #2165A2",
                    // },
                    "&::placeholder": {
                      color: "#BFBFBF",
                      opacity: 1,
                    },

                    "& .MuiOutlinedInput-root": {
                      // borderRadius:"0.76rem",
                      // fontSize: "1.4rem",
                      fontWeight: 400,
                    },
                  },
                }}
              />
              {errors?.title && (
                <p style={{ fontSize: "14px", color: "red" }}>
                  {errors?.title?.message}
                </p>
              )}
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "17.9rem", alignItems: "center" }}>
            <Box >
              <CustomTypography title="Date" />
            </Box>

            <Box sx={{ width: "35rem", height: "6rem" }}>
              <CustomDatepicker
                selected={new Date(date)}
                onChange={(date) => handleStarDate(date)}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                minDate={new Date()}
                minTime={new Date()}
                // open
              />
              {startDateError !== "" && (
                <div style={{ fontSize: "10px", color: "red" }}>
                  {startDateError}
                </div>
              )}
            </Box>
          </Box>

          <Box sx={{ display: "flex", gap: "12.2rem", mt: "3rem", alignItems: "center" }}>
            <Box>
              <CustomTypography title="Duration" />
            </Box>
            <Box>
              <FormControl
                sx={{
                  width: "22.733rem",
                  "& .MuiInputBase-root": { height: "5.25rem" },
                }}>
                <Controller
                  control={control}
                  name="duration"
                  defaultValue={60}
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  render={({ field: { onChange, ...restField } }) => (
                    <Select
                      fullWidth
                      sx={{
                        fontSize: "2rem",
                        fontWeight: 400,
                        borderColor: "#2165A2",
                        backgroundColor: "#fcfcfb",
                        borderRadius: "7px",
                        py: "0rem",
                        px: "1rem",
                        "& .MuiSelect-icon": {
                          width: "3rem",
                          height: "3rem",
                          top: "calc(50% - 1.5rem)",
                        },
                        "& .MuiMenuItem-root": {
                          fontSize: "1.8rem",
                        },
                        "& .MuiSelect-select": {
                          py: "1.8rem",
                        },
                      }}
                      defaultValue={60}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            "& .MuiMenuItem-root": {
                              fontSize: "1.8rem",
                            },
                          },
                        },
                      }}
                      renderValue={(selected: any) => {
                        if (selected.length === 0) {
                          return <em>Placeholder</em>;
                        }
                        return <span>{selected}</span>;
                      }}
                      onChange={(event: any) => {
                        onChange(event);
                        // handleSelectChange(event)
                      }}>
                      {durations?.map((user: any, index: number) => {
                        return (
                          <MenuItem
                            value={user}
                            sx={selectItemStyle}
                            key={index}>
                            {user}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
                {errors?.repeatEvery && (
                  <p style={{ fontSize: "10px", color: "red" }}>
                    {errors?.repeatEvery?.message}
                  </p>
                )}
              </FormControl>
              <Typography
                component="span"
                sx={{
                  fontSize: "2.7rem",
                  color: "#333333",
                  fontWeight: "400",
                  ml: "1.2rem",
                  alignItems: "center",
                }}>
                mins
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "10.9rem",
              mt: "3rem",
              alignItems: "center",
            }}>
            <Box>
              <CustomTypography title="Recurring" />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Controller
                control={control}
                name="recurrencecall"
                defaultValue={false}
                render={({ field: { onChange, ...rest } }) => (
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        checked={isRecurring}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: "2.625rem" } }}
                        onChange={() => {
                          onChange();
                          setIsRecurring(!isRecurring);
                        }}
                        {...rest}
                      />
                    }
                  />
                )}
              />

              {isRecurring ? (
                <Typography
                  variant="h6"
                  sx={{
                    color: "#333333",
                    fontSize: "1.8rem",
                    fontWeight: "400",
                  }}>
                  {/* {occurrenceString} */}
                  {getRecurringText()}
                </Typography>
              ) : null}
            </Box>
          </Box>
          {isRecurring ? (
            <div>
              <Box sx={{ display: "flex", gap: "9.4rem", mt: "3rem" }}>
                <Box>
                  <CustomTypography title="Recurrence" />
                </Box>
                <Box>
                  <FormControl
                    sx={{
                      width: "22.733rem",
                      "& .MuiInputBase-root": { height: "5.25rem" },
                    }}>
                    <Controller
                      control={control}
                      name="recurrence"
                      defaultValue="Daily"
                      render={({ field: { onChange, ...restField } }) => (
                        <Select
                          fullWidth
                          sx={{
                            fontSize: "2rem",
                            fontWeight: 400,
                            borderColor: "#2165A2",
                            backgroundColor: "#fcfcfb",
                            borderRadius: "7px",
                            py: "0rem",
                            px: "1rem",
                            "& .MuiSelect-icon": {
                              width: "3rem",
                              height: "3rem",
                              top: "calc(50% - 1.5rem)",
                            },
                            "& .MuiMenuItem-root": {
                              fontSize: "1.8rem",
                            },
                            "& .MuiSelect-select": {
                              py: "1.8rem",
                            },
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                "& .MuiMenuItem-root": {
                                  fontSize: "1.8rem",
                                },
                              },
                            },
                          }}
                          renderValue={(selected: any) => {
                            if (selected === "") {
                              return <span>Select Coach</span>;
                            }
                            return <span>{selected}</span>;
                          }}
                          onChange={(event: any) => {
                            onChange(event);
                            // handleSelectChange(event)
                            setAutoEndDate();
                          }}
                          {...restField}>
                          {options?.map((user: any, index: number) => {
                            return (
                              <MenuItem
                                value={user}
                                sx={selectItemStyle}
                                key={index}>
                                {user}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                    {errors?.recurrence && (
                      <p style={{ fontSize: "10px", color: "red" }}>
                        {errors?.recurrence?.message}
                      </p>
                    )}
                  </FormControl>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: "7rem", mt: "3rem" }}>
                <Box>
                  <CustomTypography title="Repeat Every" />
                </Box>
                <Box>
                  <FormControl
                    sx={{
                      width: "22.733rem",
                      "& .MuiInputBase-root": { height: "5.25rem" },
                    }}>
                    <Controller
                      control={control}
                      name="repeatEvery"
                      defaultValue={"1"}
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      render={({ field: { onChange, ...restField } }) => (
                        <Select
                          fullWidth
                          sx={{
                            fontSize: "2rem",
                            fontWeight: 400,
                            borderColor: "#2165A2",
                            backgroundColor: "#fcfcfb",
                            borderRadius: "7px",
                            py: "0rem",
                            px: "1rem",
                            "& .MuiSelect-icon": {
                              width: "3rem",
                              height: "3rem",
                              top: "calc(50% - 1.5rem)",
                            },
                            "& .MuiMenuItem-root": {
                              fontSize: "1.8rem",
                            },
                            "& .MuiSelect-select": {
                              py: "1.8rem",
                            },
                          }}
                          defaultValue={"1"}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                "& .MuiMenuItem-root": {
                                  fontSize: "1.8rem",
                                },
                              },
                            },
                          }}
                          renderValue={(selected: any) => {
                            if (selected.length === 0) {
                              return <em>Placeholder</em>;
                            }
                            return <span>{selected}</span>;
                          }}
                          onChange={(event: any) => {
                            onChange(event);
                            // handleSelectChange(event)
                          }}>
                          {days?.map((user: any, index: number) => {
                            return (
                              <MenuItem
                                value={user}
                                sx={selectItemStyle}
                                key={index}>
                                {user}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                    {errors?.repeatEvery && (
                      <p style={{ fontSize: "10px", color: "red" }}>
                        {errors?.repeatEvery?.message}
                      </p>
                    )}
                  </FormControl>
                  <Typography
                    component="span"
                    sx={{
                      fontSize: "2.7rem",
                      color: "#333333",
                      fontWeight: "400",
                      ml: "1.2rem",
                      alignItems: "center",
                    }}>
                    {recurrence === "Weekly" ? "week(s)"
                      : recurrence === "Daily" ? "day(s)" 
                      : "month(s)"}
                  </Typography>
                </Box>
              </Box>
              {recurrence === "Weekly" && (
                <>
                  {/* <Box sx={{ display: "flex", gap: "9.4rem", mt: "3rem" }}>
                    <Box>
                      <CustomTypography title="Occurs on" />
                    </Box>
                    <Box>
                      <FormControl
                        sx={{
                          width: "22.733rem",
                          "& .MuiInputBase-root": { height: "5.25rem" },
                        }}>
                        <Controller
                          control={control}
                          name="WeekDay"
                          defaultValue="Monday"
                          render={({ field: { onChange, ...restField } }) => (
                            <Select
                              fullWidth
                              sx={{
                                fontSize: "2rem",
                                fontWeight: 400,
                                borderColor: "#2165A2",
                                backgroundColor: "#fcfcfb",
                                borderRadius: "7px",
                                py: "0rem",
                                px: "1rem",
                                "& .MuiSelect-icon": {
                                  width: "3rem",
                                  height: "3rem",
                                  top: "calc(50% - 1.5rem)",
                                },
                                "& .MuiMenuItem-root": {
                                  fontSize: "1.8rem",
                                },
                                "& .MuiSelect-select": {
                                  py: "1.8rem",
                                },
                              }}
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    "& .MuiMenuItem-root": {
                                      fontSize: "1.8rem",
                                    },
                                  },
                                },
                              }}
                              renderValue={(selected: any) => {
                                if (selected === "") {
                                  return <span>Select Day</span>;
                                }
                                return <span>{selected}</span>;
                              }}
                              onChange={(event: any) => {
                                onChange(event);
                                // handleSelectChange(event)
                              }}
                              {...restField}>
                              {OccursOnWeekly?.map(
                                (user: any, index: number) => {
                                  return (
                                    <MenuItem
                                      value={user}
                                      sx={selectItemStyle}
                                      key={index}>
                                      {user}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Select>
                          )}
                        />
                        {errors?.recurrence && (
                          <p style={{ fontSize: "10px", color: "red" }}>
                            {errors?.recurrence?.message}
                          </p>
                        )}
                      </FormControl>
                    </Box>
                  </Box> */}
                  <SelectWeeklyDays
                    selectedDays={selectedDays}
                    setSelectedDays={setSelectedDays}
                  />
                </>
              )}

              {recurrence === "Monthly" && (
                // occursOnMonthly()
                <SelectMonthlyDays
                  state={monthState}
                  setState={setMonthState}
                />
              )}

              {/* {recurrence === "Monthly" && occursOnMonthly()} */}
              <Box sx={{ display: "flex", mt: "3rem" }}>
                <div className="min-w-[23rem]">
                  <Box sx={{ mt: "0.8rem" }}>
                    <CustomTypography title="End Date" />
                  </Box>
                </div>

                <FormControl
                  sx={{
                    ml: "2.5rem",
                    mt: "1rem",
                    "& .MuiFormGroup-root": {
                      gap: "5rem",
                    },
                  }}>
                  <RadioGroup
                    onChange={handleRadio}
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="by"
                    name="radio-buttons-group">
                    <FormControlLabel
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: "2rem",
                        },
                        "& .MuiTypography-root ": {
                          fontSize: "2.70362rem",
                          fontWeight: "400",
                          color: "#333333",
                          lineHeight: "3.2rem",
                        },
                      }}
                      value="by"
                      control={<Radio />}
                      label="By"
                      {...register("by")}
                    />

                    <FormControlLabel
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: "2rem",
                        },
                        "& .MuiTypography-root ": {
                          fontSize: "2.70362rem",
                          fontWeight: "400",
                          color: "#333333",
                          lineHeight: "3.2rem",
                        },
                      }}
                      value="after"
                      control={<Radio />}
                      label="After"
                    />
                  </RadioGroup>
                </FormControl>
                <Box sx={{ width: "35rem" }}>
                  <Box>
                    <Controller
                      control={control}
                      name="endDate"
                      render={({ field: { onChange, ...restField } }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MuiDatePicker
                            label=""
                            onChange={(event: any) => {
                              onChange(event);
                              setEndDate(event);
                              setError("");
                            }}
                            maxDate={maxDate}
                            disablePast
                            disabled={checked === "after" ? true : false}
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                sx={dateInputStyle}
                                {...params}
                                error={false}
                              />
                            )}
                            PaperProps={{
                              sx: calendarStyle,
                            }}
                            {...restField}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    <p style={{ fontSize: "10px", color: "red" }}>{error}</p>
                  </Box>

                  <Box sx={{ mt: "2.5rem" }}>
                    <FormControl
                      sx={{
                        width: "17.1rem",
                        "& .MuiInputBase-root": { height: "6rem" },
                      }}>
                      <Controller
                        control={control}
                        name="occurrences"
                        defaultValue="1"
                        render={({ field: { onChange, ...restField } }) => (
                          <Select
                            fullWidth
                            disabled={checked === "by"}
                            {...restField}
                            sx={{
                              fontSize: "2rem",
                              fontWeight: 400,
                              borderColor: "#2165A2",
                              backgroundColor: "#fcfcfb",
                              borderRadius: "7px",
                              py: "0rem",
                              px: "1rem",
                              "& .MuiSelect-icon": {
                                width: "3rem",
                                height: "3rem",
                                top: "calc(50% - 1.5rem)",
                              },
                              "& .MuiMenuItem-root": {
                                fontSize: "1.8rem",
                              },
                              "& .MuiSelect-select": {
                                py: "1.8rem",
                              },
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  "& .MuiMenuItem-root": {
                                    fontSize: "1.8rem",
                                  },
                                },
                              },
                            }}
                            renderValue={(selected: any) => {
                              if (selected === "") {
                                return <span>Select</span>;
                              }
                              return <span>{selected}</span>;
                            }}
                            onChange={(event: any) => {
                              onChange(event);
                              setErrorOccurrences("");
                            }}>
                            {days?.map((item: any, index: number) => {
                              return (
                                <MenuItem
                                  value={item}
                                  sx={selectItemStyle}
                                  key={index}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>

                    <Typography
                      component="span"
                      sx={{
                        fontSize: "2.7rem",
                        color: "#333333",
                        fontWeight: "400",
                        ml: "1.2rem",
                      }}>
                      occurrences
                    </Typography>
                  </Box>
                  <p style={{ fontSize: "10px", color: "red" }}>
                    {errorOccurrences}
                  </p>
                </Box>
              </Box>
            </div>
          ) : null}

          <Box>
            <Button
              type="submit"
              disabled={isLoading || isSubmitting}
              sx={{
                mt: "3.1rem",
                px: "21.2rem",
                py: "1.6rem",
                backgroundColor: "#2165A2",
                lineHeight: "2.9rem",
                fontSize: "2.49552rem",
                fontWeight: "500",
                borderRadius: "1rem",
                color: "#FFFFFF",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#2165A2",
                  boxShadow: "none",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#eaebeb",
                  color: "#2165A2",
                },
              }}>
              Schedule
            </Button>
          </Box>
        </Box>
      </form>
    </Layout>
  );
};

export default ScheduleHuddleCall;

export const dateInputStyle = {
  fontWeight: 800,
  fontSize: "10px",
  lineHeight: "15px",
  width: "35rem",
  maxWidth: "35rem",
  "& .MuiInputBase-input": {
    padding: 0,
    paddingLeft: "2rem",
    margin: 0,
    fontWeight: 400,
    fontSize: "1.6rem",
    lineHeight: "15px",
    height: "6rem",
    borderRadius: "1.4rem",
    backgroundColor: "#fff",
    color: "#333333",
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "##fff",
    borderRadius: "1.4rem",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "15px",
    "& fieldset": {
      fontWeight: 400,
      fontSize: "10px",
      lineHeight: "15px",
      borderRadius: "1.4rem",
      borderColor: "#D0D6DE",
    },
    "&:hover fieldset": {
      borderWidth: "1px",
      borderColor: "#D0D6DE",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#D0D6DE",
    },
  },
  "& .MuiInputAdornment-root": {
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "15px",
    backgroundColor: "#fff",
    color: "yellow",
    borderRadius: "8px",
    "& .MuiSvgIcon-root": {
      width: "2.2rem",
      height: "2.2rem",
    },
  },
};

export const calendarStyle = {
  backgroundColor: "#fff",
  padding: "12px",
  borderRadius: "8px",
  boxShadow: "0px 4px 26px rgba(0, 0, 0, 0.1)",
  marginTop: "6px",
  width: "300px",

  "& .MuiCalendarPicker-root": {
    width: "276px",
    margin: 0,
  },

  "& .MuiPickersCalendarHeader-root": {
    "& .MuiPickersCalendarHeader-labelContainer": {
      fontSize: "1.8rem",
      color: "#2165A2",
      "& .MuiSvgIcon-root": {
        width: "3rem",
        height: "3rem",
      },
    },
    "& .MuiPickersArrowSwitcher-root": {
      "& .MuiButtonBase-root": {
        color: "#2165A2",
        "& .MuiSvgIcon-root": {
          width: "2.4rem",
          height: "2.4rem",
        },
      },
    },
  },

  "& .MuiCalendarPicker-root > div:first-of-type": {
    margin: "0 0 9px",
    padding: "0 4px 4px",
    borderBottom: "1px solid #dce3fc61",
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },

  "& .MuiDayPicker-header": {
    "& .MuiDayPicker-weekDayLabel": {
      width: "4rem",
      height: "4rem",
      fontSize: "1.8rem",
      color: "#2165A2",
      fontWeight: 600,
    },
  },

  "& .MuiDayPicker-weekContainer": {
    "& .MuiPickersDay-root": {
      width: "4rem",
      height: "4rem",
      fontSize: "1.6rem",
      borderRadius: "5px",
      color: "#333333",
      "&.Mui-selected": {
        backgroundColor: "#2165A2",
        color: "#fff",
        fontWeight: 600,
      },
      "&.Mui-disabled": {
        backgroundColor: "#d0d6de4a",
        color: "#33333399",
      },
    },
  },

  "& .MuiMonthPicker-root": {
    width: "246px",
    margin: 0,
    gap: "10px",

    "& .PrivatePickersMonth-root": {
      borderRadius: "8px",
      fontSize: "2rem",
      color: "#000000",
      fontWeight: 500,
      margin: 0,
      backgroundColor: "#fff",
      flex: "1 0 calc(33.33% - 10px)",
      "&:hover": {
        color: "#5173F0",
        backgroundColor: "#EEF2F9CC",
      },
      "&:disabled": {
        color: "#00000026",
      },
      "&.Mui-selected": {
        color: "#fff",
        backgroundColor: "#5173F0",
      },
    },
  },

  "& .MuiYearPicker-root": {
    width: "100%",
    margin: 0,
    gap: "10px",

    "& .PrivatePickersYear-root": {
      flex: "1 0 calc(33.33% - 10px)",

      "& .PrivatePickersYear-yearButton": {
        borderRadius: "8px",
        fontSize: "2rem",
        color: "#000000",
        fontWeight: 500,
        margin: 0,
        backgroundColor: "#fff",
        width: "100%",
        "&:hover": {
          color: "#fff",
          backgroundColor: "#839CF4",
        },
        "&:disabled": {
          color: "#00000026",
        },
        "&.Mui-selected": {
          color: "#fff",
          backgroundColor: "#5173F0",
        },
      },
    },
  },
};

function CustomTypography({ title }: any) {
  return (
    <>
      <Typography
        variant="h3"
        className="text-[2.7rem] leading-[3.168rem] text-[#333333] font-bold">
        {title}
      </Typography>
    </>
  );
}
