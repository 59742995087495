import React from "react";
import Layout from "../../components/Layout/Layout";
import { AntTab, AntTabs } from "../../styles/ManageUserStyle";
import { Box } from "@mui/material";
import classes from "../../styles/CheckinFormStyle";
import UploadSingleCall from "./UploadSingleCall";
import UploadBulkCalls from "./UploadBulkCalls";
import { toast } from "react-toastify";

const UploadCall = () => {
  const [value, setValue] = React.useState<number>(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    toast.dismiss();
  };

  return (
    <Layout>
      <Box sx={{ px: "2rem" }}>
        <Box sx={classes.antTabBox}>
          <AntTabs value={value} onChange={handleChange} aria-label="ant example">
            <AntTab label="Upload Single" />
            <AntTab label="Upload Bulk" />
          </AntTabs>
        </Box>

        {value === 0 ? (
          <UploadSingleCall callType={"huddle"}/>
        ) : (
          <UploadBulkCalls callType={"huddle"}/>
        )}
      </Box>
    </Layout>
  );
};

export default UploadCall;
