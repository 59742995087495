import React from "react";
import { Listbox } from "@headlessui/react";
import ArrowDown from "../../../../assets/images/ArrowDownBlack.svg";

const weekOptions = [
  {
    title: "First",
    value: 1,
  },
  {
    title: "Second",
    value: 2,
  },
  {
    title: "Third",
    value: 3,
  },
  {
    title: "Fourth",
    value: 4,
  },
  {
    title: "Last",
    value: -1,
  },
];
const weekDayOptions = [
  { title: "Sunday", value: 1 },
  { title: "Monday", value: 2 },
  { title: "Tuesday", value: 3 },
  { title: "Wednesday", value: 4 },
  { title: "Thursday", value: 5 },
  { title: "Friday", value: 6 },
  { title: "Saturday", value: 7 },
];

const SelectMonthlyDays = ({ state, setState }: any) => {
  const monthlyDays = Array.from({ length: 31 }, (_, index) => index + 1);

  return (
    <div className=" flex flex-col sm:flex-row gap-[3rem] mt-[4.8rem] items-start">
      <h3 className="text-[2.7rem] text-[#333] font-semibold min-w-[165px]">
        Occurs on
      </h3>
      <div>
        <div className="flex items-center gap-[12px] mb-12">
          <div className="flex items-center gap-4">
            <input
              type="radio"
              checked={state.mode === "WEEK_DAY"}
              onChange={() =>
                setState((prev: any) => ({
                  ...prev,
                  mode: "WEEK_DAY",
                }))
              }
              className="w-[1.8rem] h-[1.8rem] custom_radio cursor-pointer"
            />
            <h3 className="text-[2.7rem] text-[#333] ">Day</h3>
          </div>
          <div className="w-[18rem] h-[6rem]">
            <Listbox
              defaultValue={state?.monthlyDay}
              disabled={state.mode === "MONTH_DAY"}
              onChange={(selected: any) => {
                setState((prev: any) => ({
                  ...prev,
                  monthlyDay: selected,
                }));
              }}>
              <div className="relative">
                <Listbox.Button className="relative w-full cursor-pointer border-2 border-gray-200 h-[6rem] text-[#333] rounded-[1rem] bg-white py-2 pl-3 pr-10 text-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  <span className="block text-[2rem]">{state.monthlyDay}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[2rem]">
                    <img className="w-[1.6rem]" src={ArrowDown}></img>
                  </span>
                </Listbox.Button>
                <Listbox.Options className="z-50 max-h-[30rem] absolute mt-4 w-full overflow-auto rounded-md bg-white py-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {monthlyDays?.map((option, index) => (
                    <Listbox.Option
                      key={index}
                      className="cursor-pointer text-center h-[4rem]"
                      value={option}>
                      {({ selected }) => {
                        return (
                          <>
                            <p
                              className={`font-regular text-left text-[2rem] flex h-full w-full items-center px-10 hover:bg-gray-200 ${
                                selected
                                  ? "text-primary_blue bg-primary_blue bg-opacity-[20%]"
                                  : "text-[#424242]"
                              }`}>
                              {option}
                            </p>
                          </>
                        );
                      }}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>
            </Listbox>
          </div>
          <div>
            <h3 className="text-[2.7rem] text-[#333] ">of the month</h3>
          </div>
        </div>

        <div className="flex items-center gap-[12px]">
          <div className="flex items-center gap-4">
            <input
              type="radio"
              checked={state.mode === "MONTH_DAY"}
              onChange={() =>
                setState((prev: any) => ({
                  ...prev,
                  mode: "MONTH_DAY",
                }))
              }
              className="w-[1.8rem] h-[1.8rem] custom_radio cursor-pointer"
            />
          </div>
          <div className="w-[18rem] h-[6rem]">
            <Listbox
              defaultValue={state?.weekOption}
              onChange={(selected: any) => {
                setState((prev: any) => ({
                  ...prev,
                  weekOption: selected,
                }));
              }}
              disabled={state.mode === "WEEK_DAY"}>
              <div className="relative">
                <Listbox.Button className="relative w-full cursor-pointer border-2 border-gray-200 h-[6rem] text-[#333]  rounded-[1rem] bg-white py-2 pl-3 pr-10 text-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75  focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  <span className="block text-[2.6rem]">
                    {state.weekOption.title}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[2rem]">
                    <img className="w-[1.6rem]" src={ArrowDown}></img>
                  </span>
                </Listbox.Button>
                <Listbox.Options className="z-50 max-h-[30rem] absolute mt-4 w-full overflow-auto rounded-md bg-white py-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {weekOptions?.map((option, index) => (
                    <Listbox.Option
                      key={index}
                      className="cursor-pointer text-left h-[4rem]"
                      value={option}>
                      {({ selected }) => {
                        return (
                          <>
                            <p
                              className={`font-regular text-left text-[2rem] flex h-full w-full items-center px-7 hover:bg-gray-200 ${
                                selected
                                  ? "text-primary_blue bg-primary_blue bg-opacity-[20%]"
                                  : "text-[#424242]"
                              }`}>
                              {option.title}
                            </p>
                          </>
                        );
                      }}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>
            </Listbox>
          </div>
          <div className="w-[24rem] h-[6rem]">
            <Listbox
              defaultValue={state?.weekDayOption}
              onChange={(selected: any) => {
                setState((prev: any) => ({
                  ...prev,
                  weekDayOption: selected,
                }));
              }}
              disabled={state.mode === "WEEK_DAY"}>
              <div className="relative">
                <Listbox.Button className="relative w-full border-2 border-gray-200 h-[6rem] text-[#333] rounded-[1rem] bg-white cursor-pointer py-2 pl-3 pr-10 text-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  <span className="block text-[2.6rem]">
                    {state.weekDayOption.title}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[2rem]">
                    <img className="w-[1.6rem]" src={ArrowDown}></img>
                  </span>
                </Listbox.Button>
                <Listbox.Options className="z-50 max-h-[30rem] absolute mt-4 w-full overflow-auto rounded-md bg-white py-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {weekDayOptions?.map((option, index) => (
                    <Listbox.Option
                      key={index}
                      className="cursor-pointer text-center h-[4rem]"
                      value={option}>
                      {({ selected }) => {
                        return (
                          <>
                            <p
                              className={`font-regular text-left text-[2rem] flex h-full w-full items-center px-7 hover:bg-gray-200 ${
                                selected
                                  ? "text-primary_blue bg-primary_blue bg-opacity-[20%]"
                                  : "text-[#424242]"
                              }`}>
                              {option.title}
                            </p>
                          </>
                        );
                      }}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>
            </Listbox>
          </div>
          <div>
            <h3 className="text-[2.7rem] text-[#333]">of the month</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectMonthlyDays;
