import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import theme from "../../theme";
import Header from "./Header";
import Sidebar from "./Sidebar";

const classes = {
	main: {
		paddingLeft: "30.4rem",
		paddingTop: "11.5rem",
		[theme.breakpoints.down("md")]: {
			paddingLeft: "0",
		},
	},
};
const InnerContent = styled("div")(({ theme }) => ({
	padding: "2.4rem 6rem 2rem 3rem",
	[theme.breakpoints.down("sm")]: {
		padding: "2.4rem 2rem 2rem 2rem",
	},
}));

const Layout = ({ children }: any) => {
	return (
		<Box>
			<Sidebar />
			<Header />
			<Box component="main" sx={classes.main}>
				<InnerContent>{children}</InnerContent>
			</Box>
		</Box>
	);
};

export default Layout;
