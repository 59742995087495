import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import CrossIcon from "../../../assets/images/cross-white.svg";
import CustomDatepicker from "../../../components/CustomDatepicker";

const FormCalender = ({ startDate, setStartDate, endDate, setEndDate, handleClear }: any) => {
  if (endDate < startDate) {
    toast.warn("Select end date greater than start date")
  }
  return (
    <div className="flex flex-row gap-6 items-center">
      <div className="flex flex-row items-center gap-6">
        <p className="font-bold text-3xl">From:</p>
        <div className="border border-[#373737] rounded-xl max-w-[180px]">
          <CustomDatepicker
            selected={startDate}
            onChange={(date: any) => setStartDate(date)}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy"
            showTimeInput
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            maxDate={new Date()}
          />
        </div>
      </div>

      <div className="flex flex-row items-center gap-6">
        <p className="font-bold text-3xl">To:</p>
        <div className="border border-[#373737] rounded-xl max-w-[180px]">
          <CustomDatepicker
            selected={endDate}
            onChange={(date: any) => setEndDate(date)}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy"
            showTimeInput
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            maxDate={new Date()}
          />
        </div>
      </div>

      <div
        onClick={handleClear}
        className="w-[3.4rem] h-[3.4rem] cursor-pointer border border-[#2165A2] rounded-full"
      >
        <img src={CrossIcon} alt="CrossIcon" className="w-[4rem]" />
      </div>

    </div>
  );
};

export default FormCalender;
