import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { forgotPassword } from "../../apis/auth-apis";
import MobileLogo from "../../components/MobileLogo";
import Spinner from "../../components/Spinner";
import classes from "../../styles/LogonStyle";

interface FormInputs {
  email: string;
}

const Form = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const schema = yup.object().shape({
    email: yup.string().email().required("Email is Required"),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  // navigate("/verification");
  const onSubmit = (data: FormInputs) => {
    forgotPassword(data.email, setLoading, navigate);
  };

  return (
    <Box component="div" sx={classes.LogBlk}>
      <Box component="div" sx={classes.fromWrapper}>
        <MobileLogo />

        <Typography variant="h2" sx={classes.formTitle}>
          Reset your password
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box component="div" sx={classes.form_blk}>
            <Typography variant="h6" sx={classes.formlabelReset}>
              Email
            </Typography>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={classes.logonTextField}
                  id="outlined-basic"
                  variant="outlined"
                  type="email"
                  fullWidth
                  placeholder="User@example.com"
                  error={!!errors.email}
                  helperText={errors.email ? errors.email?.message : null}
                />
              )}
            />
          </Box>

          <Typography sx={classes.resetEmail}>
            Enter the email address associated with your account and we&apos;ll send
            you an OTP to reset your password.
          </Typography>
          <Button
            disabled={loading}
            variant="contained"
            type="submit"
            sx={loading ? classes.disabled : classes.logonBtn}
          >
            {loading ? <Spinner /> : "Send OTP Code"}
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default Form;
