import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Box,
  IconButton,
  MenuItem,
} from "@mui/material";
import { classes } from "../../../../styles/ResourcesLibraryStyle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledMenu } from "../../../../styles/StyledMenu";
import dayjs from "dayjs";
import PlayVideoModal from "../Courses/PlayVideoModal";
import DeleteConfirmModal from "../DeleteConfirmModal";

const RecentHuddleCard = ({
  id,
  title,
  detail,
  thumbnail,
  item,
  date,
  onDelete,
  type,
  url,
  status
}: any) => {
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = () => {
    if (type === "zoom") {
      if (url && status === "completed") {
        window.open(url, "_blank", "noopener,noreferrer")
      }
    } else {
      setOpenModal(true);
    }
  };

  function handleDelete() {
    setIsConfirmOpen(true);
  }

  return (
    <Card sx={classes.Card}>
      <Box sx={{ ...classes.CardActionArea, position: "relative" }}>
        <Box sx={classes.CardActionAreaBox}>
          <CardMedia
            sx={classes.RecentHuddleCardMedia}
            component="img"
            image={thumbnail}
            alt="RecentHuddleImg"
          />
          {type === "zoom" && (
            <Box sx={{ position: "absolute", bottom: "10px", right: "10px", bgcolor: "#757575", borderRadius: "4px", px: "1rem", py: "0.1rem", color: "#fff" }}>
              {status === "completed" ? "Completed" : "Processing"}
            </Box>
          )}
        </Box>
        <CardContent sx={classes.CardContent}>
          <Typography gutterBottom sx={classes.CardTitle}>
            {title}
          </Typography>
          {type !== "zoom" && (
            <Typography sx={classes.RecentHuddlecardDescription}>
              {detail}
            </Typography>
          )}
          <Box
            sx={{
              mt: "1.4rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "1.6rem", color: "#333333" }}>
              {dayjs(date).format("MM-DD-YYYY")}
            </Typography>
            <Box>
              <IconButton
                aria-label="delete"
                sx={{
                  p: 0,
                  boxShadow: "0px 2px 6px rgb(0 0 0 / 9%)",
                  borderRadius: "2px",
                }}
                onClick={handleClick}
              >
                <MoreVertIcon sx={{ color: "#2165A2", fontSize: "3.2rem" }} />
              </IconButton>
            </Box>
          </Box>
        </CardContent>
      </Box>

      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleOpenModal();
            handleClose();
          }}
        >
          <Box>
            <Typography>View</Typography>
            <VisibilityIcon />
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleDelete();
          }}
        >
          <Box>
            <Typography>Delete</Typography>
            <DeleteIcon />
          </Box>
        </MenuItem>
      </StyledMenu>

      <PlayVideoModal
        open={openModal}
        setOpen={setOpenModal}
        videoUrl={item?.file}
        title={title}
        description={detail}
      />

      <DeleteConfirmModal
        onDelete={() => onDelete(id, type)}
        id={id}
        type={type}
        isConfirmOpen={isConfirmOpen}
        setIsConfirmOpen={setIsConfirmOpen}
        title={title}
      />
    </Card>
  );
};

export default RecentHuddleCard;
