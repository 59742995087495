const dateInputStyle = {
  fontWeight: 800,
  fontSize: "10px",
  lineHeight: "15px",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: 0,
    paddingLeft: "2rem",
    margin: 0,
    fontWeight: 400,
    fontSize: "1.6rem",
    lineHeight: "15px",
    height: "6rem",
    borderRadius: "1.4rem",
    backgroundColor: "#fff",
    color: "#333333",
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "##fff",
    borderRadius: "1.4rem",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "15px",
    "& fieldset": {
      fontWeight: 400,
      fontSize: "10px",
      lineHeight: "15px",
      borderRadius: "1.4rem",
      borderColor: "#D0D6DE",
    },
    "&:hover fieldset": {
      borderWidth: "1px",
      borderColor: "#D0D6DE",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#D0D6DE",
    },
  },
  "& .MuiInputAdornment-root": {
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "15px",
    backgroundColor: "#fff",
    color: "yellow",
    borderRadius: "8px",
    "& .MuiSvgIcon-root": {
      width: "2.2rem",
      height: "2.2rem"
    }
  },
};

export default dateInputStyle