import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { useState, useEffect } from "react";
import CheckInFormSelect from "./CheckinFormSelect";
import TocIcon from "@mui/icons-material/Toc";
import classes1 from "../../../styles/CreateFormStyle";
const classes = {
  pubBtn: {
    minWidth: "20.4rem",
    fontWeight: 600,
    fontSize: "1.8rem",
    lineHeight: "2.7rem",
    textTransform: "capitalize",
    borderRadius: "1rem",
  },
};

const schema = yup.object().shape({
  question: yup.string().required("qustion is required"),
  label: yup.string().required("label is required"),
  type: yup.string().required("type is required"),
});

const Question1 = ({
  setQuestionsData,
  questionsData,
  length,
  activeData,
  questionItem,
  index,
}: any) => {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });
  const [isLoading, setisLoading]: any = useState(false);

  useEffect(() => {
    if (questionItem) {
      setValue("question", questionItem?.question);
      setValue("label", questionItem?.label);
      setValue("type", questionItem?.type);
    }
  }, []);
  const onSubmit = (data: any) => {
    setisLoading(true);

    const record = questionsData.find(
      (question: any) => question.id == questionItem.id
    );

    if (record) {
      setQuestionsData(
        questionsData.map((item: any) => {
          return item.id === questionItem.id
            ? {
              question: data.question,
              label: data.label,
              type: data.type,
              id: questionItem.id,
            }
            : item;
        })
      );
      setTimeout(() => setisLoading(false), 1000);
      return;
    }

    setQuestionsData([
      ...questionsData,
      {
        id: activeData.id,
        question: data?.question,
        label: data?.label,
        type: data?.type,
        length: length,
        added: true,
      },
    ]);
    setTimeout(() => setisLoading(false), 1000);
  };

  return (
    <div className="px-8 pt-16">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="flex justify-between">
            <h6 className="require after:!text-[red] text-3xl font-semibold">
              Question
            </h6>
            <div>
              <Button
                disableElevation
                disableRipple
                startIcon={<TocIcon />}
                variant="contained"
                sx={classes1.listIconBtn}
              >
                {index + 1}
              </Button>
            </div>
          </div>
          <div className="flex items-center mt-5 max-w-[90%]">
            <input
              type="text"
              className="bg-[#A2A3AC0A] px-10 text-[1.6rem] border border-[#D0D6DE] rounded-3xl font-medium outline-none h-24 w-full"
              placeholder="Question"
              {...register("question")}
            />
          </div>
          <span style={{ color: "red" }}>
            {errors?.question ? "Question is required" : null}
          </span>
        </div>
        <div className="mt-8">
          <h6 className="require after:!text-[red] text-3xl font-semibold">
            Label
          </h6>
          <div className="flex items-center mt-5 max-w-[90%]">
            <input
              type="text"
              className="bg-[#A2A3AC0A] px-10 text-[1.6rem] border border-[#D0D6DE] rounded-3xl font-medium outline-none h-24 w-full"
              placeholder="Label"
              {...register("label")}
            />
          </div>
          <span style={{ color: "red" }}>
            {errors.label ? "Label is required" : null}
          </span>
        </div>
        <div className="mt-8">
          <div className="flex items-center mt-5 max-w-[90%]">
            <CheckInFormSelect register={register} />
          </div>
          <span style={{ color: "red" }}>
            {errors.label ? "type is required" : null}
          </span>
        </div>
        <div className="mt-8">
          <Button
            variant="contained"
            sx={classes.pubBtn}
            disableElevation
            disableRipple
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {isLoading ? "Saving..." : "save"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default React.memo(Question1);
