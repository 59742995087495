import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import { useGetAllPointsQuery } from '../../services/admin';
import classes from '../../styles/CheckinFormStyle';
import EditManagePointsUser from './componenets/EditUser';

const ManageUserPoints = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const { data } = useGetAllPointsQuery(1, {
    refetchOnMountOrArgChange: true,
  });
  const details = data?.data?.clientScore;
  const selectedItem = details?.find((item:any) => item?.id === id);

  return (
    <Layout>
      <Box sx={classes.headWithBtn}>
        <Stack direction='row' gap='2rem'>

          <IconButton
            sx={classes.backArrow}
            disableRipple
            onClick={() => {
              navigate("/manage-points")
            }}>
            <ArrowRightAltIcon />
          </IconButton>

          <Typography variant='h2'>
            Edit {selectedItem?.user?.full_name ? selectedItem?.user?.full_name : "User"}
          </Typography>
        </Stack>

      </Box>
      <EditManagePointsUser data={selectedItem}/>
    </Layout>
  );
};

export default ManageUserPoints;
