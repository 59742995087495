import CoursesCard from "./CoursesCard";
import { Grid, Paper, Typography } from "@mui/material";
import { useGetCoursesQuery, useDeleteCourseMutation } from "../../../../services/admin";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Courses = () => {
  const { data } = useGetCoursesQuery(1, { refetchOnMountOrArgChange: true });
  const [deleteCourse] = useDeleteCourseMutation();
  const navigate = useNavigate(); 

  const deleteHandler = (id: string) => {
    deleteCourse(id).unwrap().then(() => {
      toast.success("Course Deleted");
    });
  }

  const updateHandler = async (id: string) => {
    navigate(`/edit-course/${id}`)
  };

  return (
    <>
      {data?.data?.result?.length === 0 && (
        <Paper sx={{ mt: "2rem", px: "2rem", py: "6rem", textAlign: "center" }}>
          <Typography sx={{ fontSize: "2.8rem", fontWeight: 600, color: "#2165A2" }}>No Courses Found</Typography>
        </Paper>
      )}

      <Grid container spacing="2rem" marginTop="0rem">
        {data?.data?.result?.map((item: any, index: number) => {
          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <CoursesCard
                title={item.title}
                detail={item.detail}
                thumbnail={item.thumbnail}
                id={item.id}
                video={item.file}
                date={item.createdAt}
                onDelete={deleteHandler}
                onUpdate={updateHandler}
              />
            </Grid>
          );
        })}
      </Grid>
    </>

  )
};

export default Courses;