import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React from "react";
import { toast } from "react-toastify";
import { isFetchBaseQueryError, useAssignCoachMutation, useGetAllCoachesQuery } from "../../../services/admin";
import { dateInputStyle } from "../../../styles/InviteClientCalederStyle";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "80rem",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  borderRadius: "1rem",
  outline: "none",
  overflow: "hidden"
};

interface InviteUserModalProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  user: any
}

const AssignCoachModal = ({ open, setOpen, user }: InviteUserModalProps) => {
  const [coach, setCoach] = React.useState<any>();

  const { data } = useGetAllCoachesQuery(1, {
    refetchOnMountOrArgChange: true,
  });
  const coaches = data?.data?.users?.filter((item: any) => item?.id !== user?.coach?.id)

  const [assignCoach, { isLoading }] = useAssignCoachMutation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: any) => {
    setCoach(event);
  };

  const submitHandler = async () => {
    const payload = {
      userId: user.id,
      newCoachId: coach?.id
    }

    try {
      await assignCoach({ payload })
        .unwrap()
        .then(() => {
          toast.success("New Coach Assigned");
          handleClose();
        });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error.data.message);
      }
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2165A2",
            position: "relative",
            minHeight: "4.4rem"
          }}
          onClick={handleClose}
        >
          <Typography
            sx={{ fontSize: "2.2rem", fontWeight: 600, color: "#fff" }}
          >
            Assign Coach
          </Typography>
          <IconButton sx={{ position: "absolute", right: 0 }}>
            <CloseIcon sx={{ fontSize: "2.6rem", color: "#fff", fontWeight: 600 }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            minHeight: "50rem",
            width: "100%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "40%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >

            <Box sx={{ minWidth: "46rem" }}>
              <Typography
                sx={{
                  fontSize: "2.4rem",
                  fontWeight: 400,
                  color: "#333333",
                  mb: "1.6rem",
                }}
              >
                Assign Coach
              </Typography>

              <Autocomplete
                freeSolo
                options={coaches || []}
                placeholder="select coach"
                onChange={(_: any, newValue: string | null) => {
                  handleChange(newValue);
                }}
                value={coach || null}
                getOptionLabel={(option: any) => {
                  return option?.full_name
                }}

                renderInput={(params: any) => (
                  <TextField
                    sx={dateInputStyle}
                    {...params}
                    variant="outlined"
                    // onChange={handleChange}
                  />
                )}
              />

              <Button variant="contained" disableElevation
                sx={{ width: "100%", mt: 3, fontSize: "2rem" }}
                disabled={!coach || isLoading}
                onClick={submitHandler}
              >
                Assign
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AssignCoachModal;
