import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useUpdateContractEndDateMutation } from "../../../services/admin";
import { calendarStyle, dateInputStyle } from "../../../styles/InviteClientCalederStyle";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "95rem",
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    px: "7.4rem",
    py: "3.4rem",
    borderRadius: "1rem",
    outline: "none",
};

interface IFormInputs {
    contractEndDate: string;
}

const schema = yup.object().shape({
    contractEndDate: yup.string().required("Date is required").nullable(),
});

const DateModal = ({ open, setOpen, user }: any) => {
    const [updateContractEndDate, { isLoading }] = useUpdateContractEndDateMutation();
    const {
        handleSubmit,
        control,
        reset,
        setValue,
        setError,
        formState: { errors },
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            contractEndDate: dayjs(user.contractEndDate).format("MM/DD/YYYY")
        },
    });

    const handleClose = () => {
        setOpen(false);
        reset();
    };

    // setting the value of the date field
    useEffect(() => {
        if (user) {
            setValue("contractEndDate", dayjs(user.contractEndDate).format("MM/DD/YYYY"))
        }
    }, [open])


    const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
        const isValid = dayjs(data.contractEndDate).isValid();
        if (!isValid) {
            setError("contractEndDate", { type: 'custom', message: 'Invalid Date' })
            return;
        }

        const payload = {
            contractEndDate: dayjs(data.contractEndDate
            ).format("MM/DD/YYYY")
        }
        await updateContractEndDate({ id: user.id, payload }).unwrap().then((res: any) => {
            handleClose()
            reset({ contractEndDate: "" })
            toast.success(res?.message)
        }).catch((error: any) => {
            handleClose()
            reset({ contractEndDate: "" })
            toast.error(error?.message)
        })
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                    onClick={handleClose}
                >
                    <Typography
                        sx={{ fontSize: "2.8rem", fontWeight: 600, color: "#333333" }}
                    >
                        Update Contract End Date
                    </Typography>
                    <IconButton>
                        <CloseIcon sx={{ fontSize: "2.6rem" }} />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        minHeight: "30rem",
                        width: "100%",
                        position: "relative",
                    }}
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{ mt: 4 }}>
                            <Typography
                                sx={{
                                    fontSize: "2.6rem",
                                    fontWeight: 400,
                                    color: "#333333",
                                    mb: "2.6rem",
                                }}
                            >
                                Contract end date
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", gap: "4rem", mt: "5rem", justifyContent: "center", alignItems: "center" }} >
                            <Box sx={{ width: "24rem", borderColor: "#2165A2" }}>
                                <Controller
                                    control={control}
                                    name="contractEndDate"
                                    defaultValue=""
                                    render={({ field: { onChange, ...restField } }) => (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label=""
                                                // value={dateValue}
                                                disablePast
                                                onChange={(event: any) => {
                                                    onChange(event);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        variant="outlined"
                                                        sx={dateInputStyle}
                                                        {...params}
                                                        error={false}
                                                    // onKeyDown={(e: any) => e.preventDefault()}
                                                    />
                                                )
                                                }
                                                PaperProps={{
                                                    sx: calendarStyle
                                                }}
                                                {...restField}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                                {errors?.contractEndDate && (
                                    <p style={{ fontSize: "10px", color: "red" }}>
                                        {errors?.contractEndDate?.message}
                                    </p>
                                )}
                            </Box>
                            <Box sx={{ borderRadius: "50%" }} >
                                <Button
                                    type="submit"
                                    disabled={isLoading}
                                    sx={{
                                        background: "#2165A2",
                                        borderRadius: "0.485rem",
                                        height: "5rem",
                                        width: "18rem",
                                        color: "#fff",
                                        fontSize: "1.8rem",
                                        textTransform: "capitalize",
                                        "&:hover": {
                                            background: "#2165A2",
                                        },
                                        "&.Mui-disabled": {
                                            opacity: 0.7,
                                            color: "#fff"
                                        }
                                    }}

                                >
                                    Update
                                </Button>
                            </Box>
                        </Box>
                        <Box>
                        </Box>
                    </form>
                </Box>
            </Box>
        </Modal>
    );
};

export default DateModal;
