import Layout from "../../components/Layout/Layout";
import { Typography, Stack, IconButton, Button } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import classes from "../../styles/CreateFormStyle";
import Form from "./Form";
import { Link } from "react-router-dom";
import { useState } from "react";
import {
  useCreateOnBoardingFormMutation,
  isFetchBaseQueryError,
} from "../../services/admin";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CreateBoardingForm = () => {
  const [questionsData, setQuestionsData] = useState<any[]>([]);
  const [title, setTitle] = useState("");
  const [isRequired, setisRequired] = useState("");

  const [createOnBoardingForm] =
    useCreateOnBoardingFormMutation();
  const navigate = useNavigate();
  const payload = {
    title: title,
    questions: questionsData,
  };
  const publishForm = async () => {
    if (title == "") {
      setisRequired("Required");
      return;
    }
    try {
      await createOnBoardingForm(payload)
        .unwrap()
        .then((res) => {
          navigate("/onboarding-forms");
          toast.success(res?.message);
        });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error?.data?.message);
      }
    }
  };
  return (
    <Layout>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap="2rem">
          <Link to="/onboarding-forms">
            <IconButton sx={classes.backArrow} disableRipple>
              <ArrowRightAltIcon />
            </IconButton>
          </Link>
          <Typography variant="h2">Create New Onboarding Form</Typography>
        </Stack>
        <Button
          variant="contained"
          sx={classes.pubBtn}
          disableElevation
          disableRipple
          disabled={questionsData.length == 0}
          onClick={publishForm}
        >
          Publish
        </Button>
      </Stack>
      <h6 className="after:!text-[red] text-3xl font-semibold mt-10">Title</h6>
      <div className="flex items-center mt-5 max-w-[90%]">
        <input
          type="text"
          className="bg-[#fff] px-10 text-[1.6rem] border border-[#D0D6DE] rounded-3xl font-medium outline-none h-24"
          placeholder="Title"
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <span style={{ color: "red" }}>{isRequired}</span>
      <Form setQuestionsData={setQuestionsData} questionsData={questionsData} />
    </Layout>
  );
};

export default CreateBoardingForm;
