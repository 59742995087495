import { Avatar, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import dayjs from 'dayjs';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  //width: '94rem',
  bgcolor: '#FFFFFF',
  // border: '2px solid #000',
  boxShadow: "none",
  borderRadius: '1rem',
  outline: 'none',
  overflow: 'hidden',

};

export default function UserDetailModal({
  openModal,
  setOpenModal,
  member,
  title,
  description,
  time,
  date
}: any) {
  const handleClose = () => setOpenModal(false);

  return (
    <div>

      <Modal open={openModal} onClose={handleClose}
        sx={{
          ".MuiModal-backdrop": {
            backgroundColor: "transparent",
          }
        }}>
        <Box sx={style}>
          <Grid
            sx={{
              maxHeight: '60rem',
              overflow: 'auto',
              pl: '4.354rem',
              pt: '5rem',
              pr: '16.189rem',
              pb: '5rem'
            }}
          >
            <Box sx={{ display: 'flex', gap: '3.2rem' }}>
              <Box>
                <Avatar
                  sx={{
                    minWidth: '9rem',
                    height: '9rem',
                    bgcolor: '#2165A2',
                    fontSize: '2.4rem',
                    overflow: 'hidden'
                  }}
                  alt={member?.full_name?.substring(0, 1)}
                  src={member?.profile_photo}
                >
                  {member?.full_name?.substring(0, 1)}
                </Avatar>
              </Box>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '1.3rem' }}
              >
                <Box className="font-bold text-[2.8rem] leading-[4.2rem]  ">
                  {member?.full_name}
                </Box>
                <Box className="font-normal text-[#ADADAD] text-[2.8rem] leading-[4.2rem]  ">
                  <h6>{member?.company}</h6>
                </Box>
                <Box className="font-medium text-[2.8rem] leading-[4.2rem]  ">
                  Email
                </Box>
                <Box className="font-normal text-[#ADADAD] text-[2.8rem] leading-[4.2rem]  ">
                  <h6>{member?.email}</h6>
                </Box>
                <Box className="font-medium text-[2.8rem] leading-[4.2rem]  ">
                  Phone
                </Box>
                <Box className="font-normal text-[#ADADAD] text-[2.8rem] leading-[4.2rem]  ">
                  <h6>{member?.phone}</h6>
                </Box>
                <Box className="font-semibold text-[2.8rem] text-[#2165A2] leading-[4.2rem]  ">
                  {title}
                </Box>
                <Box className="font-normal  text-[#ADADAD] text-[1.935rem] leading-[2.9rem]  ">
                  <p>{description}</p>
                </Box>
                <Box sx={{ display: 'flex', mt: '3rem', gap: '15.2rem' }}>
                  <Box>
                    <h1 className="text-[#333333]  font-normal text-[2.8rem] leading-[4.2rem]">
                      Date
                    </h1>
                    <h1 className="text-[#ADADAD] min-w-[17.5rem] font-normal text-[2.8rem] leading-[4.2rem]">
                      {dayjs(date).format('MMM DD, YYYY')}
                    </h1>
                  </Box>
                  <Box>
                    <h1 className="text-[#333333]  font-normal text-[2.8rem] leading-[4.2rem]">
                      Time
                    </h1>
                    <h1 className="text-[#ADADAD] min-w-[30rem]  font-normal text-[2.8rem] leading-[4.2rem]">
                      {time}
                    </h1>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
