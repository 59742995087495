import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import accountIcon from "../../assets/images/person-fill.svg";
import logOutIcon from "../../assets/images/log-in.svg";

import classes, {
  Profile,
  ProfileBox,
} from "../../styles/HeaderStyle";
import { Typography } from "@mui/material";
import iconChevron from "../../assets/images/iconChevron.svg";
import { Link } from "react-router-dom";
import SideDrawer from "./Drawer";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Logo from "../../assets/images/logonLogo.svg";
import { useDispatch } from "react-redux";
import { logoutAsync } from "../../store/reducers/auth.reducer";
import ClientProfile from "../../assets/images/profile.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useGetNotificationsCountQuery
} from "../../services/general";
import { setNotiCount } from "../../store/slices/auth.slice";
import { selectUser } from "../../store/selectors";
import { RevokeGoogleToken, RevokeOutlookToken } from "../../apis/auth-apis";
import { toast } from "react-toastify";

export default function Header() {
  const id = useSelector((state: any) => state?.auth?.user?.id);
  const notificationCount = useSelector((state: any) => state?.auth);
  const notiCount: number = notificationCount?.notiCount;
  //get Notifications Count Api
  const { data: notificationsCount } = useGetNotificationsCountQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const user = useSelector(selectUser);
  const userId = user?.id;
  React.useEffect(() => {
    dispatch(
      setNotiCount(
        notificationsCount?.data?.count ? notificationsCount?.data?.count : 0
      )
    );
  }, [dispatch, notificationsCount?.data?.count]);

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const RevokeTokenHandler = () => {
    RevokeGoogleToken(userId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((err: any) => {
        if (err && err?.response?.data) {
          toast.error(err?.response?.data?.message);
        }
      });
  };
  const RevokeOutlookTokenHandler = () => {
    RevokeOutlookToken(userId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((err: any) => {
        if (err && err?.response?.data) {
          toast.error(err?.response?.data?.message);
        }
      });
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem
        onClick={() => {
          navigate("/settings");
          handleMenuClose();
        }}
        className="!text-3xl"
      >
        Profile
      </MenuItem> */}
      <MenuItem
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
        }}
        onClick={() => {
          navigate("/settings");
          handleMenuClose();
        }}
        className="!text-3xl"
      >
        <img
          className="w-[2.4rem] h-[2.4rem]"
          src={accountIcon}
          alt="accountIcon"
        />
        My Account
      </MenuItem>
      <MenuItem
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
        }}
        onClick={RevokeTokenHandler}
        className="!text-3xl"
      >
        {/* <Link to="/">Log Out</Link> */}
        <img
          className="w-[2.4rem] h-[2.4rem]"
          src={logOutIcon}
          alt="logOutIcon"
        />
        Disconnect From Google Calendar
      </MenuItem>
      <MenuItem
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
        }}
        onClick={RevokeOutlookTokenHandler}
        className="!text-3xl"
      >
        {/* <Link to="/">Log Out</Link> */}
        <img
          className="w-[2.4rem] h-[2.4rem]"
          src={logOutIcon}
          alt="logOutIcon"
        />
        Disconnect From Outlook Calendar
      </MenuItem>
      <MenuItem
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
        }}
        onClick={() => dispatch(logoutAsync())}
        className="!text-3xl"
      >
        {/* <Link to="/">Log Out</Link> */}
        <img
          className="w-[2.4rem] h-[2.4rem]"
          src={logOutIcon}
          alt="logOutIcon"
        />
        Log Out
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleMenuClose} className="!text-3xl">
        Profile
      </MenuItem>
      <MenuItem onClick={handleMenuClose} className="!text-3xl">
        My Account
      </MenuItem>
      <MenuItem onClick={handleMenuClose} className="!text-3xl">
        <Link to="/">Log Out</Link>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar sx={classes.header}>
        <Toolbar className="justify-end">
          <SideDrawer />
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search here"
              inputProps={{ "aria-label": "search" }}
            />
          </Search> */}
          {/* <Box sx={{ flexGrow: 1 }} /> */}
          <Box
            sx={{
              display: {
                xs: "none",
                md: "flex",
                gap: "4rem",
                alignItems: "center",
              },
            }}
          >
            <Link to="/notifications">
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
                sx={{ width: "3.5rem", height: "3.5rem" }}
              >
                <Badge
                  badgeContent={notiCount}
                  color="error"
                  sx={classes.bellBadge}
                >
                  <NotificationsIcon
                    color="secondary"
                    sx={{ fontSize: "3.5rem" }}
                  />
                </Badge>
              </IconButton>
            </Link>
            <ProfileBox>
              <Profile>
                <img
                  className="h-full w-full object-cover"
                  src={
                    user?.profile_photo ? user?.profile_photo : ClientProfile
                  }
                  alt=""
                />
              </Profile>
              <Box>
                <Typography sx={classes.name}>{user?.email}</Typography>
                <Typography sx={classes.role}>{user?.full_name}</Typography>
              </Box>
              <IconButton
                aria-label="show more"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <img src={iconChevron} alt="" />
              </IconButton>
            </ProfileBox>
          </Box>
          <div className="w-80 md:hidden">
            <img src={Logo} alt="" />
          </div>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              className="!w-20 !h-20 !p-0"
            >
              <ArrowDropDownRoundedIcon className="!w-full !h-full" />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
