import { useNavigate } from "react-router-dom";
import plusIcon from "../../assets/images/plus.svg";
import Layout from "../../components/Layout/Layout";
import { useGetBannersQuery } from "../../services/admin";
import UploadedImage from "./Components/UploadedImage";


const ChangeImage = () => {
  const navigate = useNavigate();

  const { data, refetch } = useGetBannersQuery(1, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus:true,
  });

  return (
    <Layout>
      <div
        onClick={() => navigate("/upload-change-image")}
        className="mt-[7.717rem] flex justify-end"
      >
        <button className="bg-[#2165A2] flex gap-5 rounded-[1rem] w-[26.1rem] h-[5rem] text-white text-[1.80564rem] font-semibold justify-center items-center">
          <img
            className="w-[2.4rem] h-[2.4rem]"
            src={plusIcon}
            alt="plusIcon"
          />
          Upload Photo
        </button>
      </div>
      <div className="mt-[3.7rem] grid grid-cols-3 gap-[3rem]">
        {data?.data?.result.length === 0 ? <h1 className="text-center text-[#2165A2] text-[2.4rem] font-bold">
          No Image Found
        </h1> : data?.data?.result?.map((item: any, index: any) => {
          return (
            <div key={index}>
              <UploadedImage item={item} refetch={refetch} />
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export default ChangeImage;
