import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import theme from "../theme";

export const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		{...props}
	/>
))(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color:
			theme.palette.mode === "light"
				? "rgb(55, 65, 81)"
				: theme.palette.grey[300],
		boxShadow:
			"rgb(255, 255, 255) 0 0 0 0, rgba(0, 0, 0, 0.05) 0 0 0 1px, rgba(0, 0, 0, 0.1) 0 1rem 1.5rem -0.3rem, rgba(0, 0, 0, 0.05) 0 0.4rem 0.6rem -0.2rem",
		"& .MuiMenu-list": {
			padding: "4px 0",
		},
		"& .MuiMenuItem-root": {
			fontSize: "1.6rem",
			"& .MuiSvgIcon-root": {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			"&:active": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				),
			},
		},
	},
}));

const classes = {
	cardBox: {
		display: "flex",
		gap: "6.4rem",
		background: "#FFFFFF",
		borderRadius: "4.8rem",
		padding: "3.8rem 2.7rem 3.6rem 3.6rem",
		[theme.breakpoints.down("md")]: {
			padding: "2rem",
			gap: "0.5rem",
		},
	},
	imgBox: {
		maxWidth: "6rem",
		[theme.breakpoints.down("md")]: {
			minWidth: "6rem",
		},
	},
	dateTypo: {
		fontWeight: 700,
		fontSize: "1.4rem",
		lineHeight: "2.1rem",
		color: "#202020",
		whiteSpace: "nowrap",
		pr: "0.8rem"
	},
	clientBtn: {
		fontWeight: 500,
		fontSize: "1.4rem",
		lineHeight: "2.1rem",
		color: "#202020",
		textTransform: "capitalize",
		filter: "drop-shadow(0 14.8309px 22.6367px rgba(62, 53, 120, 0.14));",
		borderRadius: "0.7rem",
		border: "2px solid #202020",
		minWidth: "16rem",
		height: "4.6rem",
		justifyContent: "space-between",
		"&:hover": {
			backgroundColor: "#ffffff",
			border: "0.2rem solid #202020",
			boxShadow: "none",
			filter: "none",
		},
	},
	newUser: {
		fontStyle: "normal",
		fontWeight: 700,
		fontSize: "7.2rem",
		lineHeight: "10.8rem",
		color: "#202020",
	},
	userText: {
		fontStyle: "normal",
		fontWeight: 500,
		fontSize: "2.4rem",
		lineHeight: "3.6rem",
		color: "#A5A5A5",
	},
	iconCalendar: {
		width: "3rem",
		height: "3rem",
		position: "absolute",
		left: "6rem",
		color: "#ADADAD",
	},
	iconCalendarTo: {
		width: "3rem",
		height: "3rem",
		position: "absolute",
		left: "4rem",
		color: "#ADADAD",
	},
	iconArrow: {
		width: "3.5rem",
		height: "3.5rem",
		position: "absolute",
		right: "0",
	},
	menuIcon: {
		width: "3.5rem",
		height: "3.5rem",
	}
};

export default classes;
