import Box from '@mui/material/Box';
import * as React from 'react';
import ClientTable from './ClientTable';
import CoachTable from './CoachTable';

interface ResourcesTabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export interface ResourcesTabStyledTabProps {
    label: string;
}
function ManageUserTabsPanel(props: ResourcesTabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

const ManageUserTabs = ({ value }: any) => {
    // const { data } = useGetAllClientsQuery(1, { refetchOnMountOrArgChange: true });

    return (
        <Box>
            <ManageUserTabsPanel value={value} index={0}>
                <ClientTable />
            </ManageUserTabsPanel>
            <ManageUserTabsPanel value={value} index={1}>
                <CoachTable />
            </ManageUserTabsPanel>
        </Box>
    );
};
export default ManageUserTabs;
