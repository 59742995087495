import { Box, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Stack } from "@mui/system";
import dayjs from "dayjs";
import * as React from "react";
import WR from "../../assets/images/WR.svg";
import classes, { StyledTableCell } from "../../styles/CheckinFormStyle";
import { columns } from "../../utilities/CheckinForm";
import PositionedMenu from "./Menu";

export default function StickyHeadTable({ data, refetch }: any) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Handled Pagination
  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={classes.tableClass}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={classes.tableHead}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sx={classes.tableCell}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

              .map((row: any) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                    <StyledTableCell>
                      <Stack direction="row" gap="2rem" alignItems="center">
                        <Box sx={classes.tableName}>
                          {" "}
                          <img src={WR} alt="logo" />
                        </Box>
                        <Box sx={{ textTransform: "capitalize" }}>
                          {row?.title}
                          <Typography
                            sx={{
                              color: "#33333366",
                              fontSize: "1.6rem",
                              marginTop: "0.3rem",
                            }}
                          >
                            {
                              // row?.onboardingFormQuestions.map((item: any) => {
                              //   return (
                              "created: " +
                                dayjs(
                                  row?.onboardingFormQuestions[0]?.createdAt
                                ).format("MMM DD YYYY")
                              //   );
                              // })
                            }
                          </Typography>
                        </Box>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell>
                      {row?.onboardingFormQuestions.length}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row?.isActivated == true ? "Activated" : "Deactivated"}
                    </StyledTableCell>
                    <StyledTableCell>
                      <PositionedMenu
                        isActivated={row?.isActivated}
                        id={row?.id}
                        refetch={refetch}
                      />
                    </StyledTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          "& .MuiSvgIcon-root": {
            width: "2.5rem",
            height: "2.5rem",
          },
          "& .MuiTablePagination-displayedRows": {
            fontSize: "1.8rem",
          },
        }}
        rowsPerPageOptions={[10]}
        component="div"
        count={data?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
