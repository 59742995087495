import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton, MenuItem } from "@mui/material";
import { StyledMenu } from "../../../../styles/UsageReportCardStyle";
import CloseIcon from "../../../../assets/images/CloseIcon.svg";
import VideoIcon from "../../../../assets/images/VideoIcon.svg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import deleteIcon from "../../../../assets/images/deleteIcon.svg";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useLazyGetMeetingDetailsQuery } from "../../../../services/general";
import ConfirmModal from "./ConfirmModal";

export default function CalenderModal({
  open,
  setOpen,
  selectedEvent,
  inProgressEvent,
  refetch
}: any) {
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);

  const [getMeetingDetails, results] = useLazyGetMeetingDetailsQuery();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function handleClose() {
    setOpen(false);
  }

  function handleDelete() {
    setIsConfirmOpen(true);
  }

  const handleStartCall = async () => {
    await getMeetingDetails(selectedEvent?.meeting_id).unwrap().then((res: any) => {
      const meeting = res.data.meeting;

      if (meeting) {
        if (selectedEvent?.start_url) {
          window.open(selectedEvent?.start_url, "_blank", "noopener,noreferrer");
          setOpen(false);
        }
      }
    }).catch((err: any) => {
      toast.error(err?.data?.message);
    });
  }

  const date = selectedEvent?.occurrance_start ? selectedEvent?.occurrance_start : selectedEvent?.startTime;
  const isSame = dayjs(date).tz(selectedEvent?.timezone).isSame(dayjs().tz(selectedEvent?.timezone), "day");

  return (
    <>
      <div>
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <Box
              sx={{
                width: "2.4rem",
                height: "2.4rem",
                ml: "auto",
                cursor: "pointer",
              }}
            >
              <img
                onClick={handleClose}
                className="w-full h-full"
                src={CloseIcon}
                alt="Close Icon"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#2165A2",
                    fontSize: "4rem",
                    lineHeight: "6rem",
                    fontWeight: "500",
                  }}
                >
                  Call Details
                </Typography>
              </Box>
              <Box>
                <IconButton
                  aria-label="delete"
                  sx={{ p: 0, borderRadius: "2px" }}
                  onClick={handleClick}
                >
                  <MoreHorizIcon
                    sx={{ color: "#2165A2", fontSize: "3.2rem" }}
                  />
                </IconButton>
                <StyledMenu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    sx: {
                      width: "23rem",
                      borderRadius: "11px",
                      boxShadow: "0px 0px 29px rgba(0, 0, 0, 0.08)",
                      padding: "1rem",
                    },
                  }}
                >
                  {/* <MenuItem
                    onClick={() => {
                      handleEdit(selectedEvent?.id);
                      handleCloseMenu();
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "13.4rem" }}>
                      <Box>
                        <Typography
                          sx={{
                            color: "#293A48",
                            fontSize: "1.8rem",
                            lineHeight: "2.7rem",
                            fontWeight: "400",
                          }}
                        >
                          Edit
                        </Typography>
                      </Box>
                      <Box sx={{ width: "1.6rem", height: "1.6rem" }}>
                        <img className="w-full h-full" src={EditIcon} alt="" />
                      </Box>
                    </Box>
                  </MenuItem>
                  <Divider /> */}
                  <MenuItem
                    onClick={() => {
                      handleCloseMenu();
                      handleDelete();
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "10.7rem" }}>
                      <Box>
                        <Typography
                          sx={{
                            color: "#293A48",
                            fontSize: "1.8rem",
                            lineHeight: "2.7rem",
                            fontWeight: "400",
                          }}
                        >
                          Delete
                        </Typography>
                      </Box>
                      <Box sx={{ width: "2rem", height: "2rem" }}>
                        <img
                          className="w-full h-full"
                          src={deleteIcon}
                          alt=""
                        />
                      </Box>
                    </Box>
                  </MenuItem>
                </StyledMenu>
              </Box>
            </Box>
            <div className="mt-[2.6rem]">
              <div className="flex gap-[15.5rem] items-center">
                <h3 className="text-[#2165A2] text-[3rem] font-medium">
                  Title
                </h3>
              </div>
              <div className="flex gap-[7.3rem] items-center">
                <h6 className="text-[#333333] text-[3rem]">
                  {selectedEvent?.title}
                </h6>
              </div>
              <div className="mt-[3.3rem] flex gap-28">
                <div className="flex flex-col">
                  <h3 className="text-[#2165A2] text-[3rem] font-medium">Date</h3>
                  <h6 className="text-[#333333] text-[3rem]">
                    {dayjs(selectedEvent?.date).format("MM-DD-YYYY")}
                  </h6>
                </div>
                <div className="flex flex-col">
                  <h3 className="text-[#2165A2] text-[3rem] font-medium">Time</h3>
                  <h6 className="text-[#333333] text-[3rem]">
                    {dayjs(selectedEvent?.date).format("hh:mm A")}
                  </h6>
                </div>
                <div className="flex flex-col">
                  <h3 className="text-[#2165A2] text-[3rem] font-medium">Duration</h3>
                  <h6 className="text-[#333333] text-[3rem]">
                    {selectedEvent?.duration} min
                  </h6>
                </div>
              </div>

              <div className="flex gap-[8.9rem] items-center mt-[3.3rem]">
                <h3 className="text-[#2165A2] text-[3rem] font-medium">
                  Recurring
                </h3>
              </div>
              <div className="flex gap-[17.2rem] items-baseline mt-2">
                <h6 className="text-[#333333] text-[3rem]">
                  {selectedEvent?.isRecurring ? "Yes" : "No"}
                </h6>
              </div>
              {!inProgressEvent && (
                <div className="flex justify-end">
                  {(selectedEvent?.meeting_type === 2) ? (
                    <button
                      disabled={results?.isFetching}
                      onClick={handleStartCall}
                      className="px-10 h-[4.5rem] bg-[#2165A2] text-[1.4rem] text-[#FFFFFF] font-medium flex justify-center items-center rounded-[1.12978rem] gap-3"
                    >
                      <img
                        src={VideoIcon}
                        className="w-[24.38px] h-[15.44px]"
                        alt="Video Icon"
                      />
                      Start call
                    </button>
                  ) : (
                    <button
                      disabled={!isSame || results?.isFetching}
                      onClick={handleStartCall}
                      className={`px-10 h-[4.5rem] ${isSame ? "bg-[#2165A2]" : "bg-gray-300"}  text-[1.4rem] text-[#FFFFFF] font-medium flex justify-center items-center rounded-[1.12978rem] gap-3`}
                    >
                      <img
                        src={VideoIcon}
                        className="w-[24.38px] h-[15.44px]"
                        alt="Video Icon"
                      />
                      {isSame ? "Start Call" : "Not Available"}
                    </button>
                  )}
                </div>
              )}


            </div>
          </Box>
        </Modal>

        <ConfirmModal
          selectedEvent={selectedEvent}
          isConfirmOpen={isConfirmOpen}
          setIsConfirmOpen={setIsConfirmOpen}
          setOpen={setOpen}
          refetch={refetch}
        />
      </div>
    </>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "88rem",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: "2.4rem",
  pr: "3.3rem",
  pb: "3.2rem",
  pl: "5.1rem",
  borderRadius: "1.6rem",
  overflow: "hidden",
};
