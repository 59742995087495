import { Grid, Paper, Typography } from "@mui/material";
import { useGetPodcastsQuery } from "../../../../services/general";
import PodcastCard from "./PodcastCard";

const Podcast = () => {
    const { data } = useGetPodcastsQuery(1);
    const podcasts = data?.data?.podcasts;

    return (
        <>
            {podcasts?.length === 0 && (
                <Paper sx={{ mt: "2rem", px: "2rem", py: "6rem", textAlign: "center" }}>
                    <Typography sx={{ fontSize: "2.8rem", fontWeight: 600, color: "#2165A2" }}>No Podcasts Found</Typography>
                </Paper>
            )}

            <Grid container spacing="2rem" marginTop="0rem">
                {podcasts?.map((item: any, index: number) => {
                    return (

                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <PodcastCard
                                id={index}
                                title={item.title}
                                desc={item.description}
                                date={item.pubDate}
                                duration={item.duration}
                                thumbnail={item.imageURL}
                                season={item.season}
                                audio={item.audioURL}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </>

    )
};

export default Podcast;