import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Box, Button, Divider, FormHelperText, Modal, Stack, TextField, Typography, } from "@mui/material";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useGetAllCoachesQuery } from "../../../services/admin";
import { useDeleteCoachMutation } from "../../../services/general";
import { dateInputStyle } from "../../../styles/InviteClientCalederStyle";

interface IFormInputs {
  coach: string;
}

const schema = yup.object().shape({
  coach: yup.string(),
});

const DeleteCoachModal = ({ open, setOpen, user, length, refetchAction }: any) => {
  const { data, refetch } = useGetAllCoachesQuery(1, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
  const [deleteCoach, { isLoading }] = useDeleteCoachMutation();
  const [selected, setSelected] = useState<any>("")

  const handleClose = () => {
    setOpen(false);
    reset();
    setSelected("");
  };
  const coaches = data?.data?.users?.filter((coach: any) => coach?.id !== user?.id);
  const message = length == 1 ? "has" : "have";
  const clientMessage = length == 1 ? "Client" : "Clients";
  const {
    handleSubmit,
    control,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  if (length === 1) {
    schema.fields.coach = schema.fields.coach.required();
  } else {
    schema.fields.coach = schema.fields.coach.notRequired();
  }

  const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {

    const payload = {
      coachId: data.coach
    };
    
    await deleteCoach({ id: user.id, payload: payload }).unwrap().then(() => {
      refetch();
      refetchAction();
      toast.success("Coach Deleted Successfully");
      handleClose();
    });
  }
  
  const handleSelectChange = (value: any) => {
    if (value !== null || undefined) {
      setSelected(value.id);
      const id = value.id;
      setValue("coach", id);
    } else {
      setValue("coach", "Select Coach");
      setSelected("");
    }
    setError("coach", { type: "focus" }, { shouldFocus: true });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography id="modal-modal-title" variant="h3" textAlign="center">
            Are you sure?
          </Typography>
          <Typography id="modal-modal-description" variant="h5" textAlign="center" sx={{ mt: 2 }}>
            All the data will be lost when deleting this Coach!
          </Typography>
          {(user?.clients?.length > 0) && (
            <Box>
              <Divider sx={{ marginTop: 1 }} />
              <Stack spacing={1} direction="row" alignItems="center" mt={1} justifyContent="center">
                <Typography variant="h6" >This Coach {message} </Typography>
                <Typography variant="h5" color="error">{user?.clients?.length} {clientMessage}.</Typography>
              </Stack>
              <Typography variant="h6" mb={1} textAlign="center">Assign a new coach to these clients to delete.</Typography>
              <Box>
                <Controller
                  control={control}
                  name="coach"

                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      freeSolo
                      options={coaches || []}
                      placeholder="select coach"
                      onChange={(_, values) => handleSelectChange(values)}
                      value={value}
                      getOptionLabel={(option: any) => {
                        return option.full_name
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          sx={dateInputStyle}
                          {...params}
                          variant="outlined"
                          onChange={onChange}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.stopPropagation();
                              e.preventDefault();
                              return;
                            }
                          }}
                        />
                      )}
                    />
                  )}
                />
                {errors?.coach && (
                  <FormHelperText
                    sx={{
                      fontSize: "1.4rem",
                      color: "#d32f2f",
                      ml: "1.4rem",
                    }}
                  >
                    {errors?.coach?.message ? "Please select a Coach" : null}
                  </FormHelperText>
                )}
              </Box>
            </Box>
          )}
          <Stack spacing={1} direction="row" justifyContent="center" mt={2}>
            <Button onClick={handleClose} variant="outlined" size="large" sx={{ fontSize: "1.4rem", fontWeight: 600 }}>No, Cancel</Button>
            <Button type="submit" disabled={isLoading || (user?.clients?.length > 0 && !selected)} variant="contained" size="large" color="error" sx={{ fontSize: "1.4rem", fontWeight: 600 }}>{isLoading ? "Loading..." : "Yes, Delete"}</Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  )
};

export default DeleteCoachModal;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};