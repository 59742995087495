import { useEffect } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import classes from "../../../styles/LogonStyle";
import ProfilePhoto from "../../../assets/images/profile.png";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetUserByIdQuery } from "../../../services/admin";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useUpdateUserByIdMutation, isFetchBaseQueryError } from "../../../services/admin";

const schema = yup.object().shape({
  fullName: yup.string().required("Required"),
  email: yup.string().required("Required"),
});

const EditUser = ({navigate}:any) => {
  const { id } = useParams();
  const { data, isFetching } = useGetUserByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const [updateUserById] = useUpdateUserByIdMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("fullName", data?.data?.user?.full_name);
    setValue("email", data?.data?.user?.email);
    setValue("role", data?.data?.user?.role.join(" | "));
  }, [isFetching]);

  const onSubmit = async (data: any) => {
    const payload = {
      fullName: data.fullName
    };

    try {
      await updateUserById({ id, payload })
        .unwrap()
        .then(() => {
          navigate(-1)
          toast.success("User Updated Successfully");
        });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error.data.message);
      }
    }
  };

  return (
    <Box sx={classes.editUserMain}>
      <Box
        component="img"
        sx={{
          width: "18rem",
          height: "18rem",
          borderRadius: "50%",
          ml: "4rem",
          m: "0 auto"
        }}
        src={
          data?.data?.user?.profile_photo
            ? data?.data?.user?.profile_photo
            : ProfilePhoto
        }
      />
      <form>
        <Grid
          container
          rowSpacing="1rem"
          columnSpacing="3rem"
          sx={{ maxWidth: "70rem", margin: "0 auto" }}
        >
          <Grid item xs={6} sx={classes.fromWrapper}>
            <Box component="div" sx={classes.form_blk}>
              <Typography
                variant="h6"
                sx={classes.formlabel}
                className="require"
              >
                First Name
              </Typography>
              <Controller
                name="fullName"
                control={control}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    sx={classes.logonTextField}
                    inputProps={{ maxLength: 20 }}
                    variant="outlined"
                    type="text"
                    fullWidth
                    error={!!errors.fullName}
                    helperText={
                      errors.fullName ? errors.fullName?.message : null
                    }
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={6} sx={classes.fromWrapper}>
            <Box component="div" sx={classes.form_blk}>
              <Typography
                variant="h6"
                sx={classes.formlabel}
                className="require"
              >
                Email
              </Typography>
              <Controller
                name="email"
                control={control}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    disabled
                    sx={classes.logonTextField}
                    variant="outlined"
                    type="text"
                    fullWidth
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sx={classes.fromWrapper}>
            <Box component="div" sx={classes.form_blk}>
              <Typography
                variant="h6"
                sx={classes.formlabel}
                className="require"
              >
                Role
              </Typography>
              <Controller
                name="role"
                control={control}
                render={({ field }: any) => (
                  <TextField
                    disabled
                    {...field}
                    sx={classes.logonTextField}
                    variant="outlined"
                    type="text"
                    fullWidth
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              sx={classes.createFormBtn}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default EditUser;
