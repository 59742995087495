import { Box } from "@mui/material";
import LogonSidebar from "../../components/LogonSidebar";
import classes from "../../styles/LogonStyle";
import Form from "./Form";

const OtpVerification = () => {
	return (
		<Box component="section" sx={classes.Logon}>
			<Form />
			<LogonSidebar />
		</Box>
	);
};

export default OtpVerification;
