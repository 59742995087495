import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ReactPlayer from 'react-player'


export default function AudioModal({ open, setOpen, videoUrl, title, description }: any) {
    const handleClose = () => {
        setOpen(false)
    };


    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Box sx={{ mb: "5rem" }}>
                        <ReactPlayer
                            url={videoUrl}
                            height="50px"
                            muted
                            width={"100%"}
                            playing={false}
                            controls={true}
                        />
                    </Box>

                    <Box>
                        <Typography sx={{
                            fontSize: "3rem",
                            fontWeight: 600,
                            color: "#2165A2",
                            mt: 2
                        }}>
                            {title}
                        </Typography>
                        <Typography sx={{
                            fontSize: "2.2rem",
                            fontWeight: 500,
                            color: "#333333",
                            mt: 0,
                            overflow: "hidden",

                        }}>
                            {description}
                        </Typography>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 780,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "1rem",

};