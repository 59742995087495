import React from "react";
import Layout from "../../components/Layout/Layout";
import { AntTab, AntTabs } from "../../styles/ManageUserStyle";
import { Box } from "@mui/material";
import CoachNotes from "./components/CoachNotes";
import ClientNotes from "./components/ClientNotes";
import classes from "../../styles/CheckinFormStyle";

interface NotesPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function NotesTabsPanel(props: NotesPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

const Notes = () => {
  const [value, setValue] = React.useState<number>(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Layout>
      <Box sx={classes.antTabBox}>
        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <AntTab label="Client's Notes" />
          <AntTab label="Coach's Notes" />
        </AntTabs>
      </Box>
      <NotesTabsPanel value={value} index={0}>
        <ClientNotes />
      </NotesTabsPanel>
      <NotesTabsPanel value={value} index={1}>
        <CoachNotes />
      </NotesTabsPanel>
    </Layout>
  );
};

export default Notes;
