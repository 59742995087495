import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import WinRate_Logo from "../assets/images/WinRate_Logo.svg";
import classes from "../styles/UsageReportCardStyle";
import { calendarStyle, dateInputStyle } from "../styles/UsageReportsStyles";
import numberWithCommas from "../utilities/commafunction";

const StatCard = ({ title, total, queryFunction }: any) => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    useEffect(() => {
        queryFunction({ start_date: dayjs(startDate).format("YYYY-MM-DD"), end_date: dayjs(endDate).format("YYYY-MM-DD") })
    }, [startDate, endDate])


    function handleStartDate(date: any) {
        setStartDate(date)
    }

    function handleEndDate(date: any) {
        setEndDate(date)
    }
    return (
        <>
            <Grid item xs={12} sm={6} key={title}>
                <Box sx={classes.cardBox}>
                    <Box sx={classes.imgBox}>
                        <img src={WinRate_Logo} alt="WinRate" />
                    </Box>
                    <Box flex={1}>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            marginBottom="1.6rem"
                            gap="2rem"
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                position="relative"
                            >
                                
                                <Typography
                                    variant="h6"
                                    sx={classes.dateTypo}
                                >
                                    From
                                </Typography>

                                <Box sx={{ width: "15.9rem" }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label=""
                                            value={startDate}
                                            onChange={handleStartDate}
                                            disableFuture
                                            renderInput={(params) => (
                                                <TextField
                                                    variant="outlined"
                                                    sx={dateInputStyle}
                                                    {...params}
                                                    onKeyDown={(e:any) => e.preventDefault()}
                                                />
                                            )
                                            }
                                            PaperProps={{
                                                sx: calendarStyle
                                            }}

                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Stack>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                position="relative"
                            >
                                
                                <Typography
                                    variant="h6"
                                    sx={classes.dateTypo}
                                >
                                    To
                                </Typography>
                                <Box sx={{ width: "15.9rem" }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label=""
                                            value={endDate}
                                            onChange={handleEndDate}
                                            disableFuture
                                            minDate={startDate}
                                            renderInput={(params) => (
                                                <TextField
                                                    variant="outlined"
                                                    sx={dateInputStyle}
                                                    {...params}
                                                    onKeyDown={(e:any) => e.preventDefault()}
                                                />
                                            )
                                            }
                                            PaperProps={{
                                                sx: calendarStyle
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Stack>
                        </Stack>
                        
                        <Typography sx={classes.newUser}>
                            {numberWithCommas(total)}
                        </Typography>
                        <Typography sx={classes.userText}>
                            {title}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </>
    )
}

export default StatCard;