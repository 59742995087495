import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useState } from "react";
import CalenderModal from "./Modal";
import dayjs from "dayjs";
// and, for optional time zone support
import timezone from "dayjs/plugin/timezone";
import moment from "moment";
dayjs.extend(timezone);

const localizer = momentLocalizer(moment);
// const localizer = dayjsLocalizer(dayjs);

const MyCalendar = ({
  myEventsList,
  deleteScheduleCall,
  date,
  handleNavigate,
  inProgressEvent,
  refetch
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const OnSelectEvent = (event: any) => {
    setIsOpen(true);
    setSelectedEvent(event);
  };


  return (
    <>
      <CalenderModal
        open={isOpen}
        selectedEvent={selectedEvent}
        setOpen={setIsOpen}
        deleteScheduleCall={deleteScheduleCall}
        inProgressEvent={inProgressEvent}
        refetch={refetch}
      />
      <Calendar
        defaultView={Views.MONTH}
        views={['month']}
        localizer={localizer}
        toolbar={false}
        showAllEvents={true}
        events={myEventsList || []}
        onSelectEvent={OnSelectEvent}
        startAccessor="start"
        endAccessor="end"
        date={date}
        onNavigate={handleNavigate}
        style={{ height: 700 }}
        components={{
          month: {
            event: (props) => (
              <div>
                {
                  <>
                    <h6>{dayjs(props?.event?.date).format("MM/DD/YYYY")}</h6>
                    <h6>{dayjs(props?.event?.date).format("hh:mm A")}</h6>
                  </>
                }
              </div>
            ),
          },
        }}
      />
    </>
  );
};
export default MyCalendar;
