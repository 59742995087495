import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import { useDeleteGoogleScheduleCallMutation } from "../../../../services/admin";

export default function ConfirmModal({
  isConfirmOpen,
  setIsConfirmOpen,
  selectedEvent,
  setOpen,
  refetch
}: any) {
  const [deleteGoogleScheduleCall] = useDeleteGoogleScheduleCallMutation();

  function handleClose() {
    setIsConfirmOpen(false);
  }

  const handleDelete = (type: any) => {
    const payload = {
      type: type,
      recurrenceId: selectedEvent?.recurrenceId,
      occurrence_id: selectedEvent?.occurrence_id,
      id: selectedEvent?.id
    };

    deleteGoogleScheduleCall(payload)
      .unwrap()
      .then((res: any) => {
        toast.success(res?.message);
        handleClose();
        setOpen(false);
        refetch();
      })
      .catch((error: any) => {
        toast.error(error?.data?.message);
        handleClose();
        setOpen(false);
      });
  };

  return (
    <>
      <div>
        <Modal open={isConfirmOpen}>
          <Box sx={style}>
            <div>
              <div className="flex justify-center">
                <div className="bg-white rounded-[1.6rem] px-12 py-12 flex gap-32">
                  <div className="flex justify-between">
                    <div className="w-full">
                      <h3 className="text-primary_blue text-6xl font-medium text-center mb-6">
                        {selectedEvent?.isRecurring ? "Delete Recurring Meeting" : "Delete Meeting"}
                      </h3>

                      <p className="text-slate-800 text-4xl font-medium text-center mb-8">
                        Are you sure you want to delete this meeting?
                      </p>

                      <div className={"flex justify-center gap-6"}>
                        {selectedEvent?.isRecurring ? (
                          <>
                            <button
                              onClick={() => handleDelete("single")}
                              className={`bg-[#dc2626] text-white px-7 py-3 text-3xl font-medium rounded-xl border border-[#dc2626] disabled:bg-opacity-50 disabled:text-opacity-50`}
                            >
                              Delete This Occurrence
                            </button>
                            <button
                              onClick={() => handleDelete("multiple")}
                              className={`bg-[#e2e8f0] disabled:bg-opacity-50 disabled:text-opacity-50 text-[#0f172a] px-7 py-3 text-3xl font-medium rounded-xl border border-[#cbd5e1]`}
                            >
                              Delete All Occurrences
                            </button>
                          </>
                        ) : (
                          <button
                            onClick={() => handleDelete("multiple")}
                            className={`bg-[#dc2626] text-white px-7 py-3 text-3xl font-medium rounded-xl border border-[#dc2626] disabled:bg-opacity-50 disabled:text-opacity-50`}
                          >
                            Delete
                          </button>
                        )}
                        <button
                          onClick={handleClose}
                          className={`bg-[#e2e8f0] disabled:bg-opacity-50 disabled:text-opacity-50 text-[#0f172a] px-7 py-3 text-3xl font-medium rounded-xl border border-[#cbd5e1]`}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "82rem",
  bgcolor: "background.paper",
  boxShadow: 24,
  px: "2.4rem",
  py: "3rem",
  borderRadius: "1.6rem",
  overflow: "hidden",
};
