import { yupResolver } from "@hookform/resolvers/yup";
import TocIcon from "@mui/icons-material/Toc";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import classes1 from "../../../styles/CreateFormStyle";
import CheckInFormSelect from "./CheckinFormSelect";

const classes = {
  pubBtn: {
    minWidth: "20.4rem",
    fontWeight: 600,
    fontSize: "1.8rem",
    lineHeight: "2.7rem",
    textTransform: "capitalize",
    borderRadius: "1rem",
  },
};

const schema = yup.object().shape({
  question: yup.string().required("qustion is required"),
  label: yup.string().required("label is required"),
  type: yup.string().required("type is required"),
});

const Question1 = ({
  setQuestionsData,
  questionsData,
  index,
  questionNum
}: any) => {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });
  const [isLoading, setisLoading]: any = useState(false);

  useEffect(() => {
    const record = questionsData?.find((question: any) => question.questionNum === questionNum);
    
    if (record) {
      setValue("question", record?.question);
      setValue("label", record?.label);
      setValue("type", record?.type);
    }
  }, [questionsData]);

  const onSubmit = (data: any) => {
    setisLoading(true);
    const record = questionsData?.find((question: any) => question.questionNum === questionNum);

    const newQuestion = {
      ...record,
      question: data.question,
      label: data.label,
      type: data.type
    };

    if(record){
      const temp = questionsData?.map((item:any) => {
        if(item.questionNum === questionNum){
          return newQuestion;
        }else {
          return item;
        }
      });
      
      setQuestionsData([...temp]);
      setisLoading(false);
    }
    
    // if(record){
    //   const records = questionsData?.filter((question: any) => question.questionNum !== record.questionNum);
    //   const newQuestion = {
    //     ...record,
    //     question: data.question,
    //     label: data.label,
    //     type: data.type
    //   };

    //   setQuestionsData([...records, newQuestion]);
    //   setisLoading(false);
    // }
  };

  const record = questionsData?.find((question: any) => question.questionNum === questionNum);
  
  return (
    <div className="px-8 pt-16">
      <form>
        <div className="flex justify-between">
          <h6 className="require after:!text-[red] text-3xl font-semibold">
            Question
          </h6>
          <div>
            <Button
              disableElevation
              disableRipple
              startIcon={<TocIcon />}
              variant="contained"
              disabled
              sx={{
                ...classes1.listIconBtn,
                "&.Mui-disabled": {
                  color: '#fff',
                  backgroundColor: !record?.question ? '#2165A2' : '#2165A2'
                }
              }}
            >
              {index + 1}
            </Button>
          </div>
        </div>
        <div>
          <div className="flex items-center mt-5 max-w-[90%]">
            <input
              type="text"
              className="bg-[#A2A3AC0A] px-10 text-[1.6rem] border border-[#D0D6DE] rounded-3xl font-medium outline-none h-24 w-full"
              placeholder="Question"
              {...register("question")}
            />
          </div>
          <span style={{ color: "red" }}>
            {errors?.question ? "Question is required" : null}
          </span>
        </div>
        <div className="mt-8">
          <h6 className="require after:!text-[red] text-3xl font-semibold">
            Label
          </h6>
          <div className="flex items-center mt-5 max-w-[90%]">
            <input
              type="text"
              className="bg-[#A2A3AC0A] px-10 text-[1.6rem] border border-[#D0D6DE] rounded-3xl font-medium outline-none h-24 w-full"
              placeholder="Label"
              {...register("label")}
            />
          </div>
          <span style={{ color: "red" }}>
            {errors.label ? "Label is required" : null}
          </span>
        </div>
        <div className="mt-8">
          <div className="flex items-center mt-5 max-w-[90%]">
            <CheckInFormSelect register={register} defaultValue={record?.type}/>
          </div>
          <span style={{ color: "red" }}>
            {errors.label ? "type is required" : null}
          </span>
        </div>
        <div className="mt-8">
          <Button
            variant="contained"
            sx={classes.pubBtn}
            disableElevation
            disabled={isLoading}
            disableRipple
            onClick={handleSubmit(onSubmit)}
          >
            {isLoading ? "Saving..." : "save"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Question1;
