import { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatepicker.css";
import { ReactDatePickerProps } from "react-datepicker";
import { IconButton, Stack } from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const CustomDatepicker = (props: ReactDatePickerProps) => {
  const datepickerRef = useRef<DatePicker>(null);

  const openCalendar = () => {
    datepickerRef.current?.setOpen(true);
  };
  return (
    <>
      <Stack flexDirection="row" sx={{ bgcolor: "#fff", alignItems: "center", borderRadius: "1rem", overflow: "hidden" }}>
        <DatePicker {...props} ref={datepickerRef} />
        <IconButton sx={{ marginRight: "1.4rem" }} onClick={openCalendar} >
          <CalendarMonthIcon sx={{ fontSize: "2.4rem" }} />
        </IconButton>
      </Stack>
      <input
        type="hidden"
        onClick={openCalendar}
        value={props?.selected ? props?.selected.toDateString() : ""}
        readOnly
      />
    </>
  )
}

export default CustomDatepicker;