import { useState } from "react";
import TimeLineTabs from "../components/TimeLineTabs";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import apiEndpoint from "../../../config/environment";
import dayjs from "dayjs";
import { useGetClientCheckInHistoryQuery } from "../../../services/admin";

const CheckInWrapper = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { data } = useGetClientCheckInHistoryQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const answers = data?.data;
  const userTimezone = dayjs.tz.guess();
  
  function getPDF() {
    return axios.get(`${apiEndpoint}/admin/checkinform/${id}/pdf-download?timezone=${userTimezone}`, {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/pdf'
      }
    })
  }

  const date = dayjs().format("MM_DD_YYYY_hh_mm");
  const fileName = `CheckIn_${date}`

  const handleDownload = async () => {
    setLoading(true);
    getPDF() // API call
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName;
        link.click()
        toast.success("PDF Downloaded Successfully")
        setLoading(false);
      })
      .catch((err) => {
        console.log("ERROR --->", err)
        toast.error("Error Generating PDF");
        setLoading(false);
      })
  }

  return (
    <div>
      {(answers?.length > 0) ? (
        <>
          <div>
            <TimeLineTabs answers={answers} loading={loading} handleDownload={handleDownload} />
          </div>
        </>
      ) : (
        <div className="bg-[#fff] px-5 py-10 rounded-lg mt-[5rem]">
          <p className="text-5xl text-center text-primary_blue">No Check-In Form History Found</p>
        </div>
      )
      }
    </div>
  );
};

export default CheckInWrapper;
