import theme from "../theme";

const classes = {
    backButton: {
        background: "transparent",
        textTransform: "capitalize",
        width: "58rem",
        height: "6rem",
        fontSize: "2rem",
        fontWeight: "400",
        color: "#2B2E34",
        border: "0.1rem",
        borderStyle: "solid",
        borderRadius: "1rem",
        borderColor: "#A8A8A8",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "transparent",
        },
    },
    postButton: {
        background: theme.palette.primary.main,
        textTransform: "capitalize",
        width: "58rem",
        height: "6rem",
        fontSize: "2rem",
        fontWeight: "500",
        color: "white",
        borderRadius: "1rem",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            cursor: "pointer"
        }
    },
    noteButton: {
        background: theme.palette.primary.main,
        textTransform: "capitalize",
        width: "58rem",
        height: "6.65rem",
        fontSize: "2rem",
        fontWeight: "500",
        color: "white",
        borderRadius: "1rem",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            cursor: "pointer"
        }
    },
    questionTextField: {
        "& .MuiInputBase-input": {
            fontWeight: 500,
            fontSize: "2rem",
            lineHeight: "3rem",
            color: "black",
            padding: "2.2rem 2.2rem 0rem 2.2rem",
            backgroundColor: "#FFFFFF",
            borderRadius: "2rem"
        },
        ".MuiInputBase-multiline": {
            padding: "0"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "0.1rem solid #DADADA",
            borderRadius: "1.1rem",
            overflow: "hidden"
        },
        "& .MuiFormHelperText-root.Mui-error": {
            color: "error",
            fontSize: "1.75rem",
        },
    },
    textField: {
        "& .MuiInputBase-input": {
            fontWeight: 400,
            width: "100%",
            fontSize: "2.2rem",
            color: "black",
            padding: "1.6rem 1.2rem",
            backgroundColor: "#FFFFFF",
            borderRadius: "1.4rem"
        },
        ".MuiInputBase-multiline": {
            padding: "0"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "0.1rem solid #DADADA",
            borderRadius: "1.1rem",
            overflow: "hidden"
        },
        "& .MuiFormHelperText-root.Mui-error": {
            color: "error",
            fontSize: "1.75rem",
        },
    },

}


export default classes;
