import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Checkbox, FormControl, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import ProgressBar from "../../components/ProgressBar";
import apiEndpoint from "../../config/environment";
import { isFetchBaseQueryError, useCreateHuddleCallMutation, useGetAllClientsQuery, useGetAllCoachesQuery } from "../../services/admin";
import InputField from "./Components/InputField";
import UploadBox from "./Components/UploadBox";

const StyledFormControl = styled(FormControlLabel)<FormControlLabelProps>(
  ({ theme }) => ({
    "& .MuiTypography-root": {
      fontSize: "3.5rem",
      color: theme.palette.secondary.main,
      fontWeight: 400,
    },
    "& .MuiSvgIcon-root": {
      width: "2.5rem",
      height: "2.5rem",
    },
  })
);

const selectItemStyle = {
  fontSize: "1.6rem",
  fontWeight: 400,
  lineHeight: "24px",
  color: "#333333",
  "&.Mui-selected": {
    backgroundColor: "rgb(33 101 162 / 44%)"
  }
};

interface IFormInputs {
  image: string;
  video: string;
  title: string;
  detail: string;
  receiverId: string;

}

const schema = yup.object().shape({
  image: yup.string().required("Required"),
  video: yup.string().required("Required"),
  title: yup.string().required("Required"),
  detail: yup.string().required("Required"),
  receiverId: yup.string()
});

const UploadSingleCall = (props: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isTrue, setIsTrue] = useState(true);
  const { data: clientData } = useGetAllClientsQuery(1, { refetchOnMountOrArgChange: true });
  const { data: coachData } = useGetAllCoachesQuery(1, { refetchOnMountOrArgChange: true });
  const [createHuddleCall, { isLoading }] = useCreateHuddleCallMutation();
  const dropData = isTrue ? coachData?.data?.users : clientData?.data?.clients;
  const [progress, setProgress] = useState(0)
  const [progressVideo, setProgressVideo] = useState(0)
  const [fileName, setFileName] = useState("")
  const [videoName, setVideoName] = useState("")
  const [selected, setSelected] = useState("")

  if (props?.callType !== "huddle") {
    schema.fields.receiverId = schema.fields.receiverId.required('Required');
  } else {
    schema.fields.receiverId = schema.fields.receiverId.notRequired();
  }

  const [image, setImage] = useState({
    name: "",
    file: {},
    isUploading: false,
    url: ""
  });

  const [video, setVideo] = useState({
    name: "",
    file: {},
    isUploading: false,
    url: ""
  });

  const {
    handleSubmit,
    register,
    control,
    setValue,
    trigger,
    reset,
    setError,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
    const payload = {
      callType: props.callType,
      title: data.title,
      thumbnail: data.image,
      file: data.video,
      detail: data.detail,
      receiverId: data.receiverId
    };

    try {
      await createHuddleCall(payload).unwrap().then((res: any) => {
        toast.success(res?.message)
        navigate("/resources-library")
      });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error?.data?.message);
      }
    }
  };

  const handleUploadImage = async (e: any) => {
    setProgress(0)
    setFileName("")
    if (!e.target.files) {
      return;
    }
    const file = e.target?.files[0];
    setImage({
      ...image,
      name: file.name,
      file: file,
      isUploading: true
    });
    setFileName(file?.name)

    const formData = new FormData();
    formData.append("image", file);

    const promise = axios.request({
      method: "POST",
      url: `${apiEndpoint}/upload-one`,
      data: formData
    }).then((res) => {
      setProgress(100)
      setImage({
        ...image,
        isUploading: false,
        url: res.data
      });
      setValue("image", res.data);
      trigger("image");
    }).catch(() => {
      setProgress(0)
      setImage({
        ...image,
        isUploading: false
      });
    });

    await toast.promise(promise, {
      pending: "Uploading Thumbnail",
      success: "Thumbnail Uploaded",
      error: "Error Uploading Thumbnail",
    });
  };

  const handleUploadVideo = async (e: any) => {
    setProgressVideo(0)
    setVideoName("")
    if (!e.target.files) {
      return;
    }
    const file = e.target?.files[0];
    setVideo({
      ...video,
      name: file.name,
      file: file,
      isUploading: true
    });
    setVideoName(file?.name)
    const formData = new FormData();
    formData.append("video", file);

    const promise = axios.request({
      method: "POST",
      url: `${apiEndpoint}/upload-video`,
      data: formData
    }).then((res) => {
      setProgressVideo(100)
      setVideo({
        ...video,
        isUploading: false,
        url: res.data
      });
      setValue("video", res.data);
      trigger("video");
    }).catch(() => {
      setProgressVideo(0)
      setVideo({
        ...video,
        isUploading: false
      });
    });

    await toast.promise(promise, {
      pending: "Uploading Video",
      success: "Video Uploaded",
      error: "Error Uploading Video",
    });

  };
  function handleChange() {
    reset({
      detail: "",
      image: "",
      video: "",
      title: "",
      receiverId: ""

    })
    setSelected("")
    setProgressVideo(0)
    setVideoName("")
    setProgress(0)
    setFileName("")
    setImage({
      name: "",
      file: {},
      isUploading: false,
      url: ""
    })
    setVideo({
      name: "",
      file: {},
      isUploading: false,
      url: ""
    })

    setIsTrue(!isTrue)
  }

  const handleSelectChange = (event: any) => {
    setSelected(event.target.value)
    if (event.target.value?.id) {
      setValue("receiverId", event.target.value?.id)
    }
    setError("receiverId", { type: "focus" }, { shouldFocus: true });
  };


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ py: "2.2rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "3.8rem",
            mb: "6.8rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "3.5rem",
              color: theme.palette.secondary.main,
              fontWeight: 600,
              textTransform: "capitalize"
            }}
          >
            {props?.callType !== "huddle" ? "Upload Call For" : "Upload Huddle"}
          </Typography>
          {props?.callType !== "huddle" ? (
            <Box>
              <StyledFormControl
                name="check"
                value="coach"
                checked={isTrue}
                control={<Checkbox />}
                label="Coach"
                onChange={handleChange}
              />
              <StyledFormControl
                name="check"
                value="client"
                control={<Checkbox />}
                label="Client"
                checked={!isTrue}
                onChange={handleChange}
              />
            </Box>
          ) : ""}

        </Box>

        <Box sx={{
          display: "flex", justifyContent: "space-between",
          flexWrap: "wrap", mb: "4.7rem",
          [theme.breakpoints.down("md")]: { gap: "3rem", },
          maxWidth: "75rem"
        }}>
          <Box sx={{ width: props?.callType === "huddle" ? "100%" : "inherit" }}>
            <Typography sx={{ fontSize: "2rem", fontWeight: 400, mb: "0rem" }}>
              Enter Call Title:
            </Typography>
            <InputField name="title" register={register} placeholder="Enter Title" fullWidth={props?.callType === "huddle"} />
            {errors?.title?.message ? (
              <p className="text-red-500 "> {errors.title.message} </p>
            ) : null}
          </Box>
          {props?.callType !== "huddle" ? (
            <Box>
              <FormControl sx={{ width: "32rem", "& .MuiInputBase-root": { height: "5.7rem" } }}>
                <Typography sx={{ fontSize: "2rem", fontWeight: 400, mb: "1.2rem" }}>
                  Select {isTrue ? "Coach" : "Client"}:
                </Typography>

                <Select
                  fullWidth
                  value={selected}
                  sx={{
                    fontSize: '2rem',
                    fontWeight: 400,
                    boxShadow: "0px 1px 4px rgb(0 0 0 / 10%)",
                    borderColor: '#2165A2',
                    backgroundColor: '#fcfcfb',
                    borderRadius: "7px",
                    py: "0rem",
                    px: "1.2rem",
                    "& .MuiSelect-icon": {
                      width: "3rem",
                      height: "3rem",
                      top: "calc(50% - 1.5rem)"
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: '1.8rem',
                    },
                    "& .MuiSelect-select": {
                      py: "1.8rem"
                    }
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        "& .MuiMenuItem-root": {
                          fontSize: "1.8rem"
                        }
                      },
                    },
                  }}
                  placeholder="Select"
                  onChange={(event: any) => {
                    handleSelectChange(event)
                  }}
                  renderValue={(value: any) => {
                    if (value === "") {
                      return <span>Select Coach</span>;
                    }
                    return <span>{value?.full_name}</span>;
                  }}
                >
                  {dropData?.map((user: any, index: number) => {
                    return (
                      <MenuItem
                        value={user}
                        sx={selectItemStyle}
                        key={index}
                        {...register("receiverId")}
                      >
                        <span className="truncate max-w-[28rem]">{user?.full_name}</span>
                      </MenuItem>
                    )
                  })}
                </Select>
                {errors?.receiverId && (
                  <p style={{ fontSize: "10px", color: "red" }}>
                    {errors?.receiverId?.message}
                  </p>
                )}
              </FormControl>

            </Box>
          ) : ""}
        </Box>

        <Box sx={{ maxWidth: "75rem", mb: "7.3rem" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              flexWrap: "wrap",
              [theme.breakpoints.down("md")]: {
                gap: "3rem",
              },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <UploadBox
                name="video"
                type="video"
                uploadTitle="Browse Video To Upload"
                accept="video/*"
                fileName={video.name}
                progress={100}
                status={"Completed"}
                isUploading={video.isUploading}
                showImages
                handleFileChange={handleUploadVideo}
                register={register}
              />
              {errors?.video?.message ? (
                <p className="text-red-500 "> {errors.video.message} </p>
              ) : null}
              <Box>
                {progressVideo === 100 &&
                  <ProgressBar
                    percentage={progressVideo}
                    name={videoName}
                    status="Completed"
                  />}
              </Box>
              {video?.url && (
                <Box>
                  <Box
                    sx={{
                      width: "32rem",
                      height: "20rem",
                      mt: "20px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      bgcolor: "#8080803d",
                      overflow: "hidden"
                    }}
                  >
                    <ReactPlayer url={video?.url} controls muted width={"100%"} height={"20rem"} />
                  </Box>
                </Box>
              )}
            </Box>


            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <UploadBox
                name="image"
                accept="image/*"
                uploadTitle="Browse Thumbnail To Upload"
                type="photo"
                fileName="Image12.png"
                progress={100}
                status="completed"
                isUploading={image.isUploading}
                showImages
                handleFileChange={handleUploadImage}
                register={register}
              />
              {errors?.image?.message ? (
                <p className="text-red-500"> {errors.image.message} </p>
              ) : null}
              <Box>
                {
                  progress === 100 &&
                  <ProgressBar
                    percentage={progress}
                    name={fileName}
                    status="Completed"
                  />
                }
              </Box>
              {image?.url && (
                <Box>
                  <Box
                    component="img"
                    src={image?.url}
                    sx={{
                      width: "32rem",
                      height: "20rem",
                      mt: "20px",
                      borderRadius: "10px",
                      objectFit: "cover"
                    }}
                  />
                </Box>
              )}
            </Box>

          </Box>
        </Box>

        <Box sx={{ maxWidth: "40rem", mb: "2rem" }}>
          <Typography sx={{ fontSize: "2rem", fontWeight: 400, mb: "1.2rem" }}>
            Details
          </Typography>
          <Controller
            control={control}
            name="detail"
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Enter Details..."
                multiline
                minRows={5}
                variant="outlined"
                sx={{

                  width: "100%",
                  "& > .MuiOutlinedInput-root": {
                    borderRadius: "1rem",
                    fontSize: "2rem",
                    backgroundColor: "#fcfcfb",
                    border: 'none',
                    boxShadow: "0px 1px 4px rgb(0 0 0 / 10%)",
                  },
                  "& .MuiFormHelperText-root": {
                    color: "red",
                    fontSize: "1.4rem",
                    ml: 0
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fff"
                  }
                }}
                helperText={errors.detail ? errors.detail?.message : null}
              />
            )}
          />

        </Box>

        <Box sx={{ mb: "7rem" }}>
          <Button
            type="submit"
            disabled={image.isUploading || video.isUploading || isLoading}
            sx={{
              background: "#2165A2",
              borderRadius: "0.485rem",
              height: "4.5rem",
              width: "18rem",
              color: "#fff",
              fontSize: "1.8rem",
              textTransform: "capitalize",
              "&:hover": {
                background: "#2165A2",
              },
              "&.Mui-disabled": {
                opacity: 0.7,
                color: "#fff"
              }
            }}
          >
            Upload Call
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default UploadSingleCall;
