import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Layout from "../../components/Layout/Layout";
import classes from "../../styles/CheckinFormStyle";
import CheckinFormTable from "./Table";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useGetCheckinFormsQuery } from "../../services/admin";

const CheckInForms = () => {
  const { data, isLoading } = useGetCheckinFormsQuery(1, {
    refetchOnMountOrArgChange: true,
  });
  return (
    <Layout>
      <Box className="flex justify-between items-center">
        <Typography variant="h2" component="h2">
          Check-In Forms
        </Typography>
        <Link to="/create-form">
          <Button variant="contained" sx={classes.createFormBtn}>
            <AddIcon fontSize="large" sx={{ marginRight: "1rem" }} /> Create New
            Form
          </Button>
        </Link>
      </Box>
      {isLoading ? (
        <h4 className="text-center text-[3rem] my-10">Loading...</h4>
      ) : (
        <CheckinFormTable data={data?.data} />
      )}
    </Layout>
  );
};

export default CheckInForms;
