import YouTubeIcon from "@mui/icons-material/YouTube";
import { Box, CardActionArea, Grid, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import React from "react";
import { classes } from "../../../../styles/ResourcesLibraryStyle";
import PlayVideoModal from "../../Client Resources/Courses/PlayVideoModal";

const YoutubeTabCard = ({ data }: any) => {
  const [openModal, setOpenModal] = React.useState(false);
  const { title, description, publishedAt, thumbnails } = data.snippet;
  const thumbnail = thumbnails?.medium?.url;
  const videoUrl = `https://www.youtube.com/watch?v=${data?.id?.videoId}`;
  const handleOpenModal = () => setOpenModal(true);

  return (
    <>
      <Card sx={classes.Card} onClick={handleOpenModal}>
        <CardActionArea sx={classes.CardActionArea}>
          <Box sx={classes.CardActionAreaBox}>
            <CardMedia
              sx={classes.RecentHuddleCardMedia}
              component="img"
              image={thumbnail}
              alt="RecentHuddleImg"
            />
            <YouTubeIcon sx={classes.YoutubeTabPlayIcon} />
            
          </Box>
          <CardContent sx={classes.CardContent}>
            <Typography gutterBottom sx={classes.CardTitle}>
              {title && title?.replace("&#39;", "'")}
            </Typography>
            <Typography sx={classes.RecentHuddlecardDescription}>
              {description}
            </Typography>
            <Grid marginTop="0rem" container spacing="1rem">
              <Grid item xs={8}>
                <Stack direction="row" spacing="1rem">
                  
                  <Typography sx={classes.PodCastCardDate}>
                    Published At: {publishedAt && dayjs(publishedAt).format("MMM, DD YYYY")}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>

      </Card>


      <PlayVideoModal
        open={openModal}
        setOpen={setOpenModal}
        videoUrl={videoUrl}
        title={title}
        description={description}
      />
    </>
  );
};

export default YoutubeTabCard;
