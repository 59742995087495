import { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  IconButton,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import Layout from "../../../../components/Layout/Layout";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import classes from "../../../../styles/CreateFormStyle";
import { Box } from "@mui/system";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CrossIcon from "../../../../assets/images/cross-white.svg";
import {
  calendarStyle,
  dateInputStyle,
} from "../../../../styles/calenderStyles";
import dayjs from "dayjs";
import MyCalendar from "./Calender";
import {
  useDeleteScheduleCallMutation,
  useGetScheduleCallsQuery,
} from "../../../../services/admin";
import moment from "moment";
import { getRequest } from "../../../../config/axiosConfig";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";
import { toast } from "react-toastify";
import apiEndpoint from "../../../../config/environment";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../store/selectors";
import { getClient } from "../../../../apis/auth-apis";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

const ScheduleCallCalender = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const { data } = useGetScheduleCallsQuery(1);

  const [deleteScheduleCall] = useDeleteScheduleCallMutation();
  const [startDate, setStartDate]: any = useState(moment());
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedMonth, setSelectedMonth] = useState(
    dayjs().format("MMM YYYY")
  );
  const [endDate, setEndDate]: any = useState(moment());
  const [date, setDate] = useState(moment());
  const [eventsList, setEventsList] = useState([]);
  const [OutlookCODE, setOutlookCODE] = useState(true);

  useEffect(() => {
    if(refetch){
      getData();
    }
  }, [refetch]);

  useEffect(() => {
    getData();
  }, [date]);

  const getData = async () => {
    console.log("DATA FETCHED --->");
    try {
      setIsLoading(true)
      let scheduledCalls: any = [];
      const result = await getRequest(`admin/call`);
      console.log("---->", result?.data?.calls);
      if(result?.data?.calls){
        scheduledCalls = result?.data?.calls.map((item: any) => {
          return {
            ...item,
            endDate: moment(item?.date).format(),
            start: new Date(item?.date),
            end: new Date(item?.date),
            allDay: true,
          };
        });
        setEventsList(scheduledCalls);
        setIsLoading(false);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  }

  const handlePrev = () => {
    const newDate = moment(selectedDate).subtract(1, "month");
    setSelectedMonth(moment(newDate).format("MMM YYYY"));
    setSelectedDate(newDate);
    setDate(newDate);
  };

  const handleNext = () => {
    const newDate = moment(selectedDate).add(1, "month");
    setSelectedMonth(moment(newDate).format("MMM YYYY"));
    setSelectedDate(newDate);
    setDate(newDate);
  };

  // Get Filter Calls Function
  function handleFilterCall(e: any) {
    e.preventDefault();
    setDate(moment(startDate));
    setSelectedMonth(moment(startDate).format("MMM YYYY"));
    setSelectedDate(moment(startDate));
    const start = dayjs(startDate).startOf("day").format();
    const end = dayjs(endDate).startOf("day").format();
    if (end < start) {
      toast.warn("Select end date greater than start date");
      return;
    }
    const payload = {
      start_date: dayjs(startDate).startOf("day").format(),
      end_date: dayjs(endDate).startOf("day").format(),
    };
    const newCalls = data?.data?.calls
      ?.filter((event: any) => {
        return dayjs(event.date).isBetween(
          payload.start_date,
          payload.end_date,
          "day",
          "[]"
        );
      })
      .map((item: any) => {
        return {
          ...item,
          endDate: moment(item?.date).format(),
          start: new Date(item?.date),
          end: new Date(item?.date),
          allDay: true,
        };
      });
    setEventsList(newCalls);
  }

  function handleNavigate(date: any) {
    setDate(date);
  }

  const handleStartDate = (date: any) => {
    setStartDate(date);
  };
  function handleEndDate(date: any) {
    setEndDate(date);
  }
  const handleGoogleAuth = () => {
    const authUrl = `${apiEndpoint}/admin/google`;
    window.location.href = authUrl; // Redirect current tab
  };

  // Setting the Calls  and clearing date-picker states
  const handleClear = () => {
    setStartDate(moment());
    setEndDate(new Date());
    setDate(moment());
    setSelectedMonth(moment().format("MMM YYYY"));
    setRefetch(!refetch);
  };
  const user = useSelector(selectUser);

  const [CODE, setCode] = useState(true);
  const userId = user?.id;

  useEffect(() => {
    getClient(userId).then((res) => {
      setCode(res?.data?.user?.google_auth);
      setOutlookCODE(res?.data?.user?.outlook_auth);

    });
  }, [userId]);

  const handleOutlookAuth = () => {
    const authUrl = `${apiEndpoint}/admin/outlook`;
    window.location.href = authUrl; // Redirect current tab
  };

  const inProgressEvent = eventsList?.find((event: any) => event?.meeting_status === "inprogress");

  return (
    <Layout>
      <Stack direction="row" gap="2rem" sx={{ mb: "6.8rem" }}>
        <Link to="/resources-library">
          <IconButton sx={classes.backArrow} disableRipple>
            <ArrowRightAltIcon />
          </IconButton>
        </Link>
        <Typography variant="h2">Schedule Huddle Call</Typography>
      </Stack>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h2">Scheduled Video Calls </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <Typography variant="h2"> {selectedMonth}</Typography>
          </Box>
          <Button
            onClick={handlePrev}
            sx={{
              backgroundColor: "#2165A2",
              ml: "3.1rem",
              borderRadius: "50%",
              width: "3rem",
              height: "3rem",
              minWidth: "3rem",
              "&:hover": {
                backgroundColor: "#2165A2",
              },
            }}
          >
            <ArrowBackIosNewIcon
              sx={{
                "&.MuiSvgIcon-root": {
                  color: "white",
                  textAlign: "center",
                  width: "1.7rem",
                  height: "1.7rem",
                },
              }}
            />
          </Button>
          <Button
            onClick={handleNext}
            sx={{
              backgroundColor: "#828282",
              ml: "1.6rem",
              borderRadius: "50%",
              width: "3rem",
              height: "3rem",
              minWidth: "3rem",
              "&:hover": {
                backgroundColor: "#828282",
              },
            }}
          >
            <ArrowForwardIosIcon
              sx={{
                "&.MuiSvgIcon-root": {
                  color: "white",
                  textAlign: "center",
                  width: "1.7rem",
                  height: "1.7rem",
                },
              }}
            />
          </Button>
        </Box>
      </Box>
      <Box sx={{ mb: "2.3rem", mt: "3.5rem" }}>
        <Typography
          sx={{
            fontWeight: "500",
            color: "#333333",
            fontSize: "1.8rem",
            lineHeight: "2.7rem",
          }}
        >
          Filter by
        </Typography>
      </Box>
      <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
        <form onSubmit={handleFilterCall}>
          <Grid sx={{ display: "flex", gap: "1rem" }} item>
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <Typography
                sx={{
                  fontSize: "14.05px",
                  fontWeight: "500",
                  lineHeight: "21.08px",
                }}
              >
                Date
              </Typography>
              <Box sx={{ width: "22rem", padding: "1.5rem 1rem" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label=""
                    // disablePast
                    onChange={handleStartDate}
                    value={startDate}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        sx={dateInputStyle}
                        {...params}
                      />
                    )}
                    PaperProps={{
                      sx: calendarStyle,
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "1.2rem" }}>
              <Typography
                sx={{
                  fontSize: "14.05px",
                  fontWeight: "500",
                  lineHeight: "21.08px",
                }}
              >
                To
              </Typography>
              <Box sx={{ width: "22rem", padding: "1.5rem 1rem" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label=""
                    // disablePast
                    value={endDate}
                    onChange={handleEndDate}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        sx={dateInputStyle}
                        {...params}
                      />
                    )}
                    PaperProps={{
                      sx: calendarStyle,
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <Button
                  type="submit"
                  sx={{ fontSize: "1.5rem" }}
                  variant="outlined"
                >
                  Filter
                </Button>

                <div
                  onClick={handleClear}
                  className="w-[3.4rem] h-[3.4rem] cursor-pointer"
                >
                  <img
                    src={CrossIcon}
                    alt="CrossIcon"
                    className="w-full h-full"
                  />
                </div>
                <div>
                  {CODE == false && (
                    <Button
                      type="button"
                      onClick={handleGoogleAuth}
                      sx={{ fontSize: "1.5rem" }}
                      variant="outlined"
                    >
                      Connect with Google Calendar
                    </Button>
                  )}

                </div>

                <div>
                  {OutlookCODE == false && (
                    <Button
                      type="button"
                      onClick={handleOutlookAuth}
                      sx={{ fontSize: "1.5rem" }}
                      variant="outlined"
                    >
                      Connect with Outlook Calendar
                    </Button>
                  )}
                </div>
              </Box>
            </Box>
          </Grid>
        </form>
      </Grid>
        {isLoading && (
          <Typography
            sx={{
              alignContent: "center",
              color: "#333333",
              fontSize: "1.8rem",
              fontWeight: "400",
            }}
          >
            Loading...
          </Typography>
        )}
      <Box sx={{ mt: "3.2rem" }}>
        <div className="bg-white rounded-[1.23547rem] border-[#19236C] w-full h-full overflow-hidden">
          <MyCalendar
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            myEventsList={eventsList}
            deleteScheduleCall={deleteScheduleCall}
            date={date}
            handleNavigate={handleNavigate}
            inProgressEvent={inProgressEvent}
            refetch={() => setRefetch(!refetch)}
          />
        </div>
      </Box>
    </Layout>
  );
};

export default ScheduleCallCalender;
