import "react-datepicker/dist/react-datepicker.css";
import {
	useGetChampionshipReportMutation,
	useGetClientActionItemsReportMutation,
	useGetClientUsersReportMutation,
	useGetCoachActionItemsReportMutation,
	useGetCoachUsersReportMutation,
	useGetCompleteChallengeReportMutation,
} from "../services/admin";
import StatCard from "./StatCard";

const UsageReportCard = () => {
	const [getClientUsersReport, { data }] = useGetClientUsersReportMutation();
	const [getClientActionItemsReport, { data: clientActionItem }] = useGetClientActionItemsReportMutation();
	const [getCoachUsersReport, { data: CoachUserReport }] = useGetCoachUsersReportMutation();
	const [getCoachActionItemsReport, { data: coachActionItem }] = useGetCoachActionItemsReportMutation();
	const [getCompleteChallengeReport, { data: completeChallenged }] = useGetCompleteChallengeReportMutation();
	const [getChampionshipReport, { data: championshipReport }] = useGetChampionshipReportMutation();

	return (
		<>

			<>
				<StatCard
					title={"Client User Report"}
					total={data?.data?.clients}
					queryFunction={getClientUsersReport}

				/>
				<StatCard
					title={"Client Action Item"}
					total={clientActionItem?.data?.client_actionitems}
					queryFunction={getClientActionItemsReport}

				/>
				<StatCard
					title={"Coach User Report"}
					total={CoachUserReport?.data?.coaches}
					queryFunction={getCoachUsersReport}

				/>
				<StatCard
					title={"Coach Action Item"}
					total={coachActionItem?.data?.coach_ationitems}
					queryFunction={getCoachActionItemsReport}

				/>
				<StatCard
					title={"Complete Challenge Report"}
					total={completeChallenged?.data?.completed_challenges
					}
					queryFunction={getCompleteChallengeReport}

				/>
				<StatCard
					title={"Championship Report"}
					total={championshipReport?.data?.championship_report}
					queryFunction={getChampionshipReport}

				/>
			</>
		</>
	);
};

export default UsageReportCard;
