import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "1px solid #fffff",
  boxShadow: 24,
  p: 1,
  borderRadius: "20px",
  maxWidth: "80%"
};

export default function ViewModal({ open, setOpen, imageUrl }: any) {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img
            className="w-full max-h-[70rem] rounded-[1rem] object-center object-contain"
            src={imageUrl}
            alt="imageUrl"
          />
        </Box>
      </Modal>
    </div>
  );
}
