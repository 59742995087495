import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import classes from "../../../styles/CreateFormStyle";
import { InputLabel } from "@mui/material";

export default function CheckInFormSelect({ register }: any) {
  return (
    <Box sx={classes.select}>
      <FormControl>
        <InputLabel sx={classes.selectItem} id="demo-simple-select-label">Select</InputLabel>
        <Select
          name="type"
          {...register("type")}

        >
          <MenuItem value={"TEXTFIELD"} sx={classes.selectItem}>
            Text Field
          </MenuItem>
          <MenuItem value={"TEXTAREA"} sx={classes.selectItem}>
            Text Area
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
