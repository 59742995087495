import Layout from "../../components/Layout/Layout";
import { Typography, Stack, IconButton, Button } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import classes from "../../styles/CreateFormStyle";
import Form from "./Form";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  useUpdateOnBoardingFormMutation,
  isFetchBaseQueryError,
  useGetOnBoardingFormQuery,
} from "../../services/admin";
import { v4 as uuidv4 } from "uuid";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
interface questionI {
  id: string;
  question: string;
  label: string;
  type: string;
  length: string;
}
const EditBoardingForm = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetOnBoardingFormQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const [activeData, setActiveData]: any = useState({});

  const [questionsData, setQuestionsData]: any = useState<any[]>([]);
  const [title, setTitle] = useState("");
  const [isRequired, setisRequired] = useState("");

  const [questions, setQuestions]: any = useState<any[]>([]);
  const AddHandler = () => {
    const id = uuidv4();
    const question = {
      id: id,
      question: "",
      label: "",
      type: "",
      length: "",
    };

    setActiveData(question);
    setQuestions([...questions, question]);
  };
  const [updateOnBoardingForm] =
    useUpdateOnBoardingFormMutation();
  const navigate = useNavigate();

  const payload = {
    title: title,
    questions: questionsData,
  };

  useEffect(() => {
    const questionData = data?.data;

    if (questionData && id) {
      setQuestionsData(questionData?.onboardingFormQuestions);
      setTitle(questionData?.title);

      const filteredQuestion = questionData?.onboardingFormQuestions.map(
        (question: questionI) => {
          return {
            id: question.id,
            label: question.label,
            length: question.length,
            question: question.question,
            type: question.type,
          };
        }
      );
      setQuestions(filteredQuestion);
    }
  }, [id, isLoading]);
  const publishForm = async () => {
    if (title == "") {
      setisRequired("Required");
      return;
    }
    try {
      await updateOnBoardingForm({ id: id, payload: payload })
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          navigate("/onboarding-forms");
        });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error?.data?.message);
      }
    }
  };

  return (
    <Layout>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap="2rem">
          <Link to="/onboarding-forms">
            <IconButton sx={classes.backArrow} disableRipple>
              <ArrowRightAltIcon />
            </IconButton>
          </Link>
          <Typography variant="h2">Edit Onboarding Form</Typography>
        </Stack>
        <Button
          variant="contained"
          sx={classes.pubBtn}
          disableElevation
          disableRipple
          disabled={questionsData.length == 0}
          onClick={publishForm}
        >
          Publish
        </Button>
      </Stack>
      <h6 className="after:!text-[red] text-3xl font-semibold mt-10">Title</h6>
      <div className="flex items-center mt-5 max-w-[90%]">
        <input
          type="text"
          className="bg-[#fff] px-10 text-[1.6rem] border border-[#D0D6DE] rounded-3xl font-medium outline-none h-24"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <span style={{ color: "red" }}>{isRequired}</span>
      <Form
        setQuestionsData={setQuestionsData}
        questionsData={questionsData}
        questions={questions}
        setQuestions={setQuestions}
        AddHandler={AddHandler}
        activeData={activeData}
        setActiveData={setActiveData}
      />
    </Layout>
  );
};

export default EditBoardingForm;
