import { Box, IconButton, MenuItem, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useState } from "react";
import { StyledMenu } from "../../../styles/StyledMenu";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useDeleteBannerMutation } from "../../../services/admin";
import { toast } from "react-toastify";
import ViewModal from "./ViewModal";

const UploadedImage = ({ item, refetch }: any) => {
  const [viewModal, setViewModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const [deleteBanner] = useDeleteBannerMutation();

  const onDelete = (id: any) => {
    deleteBanner(id)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        refetch();
      });
  };

  return (
    <>
      <ViewModal
        open={viewModal}
        setOpen={setViewModal}
        imageUrl={item?.imageUrl}
      />

      <div className="relative w-[42.1rem] bg-white shadow-lg p-[3.336rem] rounded-[1.06931rem]">
        <div className="w-[35rem] h-[25rem]">
          <img
            className="w-full h-full rounded-[1rem] object-cover"
            src={item?.imageUrl}
            alt="uploadedImage"
          />
        </div>
        <div className="absolute top-[3.9rem] right-0">
          <IconButton
            aria-label="delete"
            sx={{
              p: 0,
            }}
            onClick={handleClick}
          >
            <MoreVertIcon sx={{ color: "#2165A2", fontSize: "3.2rem" }} />
          </IconButton>
          <StyledMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                // handleOpenModal();
                setViewModal(true);
                handleClose();
              }}
            >
              <Box>
                <Typography>View</Typography>
                <VisibilityIcon />
              </Box>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(`/edit-change-image/${item?.contentType}`);
              }}
            >
              <Box>
                <Typography>Edit</Typography>
                <EditIcon />
              </Box>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Box onClick={() => onDelete(item?.id)}>
                <Typography>Delete</Typography>
                <DeleteIcon />
              </Box>
            </MenuItem>
          </StyledMenu>
        </div>
        <h4 className="text-[#2165A2] text-[2.13999rem] font-semibold mt-[2.8rem] capitalize">
          {item?.contentType === "COACH_CLIENT_LOGIN"
            ? item?.contentType.split("_")[0] +
              " " +
              "/" +
              item?.contentType.split("_")[1] +
              " " +
              item?.contentType.split("_")[2]
            : item?.contentType.split("_")[0] +
              " " +
              item?.contentType.split("_")[1]}
        </h4>
      </div>
    </>
  );
};

export default UploadedImage;
