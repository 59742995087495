import iconReport from "../assets/images/iconReport.svg";
import iconCheckin from "../assets/images/iconCheckin.svg";
import iconUser from "../assets/images/iconUser.svg";
import iconLibrary from "../assets/images/iconLibrary.svg";
import iconClient from "../assets/images/iconClient.svg";
import iconLeaderboard from "../assets/images/iconLeaderboard.svg";
import iconManagePoints from "../assets/images/iconManagePoints.svg";
import iconManageEvent from "../assets/images/iconManageEvent.svg";
import iconSettings from "../assets/images/iconSettings.svg";
import changeImageIcon from "../assets/images/changeImgeIcon.svg";

const listItem = [
  {
    id: 1,
    text: "Usage Reports",
    icon: iconReport,
    isActive: true,
    path: "/usage-report",
  },
  // {
  //   id: 2,
  //   text: "Onboarding Forms",
  //   icon: iconForms,
  //   path: "/onboarding-forms",
  // },
  {
    id: 3,
    text: "Check-In Forms",
    icon: iconCheckin,
    path: "/checkin-forms",
  },
  {
    id: 4,
    text: "Manage Users",
    icon: iconUser,
    path: "/manage-users",
  },
  {
    id: 5,
    text: "Resources Library",
    icon: iconLibrary,
    path: "/resources-library",
  },
  {
    id: 6,
    text: "View Notes",
    icon: iconClient,
    path: "/client-notes",
  },
  {
    id: 7,
    text: "Leaderboard",
    icon: iconLeaderboard,
    path: "/leaderboard",
  },
  {
    id: 8,
    text: "Manage Points",
    icon: iconManagePoints,
    path: "/manage-points",
  },
  {
    id: 9,
    text: "Manage Events",
    icon: iconManageEvent,
    path: "/manage-events",
  },
  {
    id: 10,
    text: "Change Image",
    icon: changeImageIcon,
    path: "/change-image",
  },
  {
    id: 11,
    text: "Settings",
    icon: iconSettings,
    path: "/settings",
  },
];
export default listItem;
