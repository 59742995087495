import Box from "@mui/material/Box";
import * as React from "react";
import {
  classes,
  ClientAntTab,
  ClientAntTabs,
} from "../../../styles/ResourcesLibraryStyle";


import Password from "./Password";
import ViewForm from "./ViewForm";

interface ClientTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function ClientTabPanel(props: ClientTabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={classes.TabPanelBox}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

const SettingsTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box sx={{ maxWidth: "100%" }}>
        <ClientAntTabs
          value={value}
          onChange={handleChange}
          aria-label="ant example"
        >
          <ClientAntTab label="Edit Profile" />
          <ClientAntTab label="Settings" />

        </ClientAntTabs>
      </Box>
      <ClientTabPanel value={value} index={0}>
        <ViewForm />
      </ClientTabPanel>
      <ClientTabPanel value={value} index={1}>
        <Password setValue={setValue}/>
      </ClientTabPanel>

    </Box>
  );
};
export default SettingsTabs;
