export const dateInputStyle = {
  fontWeight: 400,
  fontSize: "2.2rem",
  lineHeight: "2.5rem",
  width: "100%",

  "& .MuiInputBase-input": {
    padding: 0,
    //paddingLeft: "2rem",
    textAlign: "center",
    margin: 0,
    fontWeight: 400,
    fontSize: "2rem",
    lineHeight: "2rem",
    height: "6.5rem",
    borderRadius: "1rem",
    backgroundColor: "#fff",
    color: "#333333",
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "##fff",
    borderRadius: "1.4rem",
    fontWeight: 400,
    fontSize: "2rem",
    lineHeight: "2rem",
    borderColor: "#D0D6DE !important",
    "& fieldset": {
      fontWeight: 400,
      fontSize: "10px",
      lineHeight: "15px",
      borderRadius: "1.4rem",
      borderColor: "#D0D6DE !important",
    },
    "&:hover fieldset": {
      borderWidth: "1px",
      borderColor: "#000000",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#000000",
    },
  },
  "& .MuiInputAdornment-root": {
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "15px",
    backgroundColor: "#fff",
    color: "yellow",
    borderRadius: "8px",
    "& .MuiSvgIcon-root": {
      width: "2.2rem",
      height: "2.2rem",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0.1rem !important",
  },
};

export const calendarStyle = {
  backgroundColor: "#fff",
  padding: "12px",
  borderRadius: "8px",
  boxShadow: "0px 4px 26px rgba(0, 0, 0, 0.1)",
  marginTop: "6px",
  width: "300px",

  "& .MuiCalendarPicker-root": {
    width: "276px",
    margin: 0,
  },

  "& .MuiPickersCalendarHeader-root": {
    "& .MuiPickersCalendarHeader-labelContainer": {
      fontSize: "1.8rem",
      color: "#2165A2",
      "& .MuiSvgIcon-root": {
        width: "3rem",
        height: "3rem",
      },
    },
    "& .MuiPickersArrowSwitcher-root": {
      "& .MuiButtonBase-root": {
        color: "#2165A2",
        "& .MuiSvgIcon-root": {
          width: "2.4rem",
          height: "2.4rem",
        },
      },
    },
  },

  "& .MuiCalendarPicker-root > div:first-of-type": {
    margin: "0 0 9px",
    padding: "0 4px 4px",
    borderBottom: "1px solid #dce3fc61",
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },

  "& .MuiDayPicker-header": {
    "& .MuiDayPicker-weekDayLabel": {
      width: "4rem",
      height: "4rem",
      fontSize: "1.8rem",
      color: "#2165A2",
      fontWeight: 600,
    },
  },

  "& .MuiDayPicker-weekContainer": {
    "& .MuiPickersDay-root": {
      width: "4rem",
      height: "4rem",
      fontSize: "1.6rem",
      borderRadius: "5px",
      color: "#333333",
      "&.Mui-selected": {
        backgroundColor: "#2165A2",
        color: "#fff",
        fontWeight: 600,
      },
      "&.Mui-disabled": {
        backgroundColor: "#d0d6de4a",
        color: "#33333399",
      },
    },
  },

  "& .MuiMonthPicker-root": {
    width: "246px",
    margin: 0,
    gap: "10px",

    "& .PrivatePickersMonth-root": {
      borderRadius: "8px",
      fontSize: "2rem",
      color: "#000000",
      fontWeight: 500,
      margin: 0,
      backgroundColor: "#fff",
      flex: "1 0 calc(33.33% - 10px)",
      "&:hover": {
        color: "#5173F0",
        backgroundColor: "#EEF2F9CC",
      },
      "&:disabled": {
        color: "#00000026",
      },
      "&.Mui-selected": {
        color: "#fff",
        backgroundColor: "#5173F0",
      },
    },
  },

  "& .MuiYearPicker-root": {
    width: "100%",
    margin: 0,
    gap: "10px",

    "& .PrivatePickersYear-root": {
      flex: "1 0 calc(33.33% - 10px)",

      "& .PrivatePickersYear-yearButton": {
        borderRadius: "8px",
        fontSize: "2rem",
        color: "#000000",
        fontWeight: 500,
        margin: 0,
        backgroundColor: "#fff",
        width: "100%",
        "&:hover": {
          color: "#fff",
          backgroundColor: "#839CF4",
        },
        "&:disabled": {
          color: "#00000026",
        },
        "&.Mui-selected": {
          color: "#fff",
          backgroundColor: "#5173F0",
        },
      },
    },
  },
};
