import React from "react";
const weekDays = [
  {
    id: "1",
    title: "S",
  },
  {
    id: "2",
    title: "M",
  },
  {
    id: "3",
    title: "T",
  },
  {
    id: "4",
    title: "W",
  },
  {
    id: "5",
    title: "T",
  },
  {
    id: "6",
    title: "F",
  },
  {
    id: "7",
    title: "S",
  },
];

const SelectWeeklyDays = ({ selectedDays, setSelectedDays }: any) => {
  const weekDaysHandler = (e: any) => {
    if (selectedDays.includes(e)) {
      if (selectedDays?.length <= 1) return;
      setSelectedDays(selectedDays.filter((d: any) => d !== e));
    } else {
      setSelectedDays([...selectedDays, e]);
    }
  };

  return (
    <div className=" flex flex-col sm:flex-row gap-[8rem] mt-[4.8rem] items-start">
      <h3 className="text-[2.7rem] text-[#333] font-semibold min-w-[165px]">
        Occurs on
      </h3>
      <div className="w-[22.7rem] h-[6rem]">
        <form className="zoom_week_days flex justify-center gap-3">
          {weekDays?.map((week, idx) => (
            <div key={idx} className="text-[18px] font-normal flex items-center w-16 h-16 bg-white justify-center rounded-full shadow-sm">
              <input
                id={week?.id}
                type="checkbox"
                checked={selectedDays?.includes(week?.id)}
                className="h-4 w-4 mt-1.5 hidden shrink-0 rounded-[4px] border border-[#D0D5DD] text-primary"
                onChange={() => weekDaysHandler(week?.id)}
              />
              <label
                htmlFor={week?.id}
                className="flex items-center justify-center w-16 h-16 bg-white rounded-full shadow-sm cursor-pointer">
                {week?.title}
              </label>
            </div>
          ))}
        </form>
      </div>
    </div>
  );
};

export default SelectWeeklyDays;
