import { yupResolver } from '@hookform/resolvers/yup';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Layout from '../../../../components/Layout/Layout';
import classes from '../../../../styles/CreateFormStyle';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import {
    isFetchBaseQueryError,
    useGetByIdScheduleCallQuery,
    useUpdateScheduleCallMutation
} from '../../../../services/admin';
const selectItemStyle = {
    fontSize: '1.6rem',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#333333',
    '&.Mui-selected': {
        backgroundColor: 'rgb(33 101 162 / 44%)'
    }
};

interface IFormInputs {
    date: string;
    endDate?: string;
    recurrence: string;
    repeatEvery: string;
    occurrences?: string;
    recurrencecall?: boolean;
}

const schema = yup.object().shape({
    endDate: yup.string().nullable(),
    date: yup.string().required(' Required'),
    repeatEvery: yup.string().required('Required'),
    recurrence: yup.string().required('Required'),
    occurrences: yup.string().nullable(),
    recurrencecall: yup.string()
});

const options = ['Daily', 'Weekly', 'Monthly'];
const days = ['1', '2', '3', '4', '5', '6', '7'];
const EditScheduleCall = () => {
    const { id } = useParams();
    const { data, isFetching } = useGetByIdScheduleCallQuery(id, {
        refetchOnMountOrArgChange: true
    });
    const [selected, setSelected] = useState('');
    const [selectedDay, setSelectedDay] = useState('');
    const [occurrences, setOccurrences] = useState('');
    const [setEndDate] = React.useState<any>(null);
    const [checked, setChecked] = useState('by');
    const [error, setError] = useState("");
    const [errorOccurrences, setErrorOccurrences] = useState("");
    const navigate = useNavigate();
    const [updateScheduleCall] =
        useUpdateScheduleCallMutation();

    const {
        handleSubmit,
        control,
        setValue,
        register,
        formState: { errors }
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        if (data?.data?.calls) {
            setValue(
                'date',
                dayjs(data && data?.data?.calls?.date).format('YYYY-MM-DDTHH:mm')
            );
            setValue(
                'endDate',
                dayjs(data && data?.data?.calls?.endDate).format('MM/DD/YYYY')
            );
            setValue(
                'repeatEvery',
                data && data?.data?.calls?.repeatEvery
            );
            setValue(
                'occurrences',
                data && data?.data?.calls?.occurrences
            );
            setValue(
                'recurrence',
                data && data?.data?.calls?.recurrence
            );
            setSelectedDay(data?.data?.calls?.repeatEvery);
            setOccurrences(data?.data?.calls?.occurrences);
            setSelected(data?.data?.calls?.recurrence);
        }
    }, [isFetching]);

    const onSubmit = async (data: IFormInputs) => {
        const end = dayjs(data?.endDate);
        const start = dayjs(data?.date);
        const dateCheck = end.isBefore(start, "date");

        if (dateCheck && checked === "by") {
            toast.error("EndDate should be greater than startDate")
            return
        }

        if (data?.endDate === null && checked === "by" || data?.endDate === null && checked === "by" || data?.endDate === undefined && checked === "by") {
            setError("required")
            setErrorOccurrences("")
            return
        }
        if (data?.occurrences === null && checked === "after" || data?.occurrences === "" && checked === "after" || data?.occurrences === undefined && checked === "after") {
            setErrorOccurrences("required")
            setError("")
            return
        }
        const payload = {
            date: dayjs(data.date).format('MM/DD/YYYY HH:mm  '),
            endDate: checked === 'by' ? dayjs(data.endDate).format('MM/DD/YYYY') : dayjs(data.date).format('MM/DD/YYYY'),
            recurrence: data.recurrence,
            repeatEvery: data.repeatEvery,
            occurrences: checked === 'after' ? data.occurrences : null,
            callType: 'huddle'
        };
        try {
            await updateScheduleCall({ id, payload })
                .unwrap()
                .then((res: any) => {
                    navigate('/schedule-call-calendar');
                    toast.success(res?.message);
                });
        } catch (error) {
            if (isFetchBaseQueryError(error)) {
                toast.error(error?.data?.message);
            }
        }
    };

    function handleRadio(e: any) {
        setChecked(e.target.value);
    }

    return (
        <Layout>
            <Stack direction="row" gap="2rem" sx={{ mb: '6.8rem' }}>
                <Link to="/schedule-call-calendar">
                    <IconButton sx={classes.backArrow} disableRipple>
                        <ArrowRightAltIcon />
                    </IconButton>
                </Link>
                <Typography variant="h2">Edit Video Call</Typography>
            </Stack>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ marginLeft: '7.1rem' }}>
                    <Box sx={{ display: 'flex', gap: '18.6rem' }}>
                        <Box sx={{ mt: '1rem' }}>
                            <CustomTypography title="Date" />
                        </Box>
                        <Box sx={{ width: '35rem', height: '6rem' }}>
                            <input
                                type="datetime-local"
                                className="w-full text-[1.8rem] leading-[2.1rem]border-[#e5e7eb] font-medium py-[1.2rem] pr-[2rem] focus-visible:outline-none rounded-[1rem]  text-[#333333]  text-center  border-[0.150201 rem] border-[solid] border-[white]"
                                {...register('date')}
                            />
                            {errors?.date && (
                                <div style={{ fontSize: '10px', color: 'red' }}>
                                    {errors?.date?.message}
                                </div>
                            )}
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '9.4rem', mt: '3rem' }}>
                        <Box>
                            <CustomTypography title="Recurring" />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Controller
                                control={control}
                                name="recurrencecall"
                                defaultValue={false}
                                render={({ field: { onChange, ...rest } }) => (
                                    <FormControlLabel
                                        label=""
                                        control={
                                            <Checkbox
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: '2.625rem' } }}
                                                onChange={onChange}
                                                {...rest}
                                            />
                                        }
                                    />
                                )}
                            />

                            <Typography
                                variant="h6"
                                sx={{ color: '#333333', fontSize: '1.8rem', fontWeight: '400' }}
                            >
                                Every 2 days, until Jan 26, 2023, 7 occurrences(s)
                            </Typography>

                            {/* {errors?.recurrencecall && (
                  <p style={{ fontSize: "10px", color: "red" }}>
                    {errors?.recurrencecall?.message}
                  </p>
                )} */}
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '9.4rem', mt: '3rem' }}>
                        <Box>
                            <CustomTypography title="Recurrence" />
                        </Box>
                        <Box>
                            <FormControl
                                sx={{
                                    width: '22.733rem',
                                    '& .MuiInputBase-root': { height: '5.25rem' }
                                }}
                            >
                                <Controller
                                    control={control}
                                    name="recurrence"
                                    render={({ field: { onChange, ...restField } }) => (
                                        <Select
                                            fullWidth
                                            {...restField}
                                            sx={{
                                                fontSize: '2rem',
                                                fontWeight: 400,
                                                borderColor: '#2165A2',
                                                backgroundColor: '#fcfcfb',
                                                borderRadius: '7px',
                                                py: '0rem',
                                                px: '1rem',
                                                '& .MuiSelect-icon': {
                                                    width: '3rem',
                                                    height: '3rem',
                                                    top: 'calc(50% - 1.5rem)'
                                                },
                                                '& .MuiMenuItem-root': {
                                                    fontSize: '1.8rem'
                                                },
                                                '& .MuiSelect-select': {
                                                    py: '1.8rem'
                                                }
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        '& .MuiMenuItem-root': {
                                                            fontSize: '1.8rem'
                                                        }
                                                    }
                                                }
                                            }}
                                            renderValue={(selected: any) => {
                                                if (selected === '') {
                                                    return <span>Select Coach</span>;
                                                }
                                                return <span>{selected}</span>;
                                            }}
                                            value={selected}
                                            onChange={(event: any) => {
                                                onChange(event);
                                                setSelected(event.target.value);
                                            }}
                                        >
                                            {options?.map((user: any, index: number) => {
                                                return (
                                                    <MenuItem
                                                        value={user}
                                                        sx={selectItemStyle}
                                                        key={index}
                                                    >
                                                        {user}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    )}
                                />
                                {errors?.recurrence && (
                                    <p style={{ fontSize: '10px', color: 'red' }}>
                                        {errors?.recurrence?.message}
                                    </p>
                                )}
                            </FormControl>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '7rem', mt: '3rem' }}>
                        <Box>
                            <CustomTypography title="Repeat Every" />
                        </Box>
                        <Box>
                            <FormControl
                                sx={{
                                    width: '22.733rem',
                                    '& .MuiInputBase-root': { height: '5.25rem' }
                                }}
                            >
                                <Controller
                                    control={control}
                                    name="repeatEvery"
                                    render={({ field: { onChange, ...restField } }) => (
                                        <Select
                                            fullWidth
                                            {...restField}
                                            sx={{
                                                fontSize: '2rem',
                                                fontWeight: 400,
                                                borderColor: '#2165A2',
                                                backgroundColor: '#fcfcfb',
                                                borderRadius: '7px',
                                                py: '0rem',
                                                px: '1rem',
                                                '& .MuiSelect-icon': {
                                                    width: '3rem',
                                                    height: '3rem',
                                                    top: 'calc(50% - 1.5rem)'
                                                },
                                                '& .MuiMenuItem-root': {
                                                    fontSize: '1.8rem'
                                                },
                                                '& .MuiSelect-select': {
                                                    py: '1.8rem'
                                                }
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        '& .MuiMenuItem-root': {
                                                            fontSize: '1.8rem'
                                                        }
                                                    }
                                                }
                                            }}
                                            renderValue={(selected: any) => {
                                                if (selected.length === 0) {
                                                    return <em>Placeholder</em>;
                                                }
                                                return <span>{selected}</span>;
                                            }}
                                            value={selectedDay}
                                            onChange={(event: any) => {
                                                onChange(event);
                                                setSelectedDay(event.target.value);
                                            }}
                                        >
                                            {days?.map((user: any, index: number) => {
                                                return (
                                                    <MenuItem
                                                        value={user}
                                                        sx={selectItemStyle}
                                                        key={index}
                                                    >
                                                        {user}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    )}
                                />
                                {errors?.repeatEvery && (
                                    <p style={{ fontSize: '10px', color: 'red' }}>
                                        {errors?.repeatEvery?.message}
                                    </p>
                                )}
                            </FormControl>
                            <Typography
                                component="span"
                                sx={{
                                    fontSize: '2.7rem',
                                    color: '#333333',
                                    fontWeight: '400',
                                    ml: '1.2rem'
                                }}
                            >
                                Days
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mt: '3rem' }}>
                        <Box sx={{ mt: '0.8rem' }}>
                            <CustomTypography title="End Date" />
                        </Box>

                        <FormControl
                            sx={{
                                ml: '2.5rem',
                                mt: '1rem',
                                '& .MuiFormGroup-root': {
                                    gap: '5rem'
                                }
                            }}
                        >
                            <RadioGroup
                                onChange={handleRadio}
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="by"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: '2rem'
                                        },
                                        '& .MuiTypography-root ': {
                                            fontSize: '2.70362rem',
                                            fontWeight: '400',
                                            color: '#333333',
                                            lineHeight: '3.2rem'
                                        }
                                    }}
                                    value="by"
                                    control={<Radio />}
                                    label="By"
                                />

                                <FormControlLabel
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: '2rem'
                                        },
                                        '& .MuiTypography-root ': {
                                            fontSize: '2.70362rem',
                                            fontWeight: '400',
                                            color: '#333333',
                                            lineHeight: '3.2rem'
                                        }
                                    }}
                                    value="after"
                                    control={<Radio />}
                                    label="After"
                                />
                            </RadioGroup>
                        </FormControl>
                        <Box sx={{ width: '35rem' }}>
                            <Box>
                                <Controller
                                    control={control}
                                    name="endDate"
                                    defaultValue=""
                                    render={({ field: { onChange, ...restField } }) => (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label=""
                                                // value={dateValue}
                                                onChange={(event: any) => {
                                                    onChange(event);
                                                    setEndDate(event);
                                                    setError("")
                                                }}
                                                disabled={checked === 'after'}
                                                renderInput={params => (
                                                    <TextField
                                                        variant="outlined"
                                                        sx={dateInputStyle}
                                                        {...params}
                                                        error={false}
                                                    />
                                                )}
                                                PaperProps={{
                                                    sx: calendarStyle
                                                }}
                                                {...restField}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                                <p style={{ fontSize: "10px", color: "red", }}>
                                    {error}
                                </p>
                            </Box>

                            <Box sx={{ mt: '2.5rem' }}>
                                <FormControl
                                    sx={{
                                        width: '17.1rem',
                                        '& .MuiInputBase-root': { height: '6rem' }
                                    }}
                                >
                                    <Controller
                                        control={control}
                                        name="occurrences"
                                        render={({ field: { onChange, ...restField } }) => (
                                            <Select
                                                fullWidth
                                                disabled={checked === 'by'}
                                                {...restField}
                                                sx={{
                                                    fontSize: '2rem',
                                                    fontWeight: 400,
                                                    borderColor: '#2165A2',
                                                    backgroundColor: '#fcfcfb',
                                                    borderRadius: '7px',
                                                    py: '0rem',
                                                    px: '1rem',
                                                    '& .MuiSelect-icon': {
                                                        width: '3rem',
                                                        height: '3rem',
                                                        top: 'calc(50% - 1.5rem)'
                                                    },
                                                    '& .MuiMenuItem-root': {
                                                        fontSize: '1.8rem'
                                                    },
                                                    '& .MuiSelect-select': {
                                                        py: '1.8rem'
                                                    }
                                                }}
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            '& .MuiMenuItem-root': {
                                                                fontSize: '1.8rem'
                                                            }
                                                        }
                                                    }
                                                }}
                                                renderValue={(selected: any) => {
                                                    if (selected === '') {
                                                        return <span>Select</span>;
                                                    }
                                                    return <span>{selected}</span>;
                                                }}
                                                value={occurrences}
                                                onChange={(event: any) => {
                                                    onChange(event);
                                                    setOccurrences(event.target.value);
                                                    setErrorOccurrences("")

                                                }}
                                            >
                                                {days?.map((item: any, index: number) => {
                                                    return (
                                                        <MenuItem
                                                            value={item}
                                                            sx={selectItemStyle}
                                                            key={index}
                                                        >
                                                            {item}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        )}
                                    />
                                </FormControl>

                                <Typography
                                    component="span"
                                    sx={{
                                        fontSize: '2.7rem',
                                        color: '#333333',
                                        fontWeight: '400',
                                        ml: '1.2rem'
                                    }}
                                >
                                    occurrences
                                </Typography>
                            </Box>
                            <p style={{ fontSize: "10px", color: "red" }}>
                                {errorOccurrences}
                            </p>
                        </Box>
                    </Box>

                    <Box>
                        <Button
                            type="submit"
                            sx={{
                                mt: '3.1rem',
                                px: '21.2rem',
                                py: '1.6rem',
                                backgroundColor: '#2165A2',
                                lineHeight: '2.9rem',
                                fontSize: '2.49552rem',
                                fontWeight: '500',
                                borderRadius: '1rem',
                                color: '#FFFFFF',
                                textTransform: 'capitalize',
                                '&:hover': {
                                    backgroundColor: '#2165A2',
                                    boxShadow: 'none'
                                }
                            }}
                        >
                            Schedule
                        </Button>
                    </Box>
                </Box>
            </form>
        </Layout>
    );
};

export default EditScheduleCall;

export const dateInputStyle = {
    fontWeight: 800,
    fontSize: '10px',
    lineHeight: '15px',
    width: '35rem',
    maxWidth: '35rem',
    '& .MuiInputBase-input': {
        padding: 0,
        paddingLeft: '2rem',
        margin: 0,
        fontWeight: 400,
        fontSize: '1.6rem',
        lineHeight: '15px',
        height: '6rem',
        borderRadius: '1.4rem',
        backgroundColor: '#fff',
        color: '#333333'
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: '##fff',
        borderRadius: '1.4rem',
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '15px',
        '& fieldset': {
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '15px',
            borderRadius: '1.4rem',
            borderColor: '#D0D6DE'
        },
        '&:hover fieldset': {
            borderWidth: '1px',
            borderColor: '#D0D6DE'
        },
        '&.Mui-focused fieldset': {
            borderWidth: '1px',
            borderColor: '#D0D6DE'
        }
    },
    '& .MuiInputAdornment-root': {
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '15px',
        backgroundColor: '#fff',
        color: 'yellow',
        borderRadius: '8px',
        '& .MuiSvgIcon-root': {
            width: '2.2rem',
            height: '2.2rem'
        }
    }
};

export const calendarStyle = {
    backgroundColor: '#fff',
    padding: '12px',
    borderRadius: '8px',
    boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.1)',
    marginTop: '6px',
    width: '270px',

    '& .MuiCalendarPicker-root': {
        width: '246px',
        margin: 0
    },

    '& .MuiPickersCalendarHeader-root': {
        '& .MuiPickersCalendarHeader-labelContainer': {
            fontSize: '1.8rem',
            color: '#2165A2',
            '& .MuiSvgIcon-root': {
                width: '3rem',
                height: '3rem'
            }
        },
        '& .MuiPickersArrowSwitcher-root': {
            '& .MuiButtonBase-root': {
                color: '#2165A2',
                '& .MuiSvgIcon-root': {
                    width: '2.4rem',
                    height: '2.4rem'
                }
            }
        }
    },

    '& .MuiCalendarPicker-root > div:first-of-type': {
        margin: '0 0 9px',
        padding: '0 4px 4px',
        borderBottom: '1px solid #dce3fc61',
        '& .MuiIconButton-root': {
            padding: 0
        }
    },

    '& .MuiDayPicker-header': {
        '& .MuiDayPicker-weekDayLabel': {
            width: '4rem',
            height: '4rem',
            fontSize: '1.8rem',
            color: '#2165A2',
            fontWeight: 600
        }
    },

    '& .MuiDayPicker-weekContainer': {
        '& .MuiPickersDay-root': {
            width: '4rem',
            height: '4rem',
            fontSize: '1.6rem',
            borderRadius: '5px',
            color: '#333333',
            '&.Mui-selected': {
                backgroundColor: '#2165A2',
                color: '#fff',
                fontWeight: 600
            },
            '&.Mui-disabled': {
                backgroundColor: '#d0d6de4a',
                color: '#33333399'
            }
        }
    },

    '& .MuiMonthPicker-root': {
        width: '246px',
        margin: 0,
        gap: '10px',

        '& .PrivatePickersMonth-root': {
            borderRadius: '8px',
            fontSize: '2rem',
            color: '#000000',
            fontWeight: 500,
            margin: 0,
            backgroundColor: '#fff',
            flex: '1 0 calc(33.33% - 10px)',
            '&:hover': {
                color: '#5173F0',
                backgroundColor: '#EEF2F9CC'
            },
            '&:disabled': {
                color: '#00000026'
            },
            '&.Mui-selected': {
                color: '#fff',
                backgroundColor: '#5173F0'
            }
        }
    },

    '& .MuiYearPicker-root': {
        width: '100%',
        margin: 0,
        gap: '10px',

        '& .PrivatePickersYear-root': {
            flex: '1 0 calc(33.33% - 10px)',

            '& .PrivatePickersYear-yearButton': {
                borderRadius: '8px',
                fontSize: '2rem',
                color: '#000000',
                fontWeight: 500,
                margin: 0,
                backgroundColor: '#fff',
                width: '100%',
                '&:hover': {
                    color: '#fff',
                    backgroundColor: '#839CF4'
                },
                '&:disabled': {
                    color: '#00000026'
                },
                '&.Mui-selected': {
                    color: '#fff',
                    backgroundColor: '#5173F0'
                }
            }
        }
    }
};

function CustomTypography({ title }: any) {
    return (
        <>
            <Typography
                variant="h3"
                className="text-[2.7rem] leading-[3.168rem] text-[#333333] font-bold"
            >
                {title}
            </Typography>
        </>
    );
}
