import AddIcon from '@mui/icons-material/Add';
import { Button, ButtonProps } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from '@mui/material/styles';
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectClientResourceTabIndex } from "../../../store/selectors";
import { setClientResourceTabIndex } from "../../../store/slices/admin.slice";
import {
	classes,
	ClientAntTab,
	ClientAntTabs,
} from "../../../styles/ResourcesLibraryStyle";
import Index from "./Courses/index";
import HuddleCall from "./Huddle Calls";
import RecentHuddleCall from "./Recent Calls";

interface ClientTabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}
export interface ClientStyledTabProps {
	label: string;
}
function ClientTabPanel(props: ClientTabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={classes.TabPanelBox}>
					<Box>{children}</Box>
				</Box>
			)}
		</div>
	);
}
export const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
	minWidth: "26rem",
	width: "26rem",
	height: "5rem",
	fontSize: "1.6rem",
	fontWeight: 600,
	color: "#fff",
	backgroundColor: theme.palette.primary.main,
	textTransform: "capitalize",
	borderRadius: "1rem",
	"&:hover": {
		backgroundColor: theme.palette.primary.main,
	},
	"& .MuiSvgIcon-root": {
		fontSize: "2.6rem",
		marginRight: "1rem",
		marginTop: "-2px"
	}
}));


export const StyledDashedButton = {
	variant: "outlined",
	minWidth: "26rem",
	width: "26rem",
	height: "5rem",
	fontSize: "1.6rem",
	fontWeight: 600,
	color: "#2165A2",
	//backgroundColor: ,
	textTransform: "capitalize",
	borderRadius: "1rem",
	"&:hover": {
		backgroundColor: "#2165A2",
		color: "white",
	},
	"& .MuiSvgIcon-root": {
		fontSize: "2.6rem",
		marginRight: "1rem",
		marginTop: "-2px"
	}
}

const ClientResourcesTabs = () => {
	// const [value, setValue] = React.useState(0);
	const value = useSelector(selectClientResourceTabIndex);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleChange = (_: React.SyntheticEvent, newValue: number) => {
		// setValue(newValue);
		dispatch(setClientResourceTabIndex(newValue))
	};

	return (
		<Box>
			<Box sx={{ maxWidth: "100%" }}>
				<ClientAntTabs
					value={value}
					onChange={handleChange}
					aria-label="ant example"
				>
					<ClientAntTab label="Recent Calls" />
					<ClientAntTab label="Huddle Calls" />
					<ClientAntTab label="Courses" />
				</ClientAntTabs>
			</Box>
			<ClientTabPanel value={value} index={0}>
				<Box sx={{ textAlign: "right", marginTop: "1rem" }}>
					<StyledButton onClick={() => navigate("/upload-call")}><AddIcon />Upload Call</StyledButton>
				</Box>
				<RecentHuddleCall />
			</ClientTabPanel>
			<ClientTabPanel value={value} index={1}>
				<Box sx={{ display: "flex", justifyContent: "flex-end", gap: "3.1rem" }}>
					<Box sx={{ textAlign: "right", marginTop: "1rem" }}>
						<Button variant="outlined" sx={StyledDashedButton} onClick={() => navigate("/schedule-call-calendar")}> View Scheduled Calendar</Button>
					</Box>
					<Box sx={{ textAlign: "right", marginTop: "1rem" }}>
						<StyledButton onClick={() => navigate("/schedule-huddle-call")}><AddIcon />Schedule Huddle Call</StyledButton>
					</Box>
					<Box sx={{ textAlign: "right", marginTop: "1rem" }}>
						<StyledButton onClick={() => navigate("/upload-huddle")}><AddIcon />Upload Huddle</StyledButton>
					</Box>
				</Box>
				<HuddleCall />
			</ClientTabPanel>
			<ClientTabPanel value={value} index={2}>
				<Box sx={{ textAlign: "right", marginTop: "1rem" }}>
					<StyledButton onClick={() => navigate("/upload-course")}><AddIcon />Upload Course</StyledButton>
				</Box>
				<Index />
			</ClientTabPanel>
		</Box>
	);
};
export default ClientResourcesTabs;
