import { Box, Paper, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import dayjs from "dayjs";
import EmailIcon from "../../../assets/images/email.svg";
import ProfileImg from "../../../assets/images/profile.png";
import { useGetAllCoachesLeaderboardQuery } from "../../../services/admin";
import { StyledDataGrid } from "../../../styles/StyledDataGrid";
import ManageUserMenu from "./Menu";

const columns: GridColDef[] = [
  { field: "id", headerName: "Coach ID", minWidth: 110, getApplyQuickFilterFn: undefined  },
  {
    field: "date",
    headerName: "Joining Date",
    minWidth: 150,
    getApplyQuickFilterFn: undefined,
    renderCell: (params: any) => {
      return (
        <Typography sx={{ fontSize: "1.5rem", color: "#333333" }}>
          {params?.row?.joinDate}
        </Typography>
      );
    },
  },
  // {
  //   field: "full_name",
  //   headerName: "Name",
  //   minWidth: 150,
  // },
  {
    field: "coach",
    headerName: "Coach",
    minWidth: 200,
    flex: 1,
    renderCell: (params: any) => {
      const image = params?.row?.profile_photo;
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Box
            component="img"
            src={image ? image : ProfileImg}
            sx={{
              width: "4.4rem",
              minWidth: "4.4rem",
              height: "4.4rem",
              borderRadius: "1rem",
              objectFit: "cover",
            }}
          />
          <Box>
            <Typography
              className="truncate w-[20rem]"
              sx={{
                fontSize: "1.7rem",
                fontWeight: 600,
                lineHeight: 1.7,
                mb: "6px",
              }}
            >
              {params?.value}
            </Typography>
            <Typography
              sx={{ fontSize: "1.4rem", fontWeight: 500, lineHeight: 1 }}
            >
              {/* {params?.row?.email} */}
              Coach
            </Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    field: "email",
    headerName: "Contact",
    flex: 1,
    renderCell: (params: any) => {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Box component="img" src={EmailIcon} sx={{ width: "3.4rem" }} />
          <Typography sx={{ fontSize: "1.4rem", fontWeight: 500 }}>
            {params?.value}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: "clients",
    headerName: "Clients",
    // flex: 1,
    renderCell: (params: any) => {
      return (
        <Typography sx={{ fontSize: "1.4rem", fontWeight: 500, ml: "3rem" }}>
          {params?.value}
        </Typography>
      );
    },
  },
  {
    field: "rank",
    headerName: "Rank",
    flex: 0.5,
    minWidth: 80,
    renderCell: (params: any) => {
      return (
        <Typography sx={{ fontSize: "1.4rem", fontWeight: 500, }}>
          {params?.value}
        </Typography>
      );
    },
  },
  {
    field: "action",
    headerName: "",
    minWidth: 50,
    flex: 0.3,
    sortable: false,
    getApplyQuickFilterFn: undefined,
    renderCell: (params: any) => {
      return <ManageUserMenu user={params?.row?.user} refetchAction={params?.row?.refetchAction} type="coach" />;
    },
  },
];

export default function CoachTable() {
  const { data, isLoading, refetch: refetchCoachList } = useGetAllCoachesLeaderboardQuery(1, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const normalData = data?.data?.users?.map((user:any) => {
    return {
      id: user.id,
      email: user.email,
      coach: user?.full_name,
      clients: user?.clients?.length,
      joinDate: dayjs(user?.createdAt).format("MMM DD, YYYY, hh:mm A"),
      profile_photo: user?.profile_photo,
      rank: user?.rank,
      user: user,
      refetchAction: refetchCoachList,
      date: user?.createdAt
    }
  })

  const CustomToolbar = () => {
    return (
      <Paper sx={{ marginBottom: "4px", marginTop: "12px", backgroundColor: "transparent" }} elevation={0}>
        <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
          <GridToolbarQuickFilter sx={{
            // backgroundColor: "#f1f0ed",
            backgroundColor: "#fff",
            boxShadow: "0px 2px 6px rgb(0 0 0 / 6%)",
            paddingBottom: 0,
            borderRadius: "7px",
            "& .MuiInput-root": {
              padding: "0 10px",
              border: "1px solid rgba(0, 0, 0, 0.12)",
              borderRadius: "7px",
              "&:before, &:after": {
                display: "none"
              },
              "&.Mui-focused": {
                border: "1px solid #3A48A0",
              }
            },
            "& .MuiInputBase-inputTypeSearch::placeholder": {
              fontSize: "16px",
            },
            "& .MuiSvgIcon-root": {
              fontSize: "20px"
            },
            "& .MuiInputBase-input":{
              fontSize: "16px"
            },
            "& .MuiButtonBase-root":{
              padding: "2px"
            }
          }} />
        </GridToolbarContainer>
      </Paper>
    );
  };
  
  return (
    <div style={{ height: "74vh", width: "100%", marginTop: "4rem" }}>
      <DataGrid
        rows={normalData || []}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
        //checkboxSelection
        sx={StyledDataGrid}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableExtendRowFullWidth
        disableSelectionOnClick
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <Typography
                sx={{ color: "#2165A2", fontSize: "2rem", fontWeight: 600 }}
              >
                {isLoading
                  ? "Loading..."
                  : data?.data?.users?.length === 0 && "No Coaches Data Found"}
              </Typography>
            </Stack>
          ),
          Toolbar: CustomToolbar
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'rank', sort: 'desc' }],
          }
        }}
      />
    </div>
  );
}
