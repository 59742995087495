import { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Box, TextField, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material";
import InputField from "./Components/InputField";
import UploadBox from "./Components/UploadBox";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import apiEndpoint from "../../config/environment";
import { toast } from "react-toastify";
import {
  useUploadCourseMutation,
  isFetchBaseQueryError,
} from "../../services/admin";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../components/ProgressBar";
import ReactPlayer from "react-player";

interface IFormInputs {
  image: string;
  video: string;
  title: string;
  detail: string;
  link: string;
}

const schema = yup.object().shape({
  image: yup.string().required("Required"),
  video: yup.string().required("Required"),
  title: yup.string().required("Required"),
  detail: yup.string().required("Required"),
  link: yup.string().required("Required")
});

const UploadCourse = () => {
  const [progress, setProgress] = useState(0);
  const [progressVideo, setProgressVideo] = useState(0);
  const [fileName, setFileName] = useState("");
  const [videoName, setVideoName] = useState("");
  const theme = useTheme();
  const [image, setImage] = useState({
    name: "",
    file: {},
    isUploading: false,
    url: "",
  });
  const [video, setVideo] = useState({
    name: "",
    file: {},
    isUploading: false,
    url: "",
  });
  const [uploadCourse, { isLoading, isSuccess }] =
    useUploadCourseMutation();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      image: "",
      video: "",
      title: "",
      detail: "",
      link: "",
    },
  });

  const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
    const payload = {
      title: data.title,
      thumbnail: data.image,
      file: data.video,
      detail: data.detail,
      link: data.link
    };

    try {
      await uploadCourse(payload).unwrap();
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error.data.message);
      }
    }
  };

  if (isSuccess) {
    toast.success("Course Upload Successfully");
    navigate("/resources-library");
  }

  const handleUploadImage = async (e: any) => {
    setProgress(0);
    setFileName("");
    if (!e.target.files) {
      return;
    }
    const file = e.target?.files[0];
    setImage({
      ...image,
      name: file.name,
      file: file,
      isUploading: true,
    });
    setFileName(file?.name);
    const formData = new FormData();
    formData.append("image", file);

    const promise = axios
      .request({
        method: "POST",
        url: `${apiEndpoint}/upload-one`,
        data: formData,
      })
      .then((res) => {
        setProgress(100);
        setImage({
          ...image,
          isUploading: false,
          url: res.data,
        });
        setValue("image", res.data);
        trigger("image");
      })
      .catch(() => {
        setProgress(0);
        setImage({
          ...image,
          isUploading: false,
        });
      });

    await toast.promise(promise, {
      pending: "Uploading Thumbnail",
      success: "Thumbnail Uploaded",
      error: "Error Uploading Thumbnail",
    });
  };

  const handleUploadVideo = async (e: any) => {
    setProgressVideo(0);
    setVideoName("");
    if (!e.target.files) {
      return;
    }
    const file = e.target?.files[0];
    setVideo({
      ...video,
      name: file.name,
      file: file,
      isUploading: true,
    });
    setVideoName(file?.name);
    const formData = new FormData();
    formData.append("video", file);

    const promise = axios
      .request({
        method: "POST",
        url: `${apiEndpoint}/upload-video`,
        data: formData,
      })
      .then((res) => {
        setProgressVideo(100);
        setVideo({
          ...video,
          isUploading: false,
          url: res.data,
        });
        setValue("video", res.data);
        trigger("video");
      })
      .catch(() => {
        setProgressVideo(0);
        setVideo({
          ...video,
          isUploading: false,
        });
      });

    await toast.promise(promise, {
      pending: "Uploading Video",
      success: "Video Uploaded",
      error: "Error Uploading Video",
    });
  };

  console.log("Image -->", image?.url)

  return (
    <Layout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ px: "2.2rem", py: "1.8rem" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "3.8rem",
              mb: "4rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "3.5rem",
                color: theme.palette.secondary.main,
                fontWeight: 600,
              }}
            >
              Upload Course
            </Typography>
          </Box>

          <Box sx={{ maxWidth: "75rem", mb: "2rem" }}>
            <Box sx={{ mb: "2rem" }}>
              <InputField name="title" title="Title" register={register} fullWidth={true} />
              {errors?.title?.message ? (
                <p className="text-red-500 "> {errors.title.message} </p>
              ) : null}
            </Box>
            <Box sx={{ mb: "4.7rem" }}>
              <InputField name="link" title="GHL Course Link" register={register} fullWidth={true} />
              {errors?.link?.message ? (
                <p className="text-red-500 "> {errors?.link?.message} </p>
              ) : null}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box>
                  <UploadBox
                    name="video"
                    type="video"
                    uploadTitle="Browse Video To Upload"
                    accept="video/*"
                    fileName={video.name}
                    progress={100}
                    status={"Completed"}
                    isUploading={video.isUploading}
                    showImages
                    handleFileChange={handleUploadVideo}
                    register={register}
                  />
                  {errors?.video?.message ? (
                    <p className="text-red-500 "> {errors.video.message} </p>
                  ) : null}
                </Box>
                <Box>
                  {progressVideo === 100 && (
                    <ProgressBar
                      percentage={progressVideo}
                      name={videoName}
                      status="Completed"
                    />
                  )}
                </Box>
                {video?.url && (
                  <Box
                    sx={{
                      width: "32rem",
                      height: "20rem",
                      mt: "20px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      bgcolor: "#8080803d",
                      overflow: "hidden"
                    }}
                  >
                    <ReactPlayer url={video?.url} controls muted width={"100%"} height={"20rem"} />
                  </Box>
                )}
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box>
                  <UploadBox
                    name="image"
                    accept="image/*"
                    uploadTitle="Browse Thumbnail To Upload"
                    type="photo"
                    fileName="Image12.png"
                    progress={100}
                    status="completed"
                    isUploading={image.isUploading}
                    showImages
                    handleFileChange={handleUploadImage}
                    register={register}
                  />
                  {errors?.image?.message ? (
                    <p className="text-red-500"> {errors.image.message} </p>
                  ) : null}
                </Box>
                <Box>
                  {progress === 100 && (
                    <ProgressBar
                      percentage={progress}
                      name={fileName}
                      status="Completed"
                    />
                  )}
                </Box>
                {image?.url && (
                  <Box>
                    <Box
                      component="img"
                      src={image?.url}
                      sx={{
                        width: "32rem",
                        height: "20rem",
                        mt: "20px",
                        borderRadius: "10px",
                        objectFit: "cover"
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <Box sx={{ maxWidth: "75rem", mb: "4rem" }}>
            <Typography
              sx={{
                fontSize: "2.02697rem",
                fontWeight: 600,
                color: "#333333",
                mb: "1rem",

              }}
            >
              Details
            </Typography>
            <Controller
              control={control}
              name="detail"
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Enter Details..."
                  multiline
                  minRows={5}
                  variant="outlined"
                  sx={{
                    width: "100%",
                    "& > .MuiOutlinedInput-root": {
                      borderRadius: "1rem",
                      fontSize: "2rem",
                      backgroundColor: "#fcfcfb",
                      border: "none",
                      boxShadow: "0px 1px 4px rgb(0 0 0 / 10%)",
                    },
                    "& .MuiFormHelperText-root": {
                      color: "red",
                      fontSize: "1.4rem",
                      ml: 0,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#fff",
                    },
                  }}
                  helperText={errors.detail ? errors.detail?.message : null}
                />
              )}
            />
          </Box>

          <Box sx={{ mb: "7rem" }}>
            <Button
              type="submit"
              disabled={image.isUploading || video.isUploading || isLoading}
              sx={{
                background: "#2165A2",
                borderRadius: "0.485rem",
                height: "4.5rem",
                width: "18rem",
                color: "#fff",
                fontSize: "1.8rem",
                textTransform: "capitalize",
                "&:hover": {
                  background: "#2165A2",
                },
                "&.Mui-disabled": {
                  opacity: 0.7,
                  color: "#fff",
                },
              }}
            >
              {isLoading ? "Loading..." : "Upload Course"}
            </Button>
          </Box>
        </Box>
      </form>
    </Layout>
  );
};

export default UploadCourse;
