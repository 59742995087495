import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { inviteUser } from "../../../apis/auth-apis";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "95rem",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  px: "7.4rem",
  py: "3.4rem",
  borderRadius: "1rem",
  outline: "none",
};

interface InviteUserModalProps {
  open: boolean;
  setOpen: (state: boolean) => void;
}
interface IFormInputs {
  email: string;
}

const schema = yup.object().shape({
  email: yup.string().email().required("Email is Required"),
});

const InviteUserModal = ({ open, setOpen }: InviteUserModalProps) => {
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit: SubmitHandler<IFormInputs> = (data: IFormInputs) => {

    const payload = {
      email: data.email,
      type: "coach"
    }


    inviteUser(payload, setLoading).then(() => {
      reset();
      setOpen(false);
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={handleClose}
        >
          <Typography
            sx={{ fontSize: "2.8rem", fontWeight: 600, color: "#333333" }}
          >
            Invite Coach
          </Typography>
          <IconButton>
            <CloseIcon sx={{ fontSize: "2.6rem" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            minHeight: "44rem",
            width: "100%",
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                position: "absolute",
                top: "40%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Typography
                sx={{
                  fontSize: "2.8rem",
                  fontWeight: 400,
                  color: "#333333",
                  mb: "2.4rem",
                }}
              >
                Enter email to send link
              </Typography>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputProps={{
                      endAdornment: (
                        <Button type="submit" disabled={loading}>
                          {loading == true ? "Loading..." : "Send Link"}
                        </Button>
                      ),
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        width: "58rem",
                        height: "6.5rem",
                        borderRadius: "1rem",
                        overflow: "hidden",
                        pr: 0,
                        borderColor: "#2165A2",
                        "& .MuiInputBase-input": {
                          fontSize: "2rem",
                          fontWeight: 400,
                          px: "2.8rem",
                          borderColor: "#2165A2",
                        },
                      },
                      "& .MuiButtonBase-root": {
                        backgroundColor: "#2165A2",
                        color: "#fff",
                        height: "100%",
                        width: "26rem",
                        borderRadius: 0,
                        fontSize: "2.2rem",
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: "#2165A2",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#2165A2",
                      },
                      "& .MuiFormHelperText-root": {
                        fontSize: "1.4rem",
                      },
                    }}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email?.message : null}
                  />
                )}
              />
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default InviteUserModal;
