import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Box, CardActionArea, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { useState } from "react";
import { classes } from "../../../../styles/ResourcesLibraryStyle";
import AudioModal from "./AudioModal";


const PodcastCard = ({
  title,
  date,
  duration,
  thumbnail,
  desc,
  audio }: any) => {
  const [openModal, setOpenModal] = useState(false);

  function handleModal() {
    setOpenModal(true);
  }
  return (
    <>
      <Card sx={classes.Card} onClick={handleModal} >
        <CardActionArea sx={classes.CardActionArea}>
          <Box sx={classes.CardActionAreaBox}>
            <CardMedia
              sx={{
                width: "100%",
                height: "18rem",
                borderRadius: "1rem",
                objectFit: "cover"
              }}
              component="img"
              image={thumbnail}
              alt="RecentHuddleImg"
            />
            <PlayCircleOutlineIcon sx={classes.PodcastPlayIcon} />
            <Typography sx={classes.RecentHuddleCardActionAreaTypo}>
              {duration}
            </Typography>
          </Box>
          <CardContent sx={classes.CardContent}>
            <Grid marginTop="0rem" container sx={{ alignItems: "flex-end" }} spacing="0.5rem">
              <Grid item xs={8}>
                <Typography sx={classes.CardTitle}>
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={classes.PodCastCardDate}>{dayjs(date).format("MM-DD-YYYY")}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>

      <AudioModal
        open={openModal}
        setOpen={setOpenModal}
        videoUrl={audio}
        title={title}
        description={desc}
      />
    </>
  );
};

export default PodcastCard;
