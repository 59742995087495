import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Box,
  IconButton,
  MenuItem,
} from "@mui/material";
import { classes } from "../../../../styles/ResourcesLibraryStyle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledMenu } from "../../../../styles/StyledMenu";
import dayjs from "dayjs";
import PlayVideoModal from "./PlayVideoModal";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ConfirmModal from "./ConfirmModal";

const RecentHuddleCard = ({
  id,
  title,
  detail,
  thumbnail,
  video,
  date,
  onDelete,
  onUpdate
}: any) => {
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = () => setOpenModal(true);

  function handleDelete() {
    setIsConfirmOpen(true);
  }

  return (
    <Card sx={classes.Card}>
      <Box sx={classes.CardActionArea}>
        <Box sx={classes.CardActionAreaBox}>
          <CardMedia
            sx={classes.RecentHuddleCardMedia}
            component="img"
            image={thumbnail}
            alt="RecentHuddleImg"
          />
        </Box>
        <CardContent sx={classes.CardContent}>
          <Typography gutterBottom sx={classes.CardTitle}>
            {title}
          </Typography>
          <Typography sx={classes.CoursesCardUserName}>{detail}</Typography>

          <Box
            sx={{
              mt: "1.4rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "1.6rem", color: "#333333" }}>
              {dayjs(date).format("MM-DD-YYYY")}
            </Typography>
            <Box>
              <IconButton
                aria-label="delete"
                sx={{
                  p: 0,
                  boxShadow: "0px 2px 6px rgb(0 0 0 / 9%)",
                  borderRadius: "2px",
                }}
                onClick={handleClick}
              >
                <MoreVertIcon sx={{ color: "#2165A2", fontSize: "3.2rem" }} />
              </IconButton>
            </Box>
          </Box>
        </CardContent>
      </Box>

      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleOpenModal();
            handleClose();
          }}
        >
          <Box>
            <Typography>View</Typography>
            <VisibilityIcon />
          </Box>
        </MenuItem>
        {onUpdate ? (
          <MenuItem
            onClick={() => {
              onUpdate(id);
              handleClose();
            }}
          >
            <Box>
              <Typography>Update</Typography>
              <BorderColorIcon />
            </Box>
          </MenuItem>
        ) : ""}
        <MenuItem onClick={handleClose}>
          <Box onClick={() => handleDelete()}>
            <Typography>Delete</Typography>
            <DeleteIcon />
          </Box>
        </MenuItem>
      </StyledMenu>

      <PlayVideoModal
        open={openModal}
        setOpen={setOpenModal}
        videoUrl={video}
        title={title}
        description={detail}
      />

      <ConfirmModal
        onDelete={onDelete}
        id={id}
        isConfirmOpen={isConfirmOpen}
        setIsConfirmOpen={setIsConfirmOpen}
        title={title}
      />
    </Card>
  );
};

export default RecentHuddleCard;
