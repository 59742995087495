import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import Layout from "../../components/Layout/Layout";
import classes from "../../styles/CheckinFormStyle";
import AddIcon from "@mui/icons-material/Add";
import CreateEvent from "./components/CreateEvent";

import ManageEventsCard from "./components/ManageEventsCard";
import { useGetAllEventsQuery } from "../../services/admin";

const ManageEvents = () => {
  const { data, isLoading } = useGetAllEventsQuery(1, {
    refetchOnMountOrArgChange: true,
  });
  const [showCreateEvent, setShowCreateEvent] = React.useState(false);
  const toggleComponent = () => {
    setShowCreateEvent((showCreateEvent) => !showCreateEvent);
  };

  return (
    <Layout>
      {showCreateEvent ? (
        <CreateEvent toggleComponent={toggleComponent} />
      ) : (
        <>
          <Box className="flex justify-between items-center">
            <Typography variant="h2" component="h2">
              Upcoming Events
            </Typography>
            <Button
              variant="contained"
              sx={classes.createFormBtn}
              onClick={toggleComponent}
            >
              <AddIcon fontSize="large" sx={{ marginRight: "1rem" }} /> Create
              Event
            </Button>
          </Box>
          {isLoading ? (
            <h4 className="text-center text-[3rem] my-10">Loading...</h4>
          ) : data?.data?.events.length === 0 ? <h1 className="text-center text-[#2165A2] text-[2.4rem] font-bold mt-72">
            No Events Found
          </h1> : (
            <Grid container spacing="3.5rem" marginTop="1rem">
              {data?.data?.events?.map((event: any, index: number) => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <ManageEventsCard
                      id={event.id}
                      title={event.title}
                      description={event.description}
                      date={event.date}
                      time={event.time}
                      location={event.location}
                      photos={event.eventPhotos}
                      members={event.members}
                      status={event.status}
                      formLink={event.ghlForm}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </>
      )
      }
    </Layout >
  );
};

export default ManageEvents;
