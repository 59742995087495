import { Box, Grid, Pagination, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetYoutubeVideosQuery } from "../../../../services/general";
import YoutubeTabCard from "./YoutubeTabCard";

const YouTube = () => {
  const [value] = useState<number>(0);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [pageToken, setPageToken] = useState("");
  const {
    data: youtubeData,
    refetch,
  } = useGetYoutubeVideosQuery({ pageToken: pageToken });
  const youtubeVids = youtubeData?.data?.items?.items;
  const nextPageToken = youtubeData?.data?.items?.nextPageToken;
  const prevPageToken = youtubeData?.data?.items?.prevPageToken;

  const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    if (value === 1) {
      setPageToken('');
    } else if (value > currentPage || youtubeData?.data?.items?.nextPageToken) {
      setPageToken(nextPageToken);
    } else if (value < currentPage || value <= parseInt(youtubeData?.data?.items?.pageInfo?.totalResults)) {
      setPageToken(prevPageToken);
    }
  };

  useEffect(() => {
    refetch();
  }, [pageToken, refetch]);

  return (
    <>

      {youtubeVids?.length === 0 && value === 0 && (
        <Paper sx={{ mt: "2rem", px: "2rem", py: "6rem", textAlign: "center" }}>
          <Typography
            sx={{ fontSize: "2.8rem", fontWeight: 600, color: "#2165A2" }}
          >
            No Videos Found
          </Typography>
        </Paper>
      )}

      <Grid container spacing="2rem" sx={{ mb: "5rem" }} marginTop="0rem">
        {youtubeVids?.map((item: any, index: number) => {
          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <YoutubeTabCard data={item} />
            </Grid>
          );
        })}
      </Grid>

      <Box
        sx={{
          display: "flex",
          mt: "8rem",
          gap: "3rem",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
          cursor: "pointer",
        }}
      >
        <Pagination
          size="large"
          onChange={handleChange}
          count={
            Math.round(
              parseInt(youtubeData?.data?.items?.pageInfo?.totalResults) / 10
            ) || 0
          }
          color="primary"
        />
      </Box>
    </>
  );
};
export default YouTube;
