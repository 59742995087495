import React, { useEffect } from "react";
import {
  Button,
  Grid,
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import UploadImage from "./UploadImage";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { ThemeProvider } from "@mui/material/styles";
import type { } from "@mui/x-date-pickers/themeAugmentation";
import { useGetAllUsersQuery } from "../../../services/admin";
import Chip from "@mui/material/Chip";
import { toast } from "react-toastify";
import {
  useUpdateEventMutation,
  isFetchBaseQueryError,
  useRemoveEventMemberMutation,
} from "../../../services/admin";
import dateInputStyle from "../../../styles/DateInputStyles";
import calendarStyle from "../../../styles/CalendarStyle";
import customTimeTheme from "../../../styles/TimeStyle";
import { useParams } from "react-router-dom";
import { useGetEventByIdQuery } from "../../../services/admin";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(isSameOrBefore)

interface IFormInputs {
  title: string;
  description: string;
  date: string;
  time: string;
  location: string;
  start: string;
  end: string;
  users: unknown;
}

const schema = yup.object().shape({
  title: yup.string().required("Event title is required"),
  description: yup.string().required("Description title is required"),
  location: yup.string().required("Location is required"),
  date: yup.string(),
  start: yup.string(),
  end: yup.string(),
  users: yup.array(),
});

const EditForm = ({ goBack }: any) => {
  const { id } = useParams();
  const [startTimeValue, setStartTimeValue] = React.useState<Dayjs | null>(
    null
  );
  const [endTimeValue, setEndTimeValue] = React.useState<Dayjs | null>(null);
  const [dateValue, setDateValue] = React.useState<Dayjs | null>(null);
  const [currentUsers, setCurrentUsers] = React.useState<any[]>([]);

  const { data, isFetching } = useGetAllUsersQuery(1, { refetchOnFocus: true, refetchOnMountOrArgChange: true });
  const [updateEvent, { isLoading }] = useUpdateEventMutation();
  const [removeEventMember, { isLoading: Loading }] = useRemoveEventMemberMutation();
  const { data: eventData, isFetching: eventFetched } = useGetEventByIdQuery(id, { refetchOnFocus: true, refetchOnMountOrArgChange: true });
  const event = eventData?.data?.event;
  const eventMembers = eventData?.data?.event?.members?.map((member: any) => {
    return {
      ...member,
    };
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const [userName, setUserName] = React.useState<any[]>([]);
  const [userIds, setUserIds] = React.useState<string[]>([]);
  const [images, setImages] = React.useState<string[]>([]);

  useEffect(() => {
    setValue("title", event?.title);
    setValue("description", event?.description);
    setValue("location", event?.location);
    if (event?.time) {
      const time = event.time.split(" - ");
      setStartTimeValue(dayjs(time[0], "HH:mm A"));
      setEndTimeValue(dayjs(time[1], "HH:mm A"));
    }
    if (event?.date) {
      setDateValue(dayjs(event?.date));
    }
  }, [eventFetched]);

  const currentUsersHandler = () => {
    if (data) {
      const temp = data?.data?.users?.filter(function (cv: any) {
        return !eventMembers?.find(function (e: any) {
          return e?.email == cv?.email;
        });
      });
      setCurrentUsers([...temp]);
    }
  };

  useEffect(() => {
    if (data) {
      currentUsersHandler();
    }
  }, [isFetching]);

  const handleSelectChange = (event: any) => {
    const {
      target: { value },
    } = event;
    const temp = value?.map((user: any) => {
      return {
        email: user.email,
        full_name: user.full_name,
        phone: user.phoneNo,
        isAttending: "YES",
      };
    });
    setUserName(typeof value === "string" ? value.split(",") : value);
    setUserIds([...temp]);
  };

  const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
    const _startTime = new Date(dayjs(startTimeValue)?.toDate()).getTime();
    const _endTime = new Date(dayjs(endTimeValue)?.toDate()).getTime()
    const diff = _endTime - _startTime;

    if (_endTime < _startTime) {
      toast.error("End time should be greater than start time")
      return
    }
    if (diff <= 0) {
      toast.error("End time should be greater than start time")
      return
    }

    // Get the current date
    const currentDate = dayjs();
    // Parse the input time in dayjs object
    const inputDate = dayjs(dateValue);
    const response = inputDate.isAfter(currentDate);
    if (!response) {
      const currentTime = new Date(dayjs()?.toDate()).getTime();
      if (_startTime < currentTime) {
        toast.error("Please select future time");
        return;
      }
    }

    const startTime = dayjs(startTimeValue).format("hh:mm A");
    const endTime = dayjs(endTimeValue).format("hh:mm A");
    const time = `${startTime} - ${endTime}`;
    const photos = images?.map((image) => {
      return {
        url: image,
      };
    });
    const members = userIds;

    const payload = {
      title: data.title,
      description: data.description,
      date: dateValue,
      time: time,
      location: data.location,
      photos: photos,
      members: members,
    };

    try {
      await updateEvent({ id: id, payload: payload })
        .unwrap()
        .then(() => {
          toast.success("Event Updated Successfully");
          goBack();
        });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error.data.message);
      }
    }
  };

  const handleRemoveUser = async (email: string) => {
    const payload = {
      eventId: id,
      userEmail: email,
    };

    try {
      await removeEventMember({ payload: payload })
        .unwrap()
        .then(() => {
          toast.success("Participant Removed");
          currentUsersHandler();
        });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error.data.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-8">
        <h6 className="text-3xl font-semibold">Name Of Event</h6>
        <Controller
          control={control}
          name="title"
          render={({ field }) => (
            <input
              {...field}
              type="text"
              className="bg-[#A2A3AC0A] px-10 text-[1.6rem] mt-5 border border-[#D0D6DE] rounded-3xl font-medium outline-none h-24 w-full"
              placeholder="Title"
            />
          )}
        />
        {errors?.title && (
          <p style={{ fontSize: "10px", color: "red" }}>
            {errors?.title?.message}
          </p>
        )}
      </div>

      <div className="mb-8">
        <h6 className="text-3xl font-semibold">Description Of Event</h6>
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <input
              {...field}
              type="text"
              className="bg-[#A2A3AC0A] px-10 text-[1.6rem] mt-5 border border-[#D0D6DE] rounded-3xl font-medium outline-none h-24 w-full"
              placeholder="Title"
            />
          )}
        />
        {errors?.description && (
          <p style={{ fontSize: "10px", color: "red" }}>
            {errors?.description?.message}
          </p>
        )}
      </div>

      <Grid container spacing="2rem">
        <Grid item xs={6}>
          <h6 className="text-3xl font-semibold">Date</h6>
          <Box sx={{ mt: "1.2rem" }}>
            <Controller
              control={control}
              name="date"
              defaultValue=""
              render={({ field: { onChange, ...restField } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label=""
                    {...restField}
                    value={dateValue}
                    disablePast
                    onChange={(event: any) => {
                      onChange(event);
                      setDateValue(event);
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        sx={dateInputStyle}
                        {...params}
                        onKeyDown={(e: any) => e.preventDefault()}
                      />
                    )}
                    PaperProps={{
                      sx: calendarStyle,
                    }}
                  />
                </LocalizationProvider>
              )}
            />
            {errors?.date && (
              <p style={{ fontSize: "10px", color: "red" }}>
                {errors?.date?.message}
              </p>
            )}
          </Box>
        </Grid>

        <Grid item xs={3}>
          <h6 className="text-3xl font-semibold">Starts At</h6>
          <Box sx={{ mt: "1.2rem" }}>
            <Controller
              control={control}
              name="start"
              defaultValue=""
              render={({ field: { onChange, ...restField } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <ThemeProvider theme={customTimeTheme}>
                    <TimePicker
                      {...restField}
                      value={startTimeValue}
                      onChange={(event: any) => {
                        onChange(event);
                        setStartTimeValue(event);
                      }}
                      renderInput={(params) => (
                        <TextField sx={dateInputStyle} {...params} onKeyDown={(e: any) => e.preventDefault()} />
                      )}
                      PopperProps={{
                        className: "custom-clock",
                      }}
                      componentsProps={{
                        leftArrowButton: {
                          size: "small",
                        },
                        rightArrowButton: {
                          size: "small",
                        },
                      }}
                    />
                  </ThemeProvider>
                </LocalizationProvider>
              )}
            />
            {errors?.start && (
              <p style={{ fontSize: "10px", color: "red" }}>
                {errors?.start?.message}
              </p>
            )}
          </Box>
        </Grid>

        <Grid item xs={3}>
          <h6 className="text-3xl font-semibold">Ends At</h6>
          <Box sx={{ mt: "1.2rem" }}>
            <Controller
              control={control}
              name="end"
              defaultValue=""
              render={({ field: { onChange, ...restField } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <ThemeProvider theme={customTimeTheme}>
                    <TimePicker
                      {...restField}
                      value={endTimeValue}
                      onChange={(event: any) => {
                        onChange(event);
                        setEndTimeValue(event);
                      }}
                      renderInput={(params) => (
                        <TextField sx={dateInputStyle} {...params} onKeyDown={(e: any) => e.preventDefault()} />
                      )}
                      PopperProps={{
                        className: "custom-clock",
                      }}
                      componentsProps={{
                        leftArrowButton: {
                          size: "small",
                        },
                        rightArrowButton: {
                          size: "small",
                        },
                      }}
                    />
                  </ThemeProvider>
                </LocalizationProvider>
              )}
            />
            {errors?.end && (
              <p style={{ fontSize: "10px", color: "red" }}>
                {errors?.end?.message}
              </p>
            )}
          </Box>
        </Grid>

        <Grid item xs={6}>
          <h6 className="text-3xl font-semibold">Add More Participants</h6>
          <div className="mt-5">
            <Box sx={selectStyle}>
              <FormControl>
                <Controller
                  control={control}
                  name="users"
                  render={({ field: { onChange, ...restField } }) => (
                    <Select
                      {...restField}
                      multiple
                      value={userName}
                      onChange={(event: any) => {
                        onChange(event);
                        handleSelectChange(event);
                      }}
                      displayEmpty
                      renderValue={(selected: any) => {
                        return (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value: any, index: number) => (
                              <Chip
                                key={index}
                                label={value.full_name}
                                sx={chipStyle}
                              />
                            ))}
                          </Box>
                        );
                      }}
                    >
                      {currentUsers?.map((user: any, index: number) => {
                        return (
                          <MenuItem
                            value={user}
                            sx={selectItemStyle}
                            key={index}
                          >
                            <Stack direction="column" gap={0}>
                              <Typography sx={{ fontSize: "1.6rem", maxWidth: "20rem" }}
                                className="truncate">{user.full_name}</Typography>
                              <Typography sx={{ fontSize: "1.4rem" }}>{user.email}</Typography>
                            </Stack>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
                {errors?.users && (
                  <p style={{ fontSize: "10px", color: "red" }}>
                    {errors?.users?.message}
                  </p>
                )}
              </FormControl>
            </Box>
          </div>
        </Grid>

        <Grid item xs={6}>
          <h6 className="text-3xl font-semibold">Location</h6>
          <Controller
            control={control}
            name="location"
            render={({ field }) => (
              <input
                {...field}
                type="text"
                className="bg-[#A2A3AC0A] px-10 text-[1.6rem] mt-5 border border-[#D0D6DE] rounded-3xl font-medium outline-none h-24 w-full"
                placeholder="Algeria Town LA"
              />
            )}
          />
          {errors?.location && (
            <p style={{ fontSize: "10px", color: "red" }}>
              {errors?.location?.message}
            </p>
          )}
        </Grid>

        <Grid item xs={12}>
          <h6 className="text-3xl font-semibold">Current Participants</h6>
          <div className="mt-5">
            <Box sx={selectStyle}>
              <Stack spacing={1} direction="row" sx={{ flexWrap: "wrap", gap: "1rem" }}>
                {event?.members?.map((item: any, index: any) => {
                  return (
                    <Chip
                      disabled={Loading}
                      key={index}
                      label={item?.full_name}
                      variant="outlined"
                      sx={{ ...chipStyle }}
                      onDelete={() => handleRemoveUser(item?.email)}
                    />
                  );
                })}
              </Stack>
            </Box>
          </div>
        </Grid>
      </Grid>

      <div className="mt-12 mb-10">
        <h6 className="text-3xl font-semibold">Upload Photos</h6>
        <UploadImage images={images} setImages={setImages} />
      </div>

      <Grid container spacing="2rem">
        <Grid item xs={7}>
          <Button
            type="submit"
            variant="contained"
            disableElevation
            disableTouchRipple
            fullWidth
            disabled={isLoading}
            className="!text-3xl !capitalize h-24 !rounded-2xl !mt-8"
          >
            Save & Continue
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditForm;

const selectStyle = {
  width: "100%",
  border: "none",
  ".MuiFormControl-root": {
    width: "100%",
    ".MuiInputBase-root ": {
      borderRadius: "1.6rem",
      borderColor: "#D0D6DE",
      overflow: "hidden",
      width: "100%",
      height: "6rem",
      "> svg": {
        fontSize: "4rem",
      },
    },
  },

  ".MuiSelect-select": {
    width: "100%",
    fontSize: "1.6rem",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#333333",
    background: "rgba(162, 163, 172, 0.04)",
  },
};

const selectItemStyle = {
  fontSize: "1.6rem",
  fontWeight: 400,
  lineHeight: "24px",
  color: "#333333",
  display: "block",
  borderBottom: "1px solid #D0D6DE",
  "&.Mui-selected": {
    backgroundColor: "rgb(33 101 162 / 44%)",
  },
};

const chipStyle = {
  borderRadius: "8px",
  fontSize: "1.4rem",
  fontWeight: 600,
};
