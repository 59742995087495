import React from 'react'
import FormCalender from './FormCalender'
import FormsTabs from './FormsTabs'
import dayjs from 'dayjs';

const oneWeekBefore = dayjs().subtract(7, 'day').format();

const TimeLineTabs = ({ answers, loading, handleDownload }: any) => {
  const [startDate, setStartDate] = React.useState(new Date(oneWeekBefore));
  const [endDate, setEndDate] = React.useState(new Date());

  const handleClear = () => {
    setStartDate(new Date(oneWeekBefore));
    setEndDate(new Date());
  };

  const sortedAnswers = [...answers]?.sort((a: any, b: any) => {
    const start = new Date(a?.date).getTime();
    const end = new Date(b?.date).getTime();
    return end - start
  });

  return (
    <div className='py-[4rem] pb-0 px-[4rem] tablet:px-[6rem] rounded-xl'>
      <div className="flex flex-row justify-between mb-16">
        <div>
          <button
            onClick={handleDownload}
            className="flex items-center bg-[#2165A2] px-10 py-6 gap-3 rounded-2xl disabled:bg-opacity-70"
            disabled={loading}
          >
            <span className="text-white text-4xl font-medium">
              {loading ? "Downloading PDF..." : "Download PDF"}
            </span>
          </button>
        </div>
        <div>
          <FormCalender
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            handleClear={handleClear}
          />
        </div>
      </div>
      <FormsTabs
        answers={sortedAnswers}
        startDate={startDate}
        endDate={endDate}
        handleClear={handleClear}
      />
    </div>
  )
}

export default TimeLineTabs