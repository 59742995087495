import { Box, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ProfileImg from "../../../assets/images/profile.png";
import { useGetAllClientsQuery } from "../../../services/admin";
import { StyledDataGrid } from "../../../styles/StyledDataGrid";
import numberWithCommas from "../../../utilities/commafunction";
import UserMenu from "./Menu";

const columns: GridColDef[] = [
  {
    field: "full_name",
    headerName: "Client",
    flex: 0.8,
    renderCell: (params: any) => {
      const image = params?.row?.profile_photo;
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Box
            component="img"
            src={image ? image : ProfileImg}
            sx={{
              width: "4.4rem",
              height: "4.4rem",
              borderRadius: "1rem",
              objectFit: "cover",
            }}
          />
          <Box>
            <Typography
              className="truncate"
              sx={{
                maxWidth: "30rem",
                fontSize: "1.7rem",
                fontWeight: 600,
                lineHeight: 1,
                mb: "6px",
              }}

            >
              {params?.row?.full_name}
            </Typography>
            <Typography
              sx={{ fontSize: "1.4rem", fontWeight: 500, lineHeight: 1 }}
            >
              {params?.row?.email}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    field: "createdAt",
    headerName: "Coach",
    minWidth: 200,
    sortable: false,
    renderCell: (params: any) => {
      return (
        <Typography
          sx={{ fontSize: "1.5rem", color: "#2165A2", fontWeight: "700" }}
        >
          {params?.row?.coach?.full_name}
        </Typography>
      );
    },
  },
  { field: "winRating", headerName: "Win Rating", flex: 0.5, minWidth: 80, renderCell: (params: any) => {
    return (
      <Typography sx={{ fontSize: "1.6rem", fontWeight:700, color:"#333333"}}>
        {numberWithCommas(params?.row?.winRating)}
      </Typography>
    );
  },  },
  // { field: "rank", headerName: "Rank", flex: 0.5, minWidth: 80 },
  {
    field: "action",
    headerName: "",
    maxWidth: 50,
    sortable: false,
    renderCell: (params: any) => {
      return <UserMenu user={params?.row} />;
    },
  },
];


export default function LeaderBoardClientTable() {
  const { data, isLoading } = useGetAllClientsQuery(1, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <div style={{ height: "74vh", width: "100%", marginTop: "4rem" }}>
      <DataGrid
        rows={data?.data?.clients || []}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        // checkboxSelection
        sx={StyledDataGrid}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableExtendRowFullWidth
        disableSelectionOnClick
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <Typography
                sx={{ color: "#2165A2", fontSize: "2rem", fontWeight: 600 }}
              >
                {isLoading
                  ? "Loading..."
                  : data?.data?.clients.length === 0 && "No Clients Data Found"}
              </Typography>
            </Stack>
          ),
        }}
      />
    </div>
  );
}
