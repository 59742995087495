import { Typography } from "@mui/material";
import Layout from "../../components/Layout/Layout";
import ResourcesTabs from "./ResourcesTabs";

const ResourcesLibrary = () => {
	return (
		<Layout>
			<Typography variant="h2" sx={{ mb: 2 }}>Resources Library</Typography>
			<ResourcesTabs />
		</Layout>
	);
};

export default ResourcesLibrary;
