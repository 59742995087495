import Grid from "@mui/material/Grid";
import Layout from "../../components/Layout/Layout";
import UsageReportCard from "../../components/UsageReportCard";

const Dashboard = () => {
	return (
		<Layout>
			<Grid container spacing="2rem">
				<UsageReportCard />
			</Grid>
		</Layout>
	);
};

export default Dashboard;
