import { Grid } from "@mui/material";
import { useGetCoachNotesQuery } from "../../../services/admin";
import NoteCard from "./NoteCard";

const CoachNotes = () => {
  const { data, isLoading } = useGetCoachNotesQuery(1, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      <Grid container spacing="2rem" marginTop="1.5rem">
        <Grid item xs={12} sm={12}>
          {isLoading ? (
            <h4 className="text-center text-[3rem] my-10">Loading...</h4>
          ) : (
            data?.data?.notes?.map((item: any): any => (
              <NoteCard
                key={item?.id}
                image={item?.sender?.profile_photo}
                item={item}
                name={item?.sender?.full_name}
              />
            ))
          )}
          {data?.data?.notes?.length === 0 && (
            <h1 className="text-center text-[#2165A2] text-[2.4rem] font-bold mt-72">
              No Notes Found
            </h1>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CoachNotes;
