import {
	Box,
	Grid,
	TextField,
	Typography
} from "@mui/material";
import classes from "../../../styles/LogonStyle";

const Form = ({ user, type }: any) => {
	return (
		<Box sx={{ maxWidth: "70rem", margin: "0 auto", pb: "6rem" }}>
			<Grid container spacing="3.2rem">
				<Grid item xs={6} sx={classes.fromWrapper}>
					<Box>
						<Box component="div" sx={classes.form_blk}>
							<Typography
								variant="h6"
								sx={classes.formlabel}
							>
								Full Name
							</Typography>
							<TextField
								sx={classes.logonTextField}
								id="outlined-basic"
								variant="outlined"
								type="email"
								fullWidth
								value={user?.full_name}
								disabled
							/>
						</Box>
						<Box component="div">
							<Typography
								variant="h6"
								sx={classes.formlabel}
							>
								Role
							</Typography>
							<TextField
								sx={{ ...classes.logonTextField, textTransform: "uppercase" }}
								id="outlined-basic"
								variant="outlined"
								fullWidth
								value={user.role.join(" | ")}
								disabled
							/>
						</Box>

					</Box>
				</Grid>
				<Grid item xs={6} component="div" sx={classes.fromWrapper}>
					<Box>
						<Box component="div" sx={classes.form_blk}>
							<Typography
								variant="h6"
								sx={classes.formlabel}
							>
								Email
							</Typography>
							<TextField
								sx={classes.logonTextField}
								id="outlined-basic"
								variant="outlined"
								type="email"
								fullWidth
								value={user.email}
								disabled
							/>
						</Box>

						{user?.coach && (
							<Box component="div">
								<Typography
									variant="h6"
									sx={classes.formlabel}
								>
									Coach
								</Typography>
								<TextField
									sx={classes.logonTextField}
									id="outlined-basic"
									variant="outlined"
									fullWidth
									value={user?.coach?.full_name}
									disabled
								/>
							</Box>

						)}


					</Box>
				</Grid>
				{type === "client" && <Grid item xs={6} component="div" sx={classes.ContractEndDate}>

					<Box component="div" sx={classes.form_blk}>
						<Typography
							variant="h6"
							sx={classes.formlabel}
						>
							Contract End Date
						</Typography>
						<TextField
							sx={classes.logonTextField}
							id="outlined-basic"
							variant="outlined"
							type="email"
							fullWidth
							value={user.contractEndDate
							}
							disabled
						/>
					</Box>
				</Grid>}
			</Grid>
		</Box>
	);
};

export default Form;
