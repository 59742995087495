import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClientProfile from "../../../assets/images/ClientProfile.svg";
import { useGetNotificationsCountQuery } from "../../../services/general";
import { setNotiCount } from "../../../store/slices/auth.slice";
import { useNavigate } from "react-router";

const NotifyBlk = ({ item, user_img, markNotifications, socketArray, setTrigger, trigger }: any) => {
  const [readMark, setReadMark] = useState(false);
  const id = useSelector((state: any) => state?.auth?.user?.id);
  const [type, setType] = useState("")
  //get Notifications Count Api
  const { refetch } :any = useGetNotificationsCountQuery(
    id,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const handleMark = (e: any) => {
    setReadMark(e.target.checked);
    const paylaod = {
      id: item?.id,
      mark_as_read: e.target.checked,
    };
    markNotifications(paylaod);
    refetch()
      .unwrap()
      .then((res: any) => {
        dispatch(setNotiCount(res?.data?.count));
      });
  };
  useEffect(() => {
    setReadMark(item?.mark_as_read);
    if (trigger) {
      setTrigger(false);
    }
  }, [item, item?.mark_as_read, setTrigger, trigger]);

  // Setting the type of the notifications and getting from backend
  function handleType(type: any) {
    setType(type)
  }

  switch (true) {
      case type === "CALENDAR_DISCONNECTED":
        navigate("/schedule-call-calendar")
      break;
  }

  return (
    <div
      style={{
        background: readMark ? "#2165A233" : "#2166a251",
      }}
      className="cursor-pointer w-full bg-[#2165A233] rounded-[0.913342rem] pl-[2.466rem] pr-[2.878rem] pt-[1.96rem] pb-[1.688rem]"
    >
      <div className="flex justify-between">
        <div onClick={() => handleType(item?.type)} className="flex gap-[2.1rem] items-center">
          <div className="w-[6.485rem] h-[6.485rem] overflow-hidden rounded-full">
            <img
              src={user_img ? user_img : ClientProfile}
              alt="userimg"
              className="w-full h-full object-cover"
            />
          </div>
          <div>
            <h3 className="text-[#333333] text-[2.19202rem] font-semibold">
              {item?.title}
            </h3>
            <h5 className="text-[#333333] text-[1.64402rem] font-medium">
              {item?.body}
            </h5>
          </div>
        </div>
        <div className="mx-auto sm:mx-0 lg:w-[200px] text-right">
          <h3 className="text-[#333333] text-[1.64402rem] font-medium">
            {dayjs(item?.createdAt).format("ddd, MMM D, YYYY h:mm A")}
          </h3>
          <div style={{ display: socketArray?.length > 0 ? "none" : "block" }}>
            <div className="flex gap-4 mt-[0.5rem] items-center justify-end">
              <h3 className="text-[#333333] text-[1.64402rem] font-medium w-[14rem]">
                Mark as {readMark ? "Unread" : "read"}
              </h3>
              <label className="checkbox mb-[0.3rem]">
                <input
                  checked={readMark}
                  type="checkbox"
                  onChange={(e) => handleMark(e)}
                />
                <div className="checkbox__indicator"></div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotifyBlk;
