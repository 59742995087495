import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
	ResourcesTabStyledTabProps,
} from "../pages/ManageUser/components/ManageUserTab";
import theme from "../theme";
export const classes = {
	topBoxTypo: {
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "3.5rem",
		lineHeight: "5.2rem",
		color: "#333333",
	},
	antTabBox: {
		maxWidth: "max-content",
		marginLeft: "auto",
		[theme.breakpoints.down("md")]: {
			marginTop: "2rem",
		},
		".MuiTabs-flexContainer": {
			justifyContent: "center",
		},
	},
	youtubeAntTab: {
		maxWidth: "max-content",
		marginLeft: "2rem",
		[theme.breakpoints.down("md")]: {
			marginTop: "2rem",
		},
		".MuiTabs-flexContainer": {
			justifyContent: "start",
		},
	},
	antTabBoxLeaderBoard: {
		width: "max-content",
		marginLeft: "auto",
		marginTop: "-5rem",
		[theme.breakpoints.down("md")]: {
			marginTop: "2rem",
		},
	},
	TabPanelBox: {
		// p: 3,
	},
	RecentHuddlecardTitle: {
		marginTop: "2rem",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "2.1rem",
		lineHeight: "150%",
		color: "#2165A2",
	},
	RecentHuddlecardDescription: {
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: "1.8rem",
		lineHeight: "2.7rem",
		color: "#333333",
	},
};

export const AntTabs = styled(Tabs)({
	backgroundColor: "#2165A2",
	borderRadius: "1rem",
	padding: "0.5rem",
	minHeight: "4.8rem",
	alignItems: "center",
	// justifyContent: "center",
	"& .MuiTabs-indicator": {
		backgroundColor: "transparent",
	},
});

export const AntTab = styled((props: ResourcesTabStyledTabProps) => (
	<Tab disableRipple {...props} />
))(({ theme }) => ({
	textTransform: "none",
	borderRadius: "1rem",
	fontWeight: 600,
	// marginLeft: theme.spacing(0.5),
	// marginRight: theme.spacing(0.5),
	color: "#fff",
	fontSize: "1.6rem",
	fontStyle: "normal",
	minHeight: "4rem",
	minWidth: "17.3rem",
	padding: "0",
	[theme.breakpoints.down("md")]: {
		minWidth: "15rem",
	},
	"&:hover": {
		color: "#fff",
		opacity: 1,
	},
	"&.Mui-selected": {
		color: "#333333",
		backgroundColor: "white",
		fontWeight: 600,
	},
	"&.Mui-focusVisible": {
		backgroundColor: "#d1eaff",
	},
}));

