import { Box, Typography } from "@mui/material";
import DonePic from "../../assets/images/Done.svg";
import classes from "../../styles/LogonStyle";
import { useNavigate } from "react-router-dom";

const Done = () => {
	const navigate = useNavigate();

	return (
		<Box component="div" sx={classes.LogBlk}>
			<Box component="div" sx={classes.fromWrapperDone}>
				<Box sx={classes.donePic}>
					<img src={DonePic} alt="done" />
				</Box>
				<Typography sx={classes.fromWrapperDoneTypo}>
					Your Password is created successfully.Enjoy your favorite
					projects on Darkpaper
				</Typography>

				<Typography sx={{
					mt: "3rem",
					fontSize: "2rem",
					color: "#2165A2",
					cursor: "pointer",
					textDecoration: "underline",
					textUnderlineOffset: "5px"
				}} onClick={() => navigate("/login")}>Back to Login</Typography>
			</Box>
		</Box>
	);
};

export default Done;
