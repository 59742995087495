import { patchRequest, postRequest, getRequest } from "../config/axiosConfig";
import { toast } from "react-toastify";

export async function forgotPassword(
  email: string,
  setLoading: any,
  navigate: any
) {
  let result;
  try {
    setLoading(true);
    result = await postRequest("forgot-password", {
      email,
    });
    setLoading(false);
    toast.success(result?.message);
    navigate("/verification");
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    setLoading(false);
  }
  return result;
}

export async function verifyOtp(data: string, setLoading: any, navigate: any) {
  setLoading(true);
  let result;
  try {
    result = await postRequest("verify-otp", {
      otp: data,
    });
    setLoading(false);
    toast.success(result?.message);
    navigate(`/new-password/${data}`);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    setLoading(false);
  }
  return result;
}

export async function createPassword(
  value: any,
  password: string,
  confirmPassword: string,
  setLoading: any,
  navigate: any
) {
  let result;
  try {
    setLoading(true);
    result = await postRequest("reset-password", {
      otp: value,
      password,
      confirm_password: confirmPassword,
    });
    setLoading(false);
    toast.success(result?.message);
    navigate("/login");
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    setLoading(false);
  }
  return result;
}

export async function inviteUser(payload: any, setLoading: any) {
  let result;
  try {
    setLoading(true);
    result = await postRequest("invite-user", payload);
    setLoading(false);
    toast.success(result?.message);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    setLoading(false);
  }
  return result;
}
// Update User Profile Function
export async function updatedProfile(data: any, setLoading: any) {
  let result;
  try {
    setLoading(true);
    result = await patchRequest("admin/update", data);
    setLoading(false);
    toast.success(result?.message);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    setLoading(false);
  }
  return result;
}

// verify user through invite link
// export async function verify(token: any, navigate: any, setUserEmail: any) {
//   let result = null;
//   try {
//     result = await getRequest(`invite?key=${token}`);
//     setUserEmail(result?.data?.email);
//   } catch (error: any) {
//     toast.error(error?.response?.data?.message);
//     // navigate("/login")
//   }
//   return result;
// }

// get google token
export async function GoogleToken(code: string) {
  return getRequest(`admin/google/redirect?code=${code}`);
}

// get outlook token
export async function OutlookToken(code: string) {
  let result = null;
  try {
    result = await getRequest(`admin/outlook/redirect?code=${code}`);
  } catch (error: any) {
    // toast.error(error?.response?.data?.message);
    // navigate("/login")
  }
  return result;
}
//get client
export async function getClient(id: any) {
  const result = await getRequest(`user/${id}`);
  return result;
}
// revoke token
export async function RevokeGoogleToken(id: string) {
  return await postRequest(`revoke-token/${id}`, {});
}
// revoke outlook token
export async function RevokeOutlookToken(id: string) {
  return await postRequest(`revoke-outlook-token/${id}`, {});
}
