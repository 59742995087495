import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ResourcesTabStyledTabProps,
} from "../pages/ResourcesLibrary/ResourcesTabs";
import { ClientStyledTabProps } from "../pages/ResourcesLibrary/Client Resources/Index";
import { WinrateStyledTabProps } from "../pages/ResourcesLibrary/Winrate Resources";
import theme from "../theme";
export const classes = {
  topBoxTypo: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "3.5rem",
    lineHeight: "5.2rem",
    color: "#333333",
  },
  antTabBox: {
    maxWidth: "37.7rem",
    marginLeft: "auto",
    marginTop: "-5.3rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "2rem",
    },
  },
  TabPanelBox: {
    // p: 3,
  },
  CardTitle: {
    width: "calc(100% - 30px)",
    marginTop: "2rem",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "2.1rem",
    lineHeight: "150%",
    color: "#2165A2",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
    textTransform: "capitalize",
  },
  priceTag: {
    width: "calc(60% - 30px)",
    marginTop: "2rem",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "2rem",
    color: "#2165A2",
    lineHeight: "150%",
    textAlign: "end",
  },
  RecentHuddlecardDescription: {
    width: "100%",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1.8rem",
    lineHeight: "2.7rem",
    color: "#333333",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    height: "5.4rem",
  },
  Card: {
    p: 0,
    borderRadius: "2rem",
    boxShadow: "none",
    "&:hover": {
      color: "#fff",
      // opacity: 1,
    },
  },
  CardActionArea: {
    padding: "3rem",
  },
  CardActionAreaBox: {
    position: "relative",
  },
  RecentHuddleCardMedia: {
    width: "100%",
    height: "18rem",
    borderRadius: "1rem",
    objectFit: "cover",
    overflow: "hidden",
  },
  RecentHuddleVideocamIcon: {
    position: "absolute",
    left: 10,
    top: 0,
    color: "#fff",
    fontSize: "3.4rem",
  },
  RecentHuddleCardActionAreaTypo: {
    position: "absolute",
    bottom: 20,
    right: 15,
    background: "rgba(0, 0, 0, 0.8)",
    color: "#fff",
    padding: "0.3rem 1rem 0.1rem",
    borderRadius: "4px",
    lineHeigth: 0,
    fontSize: "1.38rem",
  },
  CardContent: {
    position: "relative",
    p: 0,
    "&:last-child": {
      pb: 0,
    },
  },
  TimeTypo: {
    position: "absolute",
    bottom: "-4px",
    right: 0,
    maxWidth: "9.3rem",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1.66444rem",
    lineHeight: "200%",
    color: "rgba(51, 51, 51, 0.6)",
  },
  CardMediaBox: {
    display: "flex",
    position: "absolute",
    bottom: 10,
    left: 10,
    alignItems: "flex-end",
    padding: "0.4rem 1rem 0.9rem 1.4rem",
    gap: "0.6rem",
    background: "#fff",
    borderRadius: "0.8rem",
  },
  CardMediaTypo: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1.2rem",
    lineHeight: "1.5rem",
    color: "#333333",
  },
  RatingIcon: {
    color: "#299AFF",
    fontSize: "2rem",
  },
  CoursesCardUserName: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "1.8rem",
    lineHeight: "2.8rem",
    color: "#1B2E42",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    height: "5rem",
  },
  CoursesCardRating: {
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1.4rem",
    lineHeight: "2.8rem",
    color: "#1B2E42",
  },
  CoursesCardStudentTypo: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1.4rem",
    lineHeight: "2.8rem",
    color: "#333333",
  },
  StackBox: {
    borderRadius: "50%",
    maxWidth: "3rem",
    maxHeight: "3rem",
  },
  Icon: {
    color: "#231F20",
    fontSize: "2.4rem",
  },
  PodCastCardDate: {
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1.6rem",
    lineHeight: "2.8rem",
    color: "#33333399",
  },
  PodcastPlayIcon: {
    position: "absolute",
    left: "40%",
    top: "40%",
    color: "#fff",
    fontSize: "5rem",
  },
  YoutubeTabPlayIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    color: "red",
    fontSize: "7rem",
    transform: "translate(-50%, -50%)",
  },
  YoutubeCardIcons: {
    fontSize: "2.4rem",
    color: "#18203A",
  },
  MenuBtn: {
    position: "absolute",
    top: "0rem",
    right: "-3rem",
  },
  MenuStyle: {
    minWidth: "23rem",
  },
};

export const AntTabs = styled(Tabs)({
  backgroundColor: "#2165A2",
  borderRadius: "1rem",
  padding: 0,
  minHeight: "4.8rem",
  alignItems: "center",
  width: "max-content",
  margin: 0,
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
});

export const AntTab = styled((props: ResourcesTabStyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  borderRadius: "1rem",
  fontWeight: 600,
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  color: "#fff",
  fontSize: "1.6rem",
  fontStyle: "normal",
  minHeight: "4rem",
  minWidth: "17.3rem",
  padding: "0",
  "&:hover": {
    color: "#fff",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#333333",
    backgroundColor: "white",
    fontWeight: 600,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));

export const ClientAntTabs = styled(Tabs)({
  backgroundColor: "transparent",
  alignItems: "center",
  marginTop: "3rem",
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
  ".MuiTabs-scroller": {
    borderBottom: "0.1rem solid #2165A2",
  },
  ".MuiTabs-flexContainer": {
    gap: "2rem",
  },
  ".MuiButtonBase-root": {
    margin: "0",
    minWidth: "19.3rem",
    minHeight: "5rem",
  },
});

export const ClientAntTab = styled((props: ClientStyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  minWidth: "18.3rem",
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  backgroundColor: "#2165A233",
  color: "#2165A2",
  borderRadius: "2rem 2rem 0 0",
  fontWeight: 500,
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  fontSize: "1.8rem",
  fontStyle: "normal",
  height: "4.6rem",
  "&:hover": {
    color: "#2165A2",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#fff",
    backgroundColor: "#2165A2",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));

export const WinrateAntTabs = styled(Tabs)({
  backgroundColor: "transparent",
  alignItems: "center",
  marginTop: "3rem",
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
  ".MuiTabs-scroller": {
    borderBottom: "0.1rem solid #2165A2",
  },
  ".MuiTabs-flexContainer": {
    gap: "2rem",
  },
  ".MuiButtonBase-root": {
    margin: "0",
    minWidth: "18.3rem",
    minHeight: "5rem",
  },
});

export const WinrateAntTab = styled((props: WinrateStyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  minWidth: "18.3rem",
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  backgroundColor: "#2165A233",
  color: "#2165A2",
  borderRadius: "2rem 2rem 0 0",
  fontWeight: 500,
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  fontSize: "1.8rem",
  fontStyle: "normal",
  height: "5rem",
  "&:hover": {
    color: "#2165A2",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#fff",
    backgroundColor: "#2165A2",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));
