import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../../styles/CheckinFormStyle";
import AssignCoachModal from "./AssignCoachModal";
import DeleteClientModal from "./DeleteClientModal";
import DeleteCoachModal from "./DeleteCoachModal";
import DateModal from "./UpdateContractDateModal";
import CustomizedDialogs from "./ViewModal";

export default function
  ManageUserMenu({ user, type, refetchAction }: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openClientDelModal, setOpenClientDelModal] = React.useState(false);
  const [openCoachDelModal, setOpenCoachDelModal] = React.useState(false);
  const [openDateModal, setOpenDateModal] = React.useState(false);
  const [openAssignModal, setOpenAssignModal] = React.useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  //Open Delete Modal Confirmation
  const handleDeleteOpen = () => {
    if (type === "client") {
      setOpenClientDelModal(true);
    }
    if (type === "coach") {
      setOpenCoachDelModal(true);
    }
    handleClose();
  }
  //Open Delete Modal Confirmation
  const handleDateOpen = () => {
    if (type === "client") {
      setOpenDateModal(true);
    }
    handleClose();
  }

  const openAssignModalHandler = () => {
    setOpenAssignModal(true);
    handleClose();
  }

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ minWidth: "33px" }}
      >
        <MoreVertIcon fontSize="large" sx={{ color: "#333333" }} />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            width: "20.3rem"
          }
        }}
      >
        <MenuItem sx={classes.menuItems}>
          <CustomizedDialogs type={type} setAnchorEl={setAnchorEl} user={user} />
        </MenuItem>
        {type === "client" && (
          <MenuItem
            sx={classes.menuItems}
            onClick={() => {
              openAssignModalHandler();
            }}
          >
            Assign Coach
          </MenuItem>
        )}
        {type === "client" ? <MenuItem sx={classes.menuItems} onClick={() => navigate(`/checkin-history/${user.id}`)}>
          Check-In History
        </MenuItem> : null}
        <MenuItem
          sx={classes.menuItems}
          onClick={() => {
            handleClose();
            navigate(`/edit-user/${user.id}`);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem sx={classes.menuItems} onClick={handleDeleteOpen}>
          Delete
        </MenuItem>
        {type === "client" ? <MenuItem sx={classes.menuItems} onClick={handleDateOpen}>
          Update Contract <br /> Date
        </MenuItem> : null}
      </Menu>

      <DeleteClientModal
        open={openClientDelModal}
        setOpen={setOpenClientDelModal}
        userId={user.id}
      />

      <DeleteCoachModal
        open={openCoachDelModal}
        setOpen={setOpenCoachDelModal}
        user={user}
        length={user?.clients?.length}
        refetchAction={refetchAction}
      />
      <DateModal
        open={openDateModal}
        setOpen={setOpenDateModal}
        user={user}
      />
      {type === "client" && (
        <AssignCoachModal
          open={openAssignModal}
          setOpen={setOpenAssignModal}
          user={user}
        />
      )}
    </div>
  );
}
