import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import * as React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useDeleteOnBoardingFormMutation,
  useOnBoardingFormStatusMutation,
} from "../../services/admin";
import classes from "../../styles/CheckinFormStyle";

export default function PositionedMenu({ isActivated, id, refetch }: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const [checkinFormStatus] =
    useOnBoardingFormStatusMutation();
  const [deleteCheckInForm] = useDeleteOnBoardingFormMutation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Activate or De-Activate Status
  const isActivatedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const payload = { isActivated: e.target.checked };
    checkinFormStatus({ id, payload })
      .unwrap()
      .then((res: any) => {
        setAnchorEl(null);
        toast.success(res?.message);
        refetch();
      })
      .catch(() => toast.error("Something went wrong"));
  };

  // Delete CheckIn Form
  const deleteHandler = () => {
    setAnchorEl(null);
    deleteCheckInForm(id)
      .unwrap()
      .then(() => {
        toast.success("Deleted Successfully");
      })
      .catch(() => {
        toast.error("Something went wrong ");
      });
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon fontSize="large" sx={{ color: "#333333" }} />
      </Button>
      <Menu
        id={id}
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ width: "20.3rem" }}
      >
        {/* <MenuItem sx={classes.menuItems} onClick={handleClose}>
          View
        </MenuItem> */}
        <Link to={`/edit-boarding-forms/${id}`}>
          <MenuItem sx={classes.menuItems} onClick={handleClose}>
            Edit
          </MenuItem>
        </Link>
        <MenuItem sx={classes.menuItems} onClick={deleteHandler}>
          Delete
        </MenuItem>
        <MenuItem sx={classes.menuItems}>
          Activate
          <Switch
            inputProps={{ role: "switch" }}
            onChange={isActivatedHandler}
            checked={isActivated}
            // value={checked ? checked : false}
          />
        </MenuItem>
      </Menu>
    </div>
  );
}
